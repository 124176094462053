import Icon from "@mdi/react";
import { MouseEventHandler } from "react";
import styles from "./MenuItem.module.css";

interface IMenuItem {
    icon: string;
    title: string;
    onClick: MouseEventHandler<HTMLDivElement>;
    iconColor?: string | null;
}

export const MenuItem = ({ icon, title, iconColor, onClick }: IMenuItem) => {
    return (
        <div className={styles.container} onClick={onClick}>
            <Icon path={icon} size={0.7} color={iconColor} />
            <div className={styles.title}>{title}</div>
        </div>
    )
}

import {mdiLock} from "@mdi/js";
import Icon from "@mdi/react";
import {useCallback, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "../../components/base/Button/Button";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {TextInput} from "../../components/base/TextInput/TextInput";
import {CardLayout} from "../../components/layouts/CardLayout/CardLayout";
import UserContext from "../../contexts/UserContext";
import styles from "./LoginPage.module.css";

let blocked = false;

const LoginPage = () => {
    const {t} = useTranslation();
    const userContext = useContext(UserContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const onLogin = useCallback(async (e: any) => {
        e.preventDefault();
        if (blocked) return;
        if (!email || !email.includes('@')) return setError(t('no email error'));
        if (!password) return setError(t('no password error'));
        blocked = true;
        try {
            await userContext.login(email, password, false);
        } catch (e) {
            setError(t('login error'))
        }
        blocked = false;
    }, [email, password, userContext, t]);

    return (
        <div className={styles.container}>
            <CardLayout
                title={t('login title')}
                subtitle={t('login subtitle')}
            >
                <form>
                    {error &&
                        <div className={styles.errorLabel}>{error}</div>
                    }
                    {!error &&
                        <SizedBox height={20}/>
                    }
                    <TextInput
                        label={t('email')}
                        value={email}
                        type="email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError(null);
                        }}
                    />
                    <SizedBox height={16}/>
                    <TextInput
                        label={t('password')}
                        value={password}
                        type="password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setError(null);
                        }}
                    />
                    <SizedBox height={48}/>
                    <Button
                        label={t("login")}
                        onClick={onLogin}
                    />
                    <input type="submit" hidden onSubmit={onLogin}/>
                </form>
                <SizedBox height={24}/>
                <div className={styles.linkContainer}>
                    <Link className={styles.link} to={"/forgotten-password"}>
                        <Icon className={styles.lock} path={mdiLock} size={0.7}/>
                        {t('forgot password')}
                    </Link>
                </div>
            </CardLayout>
        </div>
    )
}

export default LoginPage;

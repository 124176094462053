import moment from "moment";
import {StatCategory} from "../typings/StatCategory";
import {useCallback, useMemo} from "react";
import {useSearchParams} from "react-router-dom";

export const ATHLETE_QUERY_PARAM = "a";
export const DATE_QUERY_PARAM = "d";
export const START_DATE_QUERY_PARAM = "sd";
export const END_DATE_QUERY_PARAM = "ed";
export const TIME_INTERVAL_QUERY_PARAM = "i";
export const GRANULARITY_QUERY_PARAM = "g";
export const STAT_CATEGORY_QUERY_PARAM = "sc";

export const useQuery = <T extends unknown>(key: string,
                                            parse: (param: string) => T | undefined,
                                            serialize: (value: T) => string) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const param = useMemo(() => {
        return searchParams.get(key);
    }, [key, searchParams]);

    const value = useMemo(() => {
        if (param !== null) {
            const v = parse(param);
            if (v !== undefined) {
                return v;
            }
        }
        return undefined;
    }, [param, parse]);

    const update = useCallback((x: T | undefined) => {
        setSearchParams(_ => {
                // Hack. See: https://github.com/remix-run/react-router/issues/9757#issuecomment-1358336279
                const sp = new URLSearchParams(window.location.search);
                if (x === undefined)
                    sp.delete(key);
                else {
                    sp.set(key, serialize(x));
                }
                return sp;
            },
            {replace: true});
    }, [key, serialize, setSearchParams]);

    return {value, update};
}

export const getAthleteIdQuery = (searchParams: URLSearchParams,
                                  setter: (v: number) => void): boolean => {
    if (searchParams.has(ATHLETE_QUERY_PARAM)) {
        const g = searchParams.get(ATHLETE_QUERY_PARAM);
        if (g != null) {
            if (Number.isInteger(+g)) {
                setter(+g);
                return true;
            }
        }
    }
    return false;
}

export const parseAthleteId = (value: string | null): number | undefined => {
    if (value != null && Number.isInteger(+value))
        return +value;
    return undefined;
}

export const parseNumberQuery = (value: string): number | undefined => {
    if (Number.isInteger(+value))
        return +value;
    return undefined;
}

export const serializeNumberQuery = (value: number): string => {
    return value.toString();
}

export const parseNullableDateQuery = (value: string): Date | undefined | null => {
    if (value === "null")
        return null;
    else {
        const dateM = moment(value, "YYYY-MM-DD");
        if (dateM.isValid()) {
            return dateM.toDate();
        }
    }
    return undefined;
}

export const serializeNullableDateQuery = (date: Date | null): string => {
    if (date === null)
        return "null";
    else
        return moment(date).format("YYYY-MM-DD");
}

export const parseDateQuery = (value: string): Date | undefined => {
    const dateM = moment(value, "YYYY-MM-DD");
    if (dateM.isValid()) {
        return dateM.toDate();
    }
    return undefined;
}

export const serializeDateQuery = (date: Date): string => {
    return moment(date).format("YYYY-MM-DD");
}


export const createEnumParser = <T extends string | number>(type: any):
    (value: string) =>
        (T | undefined) => {
    const enumValues = Object.values(type);
    return (value: string): T | undefined => {
        if (enumValues.includes(value as T))
            return value as T;
        return undefined;
    }
}

export const serializeEnum = <T extends string | number>(value: T): string => {
    return value as string;
}

export const setAthleteIdQuery = (searchParams: URLSearchParams, athleteId: number | undefined) => {
    if (athleteId === undefined)
        searchParams.delete(ATHLETE_QUERY_PARAM);
    else
        searchParams.set(ATHLETE_QUERY_PARAM, athleteId.toString())
}

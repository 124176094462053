import * as React from "react";
import {MutableRefObject, useCallback, useEffect, useRef} from "react";
import uPlot from "uplot";
import "uplot/dist/uPlot.min.css";

export interface IUPlot {
    options: uPlot.Options,
    data: uPlot.AlignedData,
    uPlotRef: MutableRefObject<uPlot|undefined>
}

export const UPlot = ({options, data, uPlotRef}: IUPlot) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const chartRef = useRef<uPlot | null>(null);

    const updateChart = useCallback(() => {
        if (!divRef.current) return;
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        chartRef.current = new uPlot({
            ...options
        }, data, divRef.current);
        uPlotRef.current = chartRef.current;
    }, [data, uPlotRef, options]);

    useEffect(() => {
        updateChart();
    }, [data, options, updateChart]);

    return <div ref={divRef}/>;
};
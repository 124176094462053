import {mdiClose} from '@mdi/js';
import Icon from '@mdi/react';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SizedBox} from '../../components/base/SizedBox/SizedBox';
import {Spacer} from '../../components/base/Spacer/Spacer';
import {YesCancelDialog} from '../../components/dialogs/YesCancelDialog/YesCancelDialog';
import DashboardLayout from '../../components/layouts/DashboardLayout/DashboardLayout';
import DialogContext from '../../contexts/DialogContext';
import UserContext from '../../contexts/UserContext';
import {ICoach} from '../../typings/ICoach';
import {Api} from '../../utils/api';
import styles from './CoachesPage.module.css';

interface ICoachesPage {

}

export const CoachesPage = ({ }: ICoachesPage) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, isCoach } = useContext(UserContext);
    const { showError, openDialog } = useContext(DialogContext);
    const [coaches, setCoaches] = useState<ICoach[]>([]);

    useEffect(() => {
        if (!user) return;
        let cancelled = false;

        (async () => {
            const coaches = await Api.getMyCoaches(user.id);
            if (cancelled) return;
            setCoaches(coaches);
        })();

        return () => { cancelled = true; };
    }, [user]);

    const onDeleteCoach = async (coachId: number) => {
        const success = await Api.deleteCoach(user!.id, coachId);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        setCoaches(coaches => {
            const newCoaches = coaches!.slice();
            const index = newCoaches.findIndex(c => c.id === coachId);
            if (index !== -1) newCoaches.splice(index, 1);
            return newCoaches;
        })
    }

    const onDeleteCoachWrapper = (e: any, coachId: number) => {
        e.preventDefault();
        e.stopPropagation();
        openDialog(
            <YesCancelDialog
                title={t('are your sure you want to delete it')}
                onYes={() => onDeleteCoach(coachId)}
            />
        );
    }

    useEffect(() => {
        if (user === null) return;
        if (isCoach) navigate('/trainees');
    }, [user, isCoach, navigate]);

    return (
        <DashboardLayout>
            <SizedBox height={8} />
            <div className={styles.title}>{t('coaches')}</div>
            <div className={styles.listBox}>
                {coaches.length === 0 && <div style={{
                    display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16,
                    padding: 20, fontSize: 18
                }}>
                    {t("no coaches")}
                </div>}
                {coaches.map(coach => (
                    <div className={styles.coach}>
                        <div className={styles.name}>{coach.name}</div>
                        <Spacer flex={1} />
                        <div className={styles.deleteIcon} onClick={(e) => onDeleteCoachWrapper(e, coach.id)}>
                            <Icon size={1.2} path={mdiClose} />
                        </div>
                    </div>
                ))}
            </div>
        </DashboardLayout>
    );
};

import {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TrainingsFilterContext from '../../../contexts/TrainingsFilterContext';
import {Dropdown} from '../Dropdown/Dropdown';
import {DropdownLabel} from '../DropdownLabel/DropdownLabel';
import {SelectItem} from '../SelectItem/SelectItem';
import styles from './IntervalSelector.module.css';
import {GroupByPeriod} from "../../../typings/GroupByPeriod";

interface IGroupByDateSelector {

}

export const GroupByDateSelector = ({}: IGroupByDateSelector) => {
    const {t} = useTranslation();
    const {groupBy, setGroupBy} = useContext(TrainingsFilterContext);
    const selectorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const getIntervalString = (groupByPeriod: GroupByPeriod) => {
        switch (groupByPeriod) {
            case GroupByPeriod.Weeks:
                return t('week');
            case GroupByPeriod.Months:
                return t('month');
            default:
                return t('year');
        }
    }

    return (
        <div
            ref={selectorRef}
            onClick={() => setOpen(!open)}
            className={styles.container}
        >
            <DropdownLabel
                label={getIntervalString(groupBy)}
                open={open}
                className={styles.label}
            />
            {open &&
              <Dropdown anchor={selectorRef} setOpen={setOpen}>
                <SelectItem
                  label={getIntervalString(GroupByPeriod.Weeks)}
                  onClick={() => setGroupBy(GroupByPeriod.Weeks)}
                />
                <SelectItem
                  label={getIntervalString(GroupByPeriod.Months)}
                  onClick={() => setGroupBy(GroupByPeriod.Months)}
                />
                <SelectItem
                  label={getIntervalString(GroupByPeriod.Years)}
                  onClick={() => setGroupBy(GroupByPeriod.Years)}
                />
              </Dropdown>
            }
        </div>
    );
};

import moment from 'moment';
import {getSportIcon} from '../../../typings/SportType';
import {trainingSportMapping} from '../../../typings/TrainingSport';
import {Formatter} from '../../../utils/formatter';
import styles from './TrainingRow.module.css';
import {IApiTrainingCommonBase} from "../../../typings/IApiTrainingCommonBase";
import {IApiTrainingCommonTraining} from "../../../typings/IApiTrainingWithCommonTraining";

export const TrainingRow = ({i, t, tc, forAthlete, onClick}: {
    i: number,
    t: IApiTrainingCommonTraining,
    tc: IApiTrainingCommonBase,
    forAthlete: boolean,
    onClick: () => void,
}) => {
    let j = 1;
    return <div className={styles.trainingRow} key={`r-${i}`}>
        <div style={{gridColumn: `1 / -1`, gridRow: `${i} / ${i + 1}`}} className={styles.trainingRowBg}/>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{moment(tc.startAt).format("YYYY-MM-DD HH:mm")}</div>
        <div className={styles.trainingCell} key={`${i}-2`} onClick={onClick}
             style={{gridColumnStart: j++, gridRowStart: i}}>
            <img className={styles.sportIcon} src={getSportIcon(trainingSportMapping(tc.sport))}/>
        </div>
        {!forAthlete && <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
                             className={styles.trainingCell}>
            <div className={styles.createdByName}>
                {tc.createdBy.name}
            </div>
        </div>}
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>
            <div className={styles.trainingName}>
                {tc.name}
            </div>
        </div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{Formatter.secToTimeString(moment(tc.endAt).diff(moment(tc.startAt), 'second'))}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{tc.lengthKm?.toFixed(1)} km
        </div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{t.avgHeartRateBpm?.toFixed(1)} {t.avgHeartRateBpm ? 'bpm' : ''}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{t.avgPaddlingForceN?.toFixed(0).replace('-0', '0')} {t.avgPaddlingForceN ? 'N' : ''}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}>{tc.avgStrokeRateSpm?.toFixed(1)} {tc.avgStrokeRateSpm ? ' / min' : ''}</div>
        <div onClick={onClick} style={{gridColumnStart: j++, gridRowStart: i}}
             className={styles.trainingCell}><div className={styles.notesCellContent}>{tc.notes}</div></div>
    </div>;
};

import {mdiClose} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styles from "./FullscreenDialogShim.module.scss";

interface IFullscreenDialogShim {
    children: React.ReactNode;
    onClose?: () => void;
}

const FullscreenDialogShim = ({
                                  children,
                                  onClose = undefined
                              }: IFullscreenDialogShim) => {
    return (
        <div className={styles.dialog}
        >
            {children}
            {onClose &&
                <div className={styles.close} onClick={onClose}>
                    <Icon path={mdiClose} size={0.7}/>
                </div>
            }
        </div>
    );
}

export default FullscreenDialogShim;

import moment from 'moment';
import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import UserContext from '../../../contexts/UserContext';
import {ITrainingCommon} from '../../../typings/ITrainingCommon';
import {getSportIcon} from '../../../typings/SportType';
import {trainingSportMapping} from '../../../typings/TrainingSport';
import {Api} from '../../../utils/api';
import {Button} from '../../base/Button/Button';
import {SizedBox} from '../../base/SizedBox/SizedBox';
import {TextInput} from '../../base/TextInput/TextInput';
import {StatsGlanceSmall} from '../GlanceStatsSmall/GlanceStatsSmall';
import styles from './AnalyticsHeader.module.scss';
import {avg, max} from "../../../utils/arrayUtils";
import Icon from "@mdi/react";
import {mdiDownload} from "@mdi/js";

interface IAnalyticsHeader {
    trainingData: ITrainingCommon;
    onDownloadClick: () => void;
    onReportProblemClick: () => void;
}

export const AnalyticsHeader = ({trainingData, onDownloadClick, onReportProblemClick}: IAnalyticsHeader) => {
    const {t} = useTranslation();
    const {showError} = useContext(DialogContext);
    const {isCoach} = useContext(UserContext);
    const [changed, setChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [notes, setNotes] = useState<string | undefined>();

    useEffect(() => {
        if (trainingData.notes != null)
            setNotes(trainingData.notes);
    }, [trainingData.notes]);

    const onSave = async () => {
        let success = false;
        try {
            setChanged(false);
            setSaving(true);
            await Api.updateTrainingCommon(trainingData.id, {notes});
            setSaving(false);
            success = true;
        } catch (err) {
            setChanged(true);
            setSaving(false);
            success = false;
        }
        if (!success) showError(t('an error happened please try later'));
    }

    const allForces = useMemo(() => {
            return (trainingData.trainings.map(x =>
                [x.leftPaddlingForceNSeries, x.rightPaddlingForceNSeries])).flat()
                .filter(x => x != null)
                .map(x => x!)
                .flat();
        },
        [trainingData.trainings]);

    const avgMaxForce = useMemo(() => {
            const avgSumMaxByForceArray = allForces.reduce((res, force) => {
                res.sum += force;
                if (res.max < force)
                    res.max = force;
                return res;
            }, {sum: 0, max: -Infinity});
            if (allForces.length === 0)
                return {avg: undefined, max: undefined};
            return {avg: avgSumMaxByForceArray.sum / allForces.length, max: avgSumMaxByForceArray.max};
        }
        , [allForces]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img className={styles.sportIcon} src={getSportIcon(trainingSportMapping(trainingData.sport))}/>
                <div className={styles.headerCell}>
                    <div className={styles.trainingName}>{trainingData.name}</div>
                    <div className={styles.subTitle}>
                        {moment(trainingData.startAt).format('YYYY MMMM D HH:mm')}
                    </div>
                </div>
                <div className={styles.notes}>
                    {isCoach &&
                        <div className={styles.notesRow}>
                            <div className={styles.flex1}>
                                <TextInput
                                    placeholder={t('notes')}
                                    value={notes || ""}
                                    onChange={e => {
                                        setNotes(e.target.value.slice(0, 1000));
                                        setChanged(true);
                                    }}
                                />
                            </div>
                            <div className={styles.saveNoteContainer}>
                                {saving && t('saving')}
                                {!saving &&
                                    <Button
                                        label={t('save')}
                                        disabled={!changed}
                                        onClick={onSave}
                                        secondary
                                    />
                                }
                            </div>
                        </div>
                    }
                    {!isCoach && notes && notes !== "" &&
                        <>
                            <TextInput
                                placeholder={t('notes')}
                                value={notes || ""}
                                onChange={e => {
                                    setNotes(e.target.value.slice(0, 1000));
                                    setChanged(true);
                                }}
                                readOnly={true}
                            />
                            <SizedBox height={12}/>
                        </>
                    }
                </div>
                <div className={styles.buttons}>
                    <Button
                        className={styles.downloadButton}
                        secondary
                        label={
                            <>
                                <Icon path={mdiDownload} size={0.8} className="me-2"/>
                                {t('download')}
                            </>}
                        onClick={onDownloadClick}
                    />
                    <Button
                        className={styles.downloadButton}
                        secondary
                        label={t(trainingData.reportedProblem ? 'problem reported' : 'report problem')}
                        onClick={onReportProblemClick}
                        disabled={!!trainingData.reportedProblem}
                    /></div>
            </div>
            <div className={"px-3"}>
                <StatsGlanceSmall
                    avgStrokeRateSpm={trainingData.avgStrokeRateSpm}
                    maxStrokeRateSpm={trainingData.maxStrokeRateSpm}
                    lengthKm={trainingData.lengthKm}
                    startAt={trainingData.startAt}
                    endAt={trainingData.endAt}
                    avgSpeedKph={trainingData.avgSpeedKph}
                    maxSpeedKph={trainingData.maxSpeedKph}
                    avgPaddlingForceN={avgMaxForce.avg}
                    maxPaddlingForceN={avgMaxForce.max}
                    avgHeartRateBpm={avg(trainingData.trainings
                        .filter(x => x.avgHeartRateBpm != null)
                        .map(x => x.avgHeartRateBpm as number))}
                    maxHeartRateBpm={max(trainingData.trainings
                        .filter(x => x.maxHeartRateBpm != null)
                        .map(x => x.maxHeartRateBpm as number))}
                    sport={trainingData.sport}
                />
            </div>
        </div>
    );
};

import {useCallback, useRef} from "react";

export const useDoubleTap = (onDoubleTap?: () => void) => {
    const lastClickTime = useRef<number>(0);
    const onClick = useCallback((e: any) => {
        //to handle double click on mobile
        if (lastClickTime.current === 0) {
            lastClickTime.current = new Date().getTime();
        } else {
            if (((new Date().getTime()) - lastClickTime.current) < 400) {
                lastClickTime.current = 0;
                e.preventDefault();
                e.stopPropagation();
                onDoubleTap?.();
            } else {
                lastClickTime.current = new Date().getTime();
            }
        }

    }, [onDoubleTap]);
    return {onClick}
}
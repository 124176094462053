import { MouseEventHandler } from 'react';
import { SizedBox } from '../../base/SizedBox/SizedBox';
import styles from './GlanceIndicator.module.css';

interface IGlanceIndicator {
    icon: string;
    text: string;
    value: string;
    smallValue?: string;
    onClick: MouseEventHandler<HTMLDivElement>;
    selected: boolean;
}

export const GlanceIndicator = ({ icon, text, value, smallValue, onClick, selected }: IGlanceIndicator) => {
    return (
        <div
            className={`${styles.container} ${selected ? styles.selected : ''}`}
            onClick={onClick}
        >
            <img src={icon} className={styles.icon} />
            <SizedBox width={8} />
            <div className={styles.col}>
                <div className={styles.value}>{value}</div>
                <div className={styles.text}>{text}</div>
                {smallValue !== undefined &&
                    <div className={styles.smallValue}>{smallValue}</div>
                }
            </div>
        </div>
    );
};

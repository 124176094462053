import { SizedBox } from '../../base/SizedBox/SizedBox';
import styles from './GlanceIndicatorSmall.module.css';

interface IGlanceIndicatorSmall {
    icon: string;
    text: string;
    value: string;
    smallValue?: string;
    unavailable?: boolean;
}

export const GlanceIndicatorSmall = ({ icon, text, value, smallValue, unavailable = false }: IGlanceIndicatorSmall) => {
    return (
        <div className={!unavailable ? styles.container : styles.containerUnavailable}>
            <img src={icon} className={styles.icon} />
            <SizedBox width={8} />
            <div className={styles.col}>
                <div className={styles.value}>{value}</div>
                <div className={styles.text}>{text}</div>
                {smallValue !== undefined &&
                    <div className={styles.smallValue}>{smallValue}</div>
                }
            </div>
        </div>
    );
};

import React, {useCallback, useEffect, useState} from "react";
import {GroupByPeriod} from "../typings/GroupByPeriod";
import {
    END_DATE_QUERY_PARAM,
    parseNullableDateQuery,
    serializeNullableDateQuery,
    START_DATE_QUERY_PARAM,
    useQuery
} from "../hooks/useQuery";
import moment from "moment/moment";

interface ITrainingsFilterContext {
    groupBy: GroupByPeriod;
    startDate: Date | undefined | null; // undefined=loading
    endDate: Date | undefined | null; // undefined=loading

    setGroupBy: (interval: GroupByPeriod) => void;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
    reset: () => void;
    loadCached: () => void;
}

const dv: ITrainingsFilterContext = {
    groupBy: GroupByPeriod.Months,
    startDate: undefined,
    endDate: undefined,

    setGroupBy: () => {
    },

    setStartDate: () => {
    },
    setEndDate: () => {
    },

    reset: () => {
    },
    loadCached: () => {
    },
};

export const TrainingsFilterContext = React.createContext(dv);

export const TrainingsFilterContextProvider = ({children}: { children: React.ReactNode }) => {
    const [groupBy, setGroupBy] =
        useState<GroupByPeriod>(GroupByPeriod.Months);
    const [cachedStartDate, setCachedStartDate] =
        useState<Date | null>(moment().subtract(1, 'month').toDate());
    const [cachedEndDate, setCachedEndDate] =
        useState<Date | null>(moment().toDate());
    const {value: startDate, update: setStartDate} = useQuery(
        START_DATE_QUERY_PARAM, parseNullableDateQuery, serializeNullableDateQuery
    );
    const {value: endDate, update: setEndDate} = useQuery(
        END_DATE_QUERY_PARAM, parseNullableDateQuery, serializeNullableDateQuery
    );

    const setStartDateWrapper = useCallback((newDate: Date) => {
        if (moment(newDate).isSameOrAfter(moment(endDate))) {
            setEndDate(moment(newDate).add(1, groupBy).toDate());
        }
        setStartDate(newDate);
    }, [endDate, groupBy, setEndDate, setStartDate]);

    const setEndDateWrapper = useCallback((newDate: Date) => {
        if (moment(newDate).isSameOrBefore(moment(startDate))) {
            setStartDate(moment(newDate).subtract(1, groupBy).toDate());
        }
        setEndDate(newDate);
    }, [startDate, setEndDate, setStartDate, groupBy]);

    useEffect(() => {
        if (startDate !== undefined) {
            setCachedStartDate(startDate);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate !== undefined) {
            setCachedEndDate(endDate);
        }
    }, [endDate]);

    const loadCached = useCallback(() => {
        if (startDate === undefined) {
            if (cachedStartDate !== undefined) {
                setStartDate(cachedStartDate);
            }
        }
        if (endDate === undefined) {
            if (cachedEndDate !== undefined) {
                setEndDate(cachedEndDate);
            }
        }
    }, [startDate, endDate, cachedStartDate, setStartDate, cachedEndDate, setEndDate]);

    const reset = useCallback(() => {
        setGroupBy(GroupByPeriod.Months);
        setStartDate(null);
        setEndDate(null);
    }, [setEndDate, setStartDate]);

    return (
        <TrainingsFilterContext.Provider value={{
            groupBy,
            startDate,
            endDate,

            setGroupBy,
            setStartDate: setStartDateWrapper,
            setEndDate: setEndDateWrapper,
            reset,
            loadCached
        }}>
            {children}
        </TrainingsFilterContext.Provider>
    );
};

export default TrainingsFilterContext;

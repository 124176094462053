import Highcharts, {Chart} from "highcharts";
import HighchartsReact, {HighchartsReactRefObject} from "highcharts-react-official";
import * as React from "react";
import {forwardRef, memo, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ChartHelper} from "../../../../utils/chartHelper";
import styles from "./LineChart.module.scss";
import {avg, max, min} from "../../../../utils/arrayUtils";
import {autoUpdate, flip, offset, useFloating} from "@floating-ui/react";
import {chartHeight} from "../ChartHeight";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiFullscreen} from "@mdi/js";

export const standardLineChartOptions = ChartHelper.getStandardChartOptions(chartHeight);

export const ChartComp = memo(forwardRef<HighchartsReactRefObject, any>(({
                                                                             showXLabels,
                                                                             plotBands,
                                                                             plotLines,
                                                                             xAxisLabelFormatter,
                                                                             setExtremes,
                                                                             afterSetExtremes,
                                                                             afterDrawCrosshair,
                                                                             options,
                                                                             series
                                                                         }: any, propRef) => {
    const defaultXAxis = {
        ...standardLineChartOptions.xAxis,
        labels: {
            enabled: showXLabels,
            useHTML: !!xAxisLabelFormatter,
            formatter: xAxisLabelFormatter,
        },
        plotBands,
        plotLines,
        events: {
            setExtremes,
            afterSetExtremes,
            afterDrawCrosshair
        }
    };
    let xAxis: any;
    if (options.xAxis) {
        xAxis = options.xAxis;
        if (!Array.isArray(xAxis))
            xAxis = [xAxis];
        xAxis = xAxis.map((x: any) => {
            return ({
                ...defaultXAxis,
                ...(x)
            });
        });
    } else
        xAxis = defaultXAxis;
    return <HighchartsReact
        ref={propRef}
        callback={(chart: Chart) => {
            // Override the reset function, we don't need to hide the tooltips and crosshairs.
            // They are shown for every chart synchronized
            chart.pointer.reset = () => {
            };
        }}
        highcharts={Highcharts}
        options={{
            ...standardLineChartOptions,
            tooltip: {
                enabled: false,
                snap: 0
            },
            xAxis,
            yAxis: options?.yAxis ?? standardLineChartOptions.yAxis,
            series: series.some((x: any) => x.data != null) ? series : [{
                data: series,
                dataGrouping: {
                    approximation: "max"
                }
            }],
            chart: {
                ...standardLineChartOptions.chart,
                height: standardLineChartOptions.chart.height! + (showXLabels ? 50 : 0),
                ...(options?.chart)
            },
            title: {
                ...standardLineChartOptions.title,
                ...(options?.title)
            }
        }}
        updateArgs={[true, true, false]}
    />;
}));

export const ChartTooltip = memo((
    {reference, boundary, tooltipFormatter, points, unit, units}: any) => {
    const {refs, floatingStyles} = useFloating({
        placement: "right-end",
        open: true,
        whileElementsMounted: autoUpdate,
        middleware: [offset(30), flip({boundary: boundary || 'clippingAncestors'})],
    });

    useEffect(() => {
        refs.setReference(reference);
    }, [reference, refs]);

    const minErrorPoint = points.reduce((min: any, x: any) => {
        if (min === null || (x.xError !== undefined && min.xError !== undefined && x.xError < min.xError))
            return x;
        return min;
    }, null);

    return <div
        ref={refs.setFloating}
        style={{
            ...floatingStyles,
            pointerEvents: "none",
            background: "white",
            boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.6)",
            borderRadius: 2,
            padding: 8,
            fontFamily: "Helvetica, Arial, sans-serif",
            display: "flex",
            alignItems: "center",
            fontSize: "0.8em",
            zIndex: 10
        }}
    >
        {tooltipFormatter(minErrorPoint?.x ?? 0, points, unit || units)}
    </div>;
});

export const LineChart = forwardRef<
    HighchartsReactRefObject,
    any
>(({
       tooltipFormatter, showXLabels, xAxisLabelFormatter,
       plotBands, plotLines, setExtremes, options, series, unit,
       valueFormatter, minMax, showCrosshair, onFullscreenClick
   }: any, propRef) => {
    minMax ??= "max";
    const {t} = useTranslation();
    const ref = useRef();
    const setRef = useCallback((el: any) => {
        ref.current = el;
        (propRef as (instance: HighchartsReactRefObject | null) => void)(el);
        setBoundary(el?.container?.current);
    }, [propRef]);
    const [avgMinMax, setAvgMinMax] = useState({avg: 0, min: 0, max: 0});
    const afterSetExtremes = useCallback((e: any) => {
        const target = e.target as Highcharts.Chart;
        const series = target.series;
        // @ts-ignore
        const visibleData = series.flatMap(x => x.points.filter(x => x.isInside).map(y => y.y as number));
        if (visibleData.length === 0) return
        const value = {avg: avg(visibleData)} as any;
        if (minMax === "max")
            value.max = max(visibleData);
        else
            value.min = min(visibleData);
        setAvgMinMax(value);
    }, [minMax]);
    const [boundary, setBoundary] = useState<Element | null>(null);
    const [crosshairRef, setCrosshairRef] = useState<any | null>(null);
    const [selectedPoints, setSelectedPoints] = useState([]);

    const afterDrawCrosshair = useCallback((e: any) => {
        if (e.e.chartX === undefined) return;
        const chart = e.target;
        //set crosshair ref to position tooltip
        const px = e.point.clientX + chart.chart.container.getBoundingClientRect().left + chart.chart.plotLeft;
        const py = boundary?.getBoundingClientRect()?.top || 0;
        setCrosshairRef({
            getBoundingClientRect: () =>
                ({
                    x: px,
                    y: py + 20,
                    top: py + 20,
                    left: px,
                    bottom: py + 20,
                    right: px,
                    width: 0,
                    height: 0,
                })
        });
        //set selected points for tooltip content
        const lastPoint = chart.series[0].points[chart.series[0].points.length - 1];
        const xPoint = e.point.x;
        const dataIndex = Math.round((chart.series[0].data.length - 1) * xPoint / lastPoint.x);
        const points = chart.series.map((x: any) => {
            const point = x.data[dataIndex];
            return ({
                x: point.x,
                y: point.y,
                color: point.color,
                xError: Math.abs(point.x - xPoint)
            });
        });
        setSelectedPoints(points);
    }, [boundary]);
    return <div className={styles.lineChart}>
        <ChartComp ref={setRef}
                   showXLabels={showXLabels}
                   plotBands={plotBands}
                   plotLines={plotLines}
                   xAxisLabelFormatter={xAxisLabelFormatter}
                   setExtremes={setExtremes}
                   afterSetExtremes={afterSetExtremes}
                   afterDrawCrosshair={afterDrawCrosshair}
                   options={options}
                   series={series}/>
        <div className={styles.topRight}>
            <div className={styles.avgMinMaxContainer}>
                <div className={styles.avgMinMax}>{t("avg")}&nbsp;
                    {valueFormatter(avgMinMax.avg)} {unit} <br/>
                </div>
                {minMax === "max" && <div className={styles.avgMinMax}>
                    {t("max")} {valueFormatter(avgMinMax.max)} {unit}
                </div>}
                {minMax === "min" && <div className={styles.avgMinMax}>
                    {t("min")} {valueFormatter(avgMinMax.min)} {unit}
                </div>}
            </div>
            <Button size="sm" outline={true} className={styles.fullscreenButton} onClick={onFullscreenClick}>
                <Icon path={mdiFullscreen}
                      size={0.8}/>
            </Button>
        </div>
        {showCrosshair && <ChartTooltip reference={crosshairRef}
                                        boundary={boundary}
                                        points={selectedPoints}
                                        tooltipFormatter={tooltipFormatter}
                                        unit={unit}/>}
    </div>
});

import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import moment from "moment";

export const useLocaleData = () => {
    const {i18n} = useTranslation();
    return useMemo(() => {
        moment.locale(i18n.language);
        return moment.localeData();
    }, [i18n.language]);
}
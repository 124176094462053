export const speedToTempo500 = (speed: number) => {
    const tempo = 30 / speed;
    if (Number.isNaN(tempo) || !Number.isFinite(tempo))
        return 0;
    return Math.min(8, tempo);
}
export const speedToTempo = (speed: number) => {
    const tempo = 60 / speed;
    if (Number.isNaN(tempo) || !Number.isFinite(tempo))
        return 0;
    return Math.min(15, tempo);
}
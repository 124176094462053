import * as React from "react";
import {ReactInstance, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import FullscreenDialogShim from "../FullscreenDialogShim";
import styles from "./ChartDialog.module.scss";
import {UPlot} from "../../uplot/UPlot";
import {autoUpdate, flip, offset, useClientPoint, useFloating, useInteractions} from "@floating-ui/react";
import {TooltipChangedListener, TooltipPlugin} from "../../uplot/TooltipPlugin";
import {useResizeDetector} from "react-resize-detector";
import {Axis, Cursor, Scale, Scales, Series} from "uplot";
import moment from "moment/moment";
import {TouchZoomPlugin} from "../../uplot/TouchZoomPlugin";
import {WheelPanPlugin} from "../../uplot/WheelPanPlugin";
import {SeriesType} from "../../../typings/SeriesType";
import {isTwoPaddle, TrainingSport} from "../../../typings/TrainingSport";
import {IAthlete} from "../../../typings/IAthlete";
import {ChartTooltipContent} from "../../paddlemate/AnalyticsPanel/ChartTooltipContent/ChartTooltipContent";
import {forceSeriesColors, tempoFormatter} from "../../paddlemate/AnalyticsPanel/StandardGraphs/StandardGraphs";
import {getAthleteDisplayName} from "../../paddlemate/AnalyticsPanel/AthleteToggle/AthleteToggle";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiMinusCircleOutline, mdiPlusCircleOutline, mdiPrinter} from "@mdi/js";
import {distinct} from "../../../utils/arrayUtils";
import {useReactToPrint} from "react-to-print";

const Toggle = ({isSelected, onClick, name, color}: any) => {
    return <div className={`${styles.pill} ${isSelected ? styles.selected : ""}
    ${isSelected}`} onClick={onClick}>
        {isSelected && color && <div
            className={styles.color}
            style={{
                backgroundColor: color
            }}>
        </div>}
        {name}
    </div>
}

interface IChartDialog {
    onDismiss: () => void;
    heartRateSeries: (number[][] | null)[];
    heartRateMax: number;
    speedSeries: number[][] | null;
    speedMax: number;
    tempoSeries: number[][] | null;
    tempo500Max: number;
    tempo500Series: number[][] | null;
    strokeSeries: number[][] | null;
    strokeMax: number;
    distancePerStrokeSeries: number[][] | null;
    distancePerStrokeMax: number;
    forceLeftSeries: (number[][] | null)[];
    forceRightSeries: (number[][] | null)[];
    forceMin: number;
    forceMax: number;
    detailedForceMin: number;
    detailedForceMax: number;
    detailedLeftForces: (number[] | null)[];
    detailedRightForces: (number[] | null)[];
    distanceSeries: number[][] | null;
    imuSeries: number[][] | undefined;
    accelerationMin: number;
    accelerationMax: number;
    gyroscopeMin: number;
    gyroscopeMax: number;
    isTracker: boolean;
    zoomStart: number;
    zoomEnd: number;
    maxX: number;
    athletes: IAthlete[];
    defaultSeriesType: SeriesType,
    sport: TrainingSport;
    onZoomChange: (min: number, max: number) => void;
}

const interpolate = (data: number[], multiplier: number): number[] => {
    const res = [];
    for (let i = 0; i < data.length - 1; i++) {
        let yStart = data[i];
        let yEnd = data[i + 1];
        for (let j = 0; j < multiplier; j++) {
            res.push(yStart + (yEnd - yStart) * (j / multiplier));
        }
    }
    res.push(data[data.length - 1]);
    return res;
}

export const ChartDialog = ({
                                onDismiss,
                                heartRateSeries,
                                heartRateMax,
                                speedSeries,
                                speedMax,
                                tempoSeries,
                                tempo500Max,
                                tempo500Series,
                                strokeSeries,
                                strokeMax,
                                forceLeftSeries,
                                forceRightSeries,
                                detailedLeftForces,
                                detailedRightForces,
                                forceMin,
                                forceMax,
                                detailedForceMin,
                                detailedForceMax,
                                distanceSeries,
                                distancePerStrokeSeries,
                                distancePerStrokeMax,
                                imuSeries,
                                accelerationMin,
                                accelerationMax,
                                gyroscopeMin,
                                gyroscopeMax,
                                isTracker,
                                zoomStart,
                                zoomEnd,
                                maxX,
                                athletes,
                                defaultSeriesType,
                                sport,
                                onZoomChange
                            }: IChartDialog) => {
    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.key === "Escape") {
                onDismiss()
            }
        };
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [onDismiss]);
    const {t} = useTranslation();
    const uPlot = useRef<uPlot>();
    const [topRightContainerMouseOver, setTopRightContainerMouseOver] = useState(false);

    const getAthleteName = useCallback((i: number) => {
        if (athletes[i]) {
            return getAthleteDisplayName(athletes[i], true)
        }
        return `${t("guest")} ${i + 1}`
    }, [athletes, t]);

    const yAxisDefinitions = useMemo(() => [
        {
            name: "heartrate bpm",
            valueFormatter: (x: number) => x.toFixed(0).replace('-0', '0'),
            min: 0,
            max: heartRateMax
        },
        {
            name: "speed kmph",
            valueFormatter: (x: number) => x.toFixed(1).replace('-0', '0'),
            min: 0,
            max: speedMax
        },
        {
            name: "tempo",
            valueFormatter: tempoFormatter,
            min: Math.max(tempo500Max - 0.1, 0),
            max: 15,
            dir: -1
        },
        {
            name: "stroke rate spm",
            valueFormatter: (x: number) => x.toFixed(1).replace('-0', '0'),
            min: 0,
            max: strokeMax
        },
        {
            name: "force N",
            valueFormatter: (x: number) => x.toFixed(0).replace('-0', '0'),
            min: detailedForceMin === Infinity ? forceMin : detailedForceMin,
            max: detailedForceMax === -Infinity ? forceMax : detailedForceMax
        },
        {
            name: "acceleration milliG",
            valueFormatter: (x: number) => x.toFixed(0).replace('-0', '0'),
            min: accelerationMin,
            max: accelerationMax
        },
        {
            name: "gyroscope dps",
            valueFormatter: (x: number) => x.toFixed(1).replace('-0', '0'),
            yAxisValueFormatter: (x: number) => x.toFixed(0).replace('-0', '0'),
            min: gyroscopeMin,
            max: gyroscopeMax
        },
        {
            name: "distance per stroke m",
            valueFormatter: (x: number) => x.toFixed(3).replace('-0', '0'),
            min: 0,
            max: distancePerStrokeMax
        },
    ], [accelerationMax, accelerationMin, detailedForceMax, detailedForceMin,
        distancePerStrokeMax, gyroscopeMax, gyroscopeMin, heartRateMax, speedMax,
        strokeMax, tempo500Max]);

    const series = useMemo(() => {
            let id = 0;
            const arr = [
                heartRateSeries
                    .map((x, i) => ({
                        data: x ? interpolate(x.map(y => y[1]), 100) : x,
                        name: athletes.length > 1 ? `Heart rate ${getAthleteName(i)}` : "Heart rate",
                        yAxis: "heartrate bpm",
                        unit: "bpm",
                        seriesType: SeriesType.HEARTRATE,
                        id: id++
                    })),
                [{
                    data: speedSeries ?
                        interpolate(speedSeries.map(x => x[1]), isTracker ? 10 : 100) :
                        speedSeries,
                    name: t("speed"),
                    yAxis: "speed kmph",
                    unit: "km/h",
                    seriesType: SeriesType.SPEEDS,
                    id: id++
                }],
                [{
                    data: tempoSeries ?
                        interpolate(tempoSeries.map(x => x[1]), isTracker ? 10 : 100) :
                        tempoSeries,
                    name: t("avg tempo"),
                    yAxis: "tempo",
                    unit: "/km",
                    seriesType: SeriesType.TEMPOS,
                    id: id++
                }],
                [{
                    data: tempo500Series ?
                        interpolate(tempo500Series.map(x => x[1]), isTracker ? 10 : 100) :
                        tempo500Series,
                    name: t("avg tempo split"),
                    yAxis: "tempo",
                    unit: "/500",
                    seriesType: SeriesType.TEMPO_500S,
                    id: id++
                }],
                [{
                    data: strokeSeries ?
                        interpolate(strokeSeries.map(x => x[1]), 100) : strokeSeries,
                    name: t("avg stroke rate"),
                    yAxis: "stroke rate spm",
                    unit: "s/min",
                    seriesType: SeriesType.STROKES,
                    id: id++
                }],
                [{
                    data: distancePerStrokeSeries ?
                        interpolate(distancePerStrokeSeries.map(x => x[1]), 100) : distancePerStrokeSeries,
                    name: t("distance per stroke"),
                    yAxis: "distance per stroke m",
                    unit: "m",
                    seriesType: SeriesType.DISTANCEPERSTROKE,
                    id: id++
                }],
                forceLeftSeries
                    .map((x, i) => ({
                        data: x ? interpolate(x.map(x => x![1]), 100) : x,
                        name: isTwoPaddle(sport) ?
                            (athletes.length > 1 ?
                                t("left paddling force athlete", {athlete: getAthleteName(i)}) :
                                t("left paddling force")) :
                            (athletes.length > 1 ?
                                t("paddling force athlete", {athlete: getAthleteName(i)}) :
                                t("avg paddling force")),
                        yAxis: "force N",
                        unit: "N",
                        seriesType: SeriesType.PULLING_FORCE,
                        id: id++
                    })),
                forceRightSeries
                    .map((x, i) => ({
                        data: x ? interpolate(x.map(x => x![1]), 100) : x,
                        name: isTwoPaddle(sport) ?
                            (athletes.length > 1 ?
                                t("right paddling force athlete", {athlete: getAthleteName(i)}) :
                                t("right paddling force")) :
                            (athletes.length > 1 ?
                                t("paddling force athlete", {athlete: getAthleteName(i)}) :
                                t("avg paddling force")),
                        yAxis: "force N",
                        unit: "N",
                        seriesType: SeriesType.PULLING_FORCE,
                        id: id++
                    })),
                detailedLeftForces.map((x, i) => ({
                    data: x,
                    name: isTwoPaddle(sport) ?
                        (athletes.length > 1 ?
                            t("left detailed paddling force athlete", {athlete: getAthleteName(i)}) :
                            t("left detailed paddling force")) :
                        (athletes.length > 1 ?
                            t("detailed paddling force athlete", {athlete: getAthleteName(i)}) :
                            t("detailed paddling force")),
                    yAxis: "force N",
                    unit: "N",
                    seriesType: SeriesType.DETAILS,
                    id: id++
                })),
                detailedRightForces.map((x, i) => ({
                    data: x,
                    name: isTwoPaddle(sport) ?
                        (athletes.length > 1 ?
                            t("right detailed paddling force athlete", {athlete: getAthleteName(i)}) :
                            t("right detailed paddling force")) :
                        (athletes.length > 1 ?
                            t("detailed paddling force athlete", {athlete: getAthleteName(i)}) :
                            t("detailed paddling force")),
                    yAxis: "force N",
                    unit: "N",
                    seriesType: SeriesType.DETAILS,
                    id: id++
                }))
            ];
            if (imuSeries) {
                arr.push(
                    [...Array(6).keys()].map((i) => ({
                        data: imuSeries.map(x => x[i]),
                        name: i < 3 ? t("acceleration axis", {axis: ["x", "y", "z"][i % 3]}) :
                            t("gyroscope axis", {axis: ["x", "y", "z"][i % 3]}),
                        yAxis: i < 3 ? "acceleration milliG" : "gyroscope dps",
                        unit: i < 3 ? "milliG" : "°/s",
                        seriesType: SeriesType.IMUS,
                        id: id++
                    }))
                )
            }
            return arr.flat().filter(x => !!x.data) as {
                data: number[],
                name: string,
                yAxis: string,
                unit: string,
                seriesType: string,
                id: number
            }[];
        }
        , [athletes.length, detailedLeftForces, detailedRightForces,
            distancePerStrokeSeries, forceLeftSeries, forceRightSeries,
            getAthleteName, heartRateSeries, imuSeries, isTracker,
            speedSeries, sport, strokeSeries, t, tempo500Series, tempoSeries]);

    const defaultSelectedSeries = series
        .filter(x => x.seriesType === defaultSeriesType)
        .map(x => x.id);
    const [selectedSeries, setSelectedSeries] = useState(
        defaultSelectedSeries);

    const [seriesColors, setSeriesColors] = useState<{ [key: string]: string }>(
        Object.fromEntries(defaultSelectedSeries.map((s, i) => [s, forceSeriesColors[i]]))
    );

    const filteredSeries = useMemo(() => {
        return series.filter((x) => selectedSeries.includes(x.id))
    }, [selectedSeries, series]);

    const toggleSelectedSeries = useCallback((i: number) => {
        if (selectedSeries.includes(i)) {
            if (selectedSeries.length > 1) {
                setSelectedSeries(x => x.filter(y => y !== i));
                setSeriesColors(x => {
                    const res = {...x};
                    delete res[i];
                    return res;
                });
            }
        } else if (selectedSeries.length < 10) {
            setSelectedSeries(x => [...x, i]);
            setSeriesColors(x => {
                const takenColors = Object.values(x);
                const nextAvailableColor = forceSeriesColors.filter(y => !takenColors.includes(y))[0]
                return {
                    ...x,
                    [i]: nextAvailableColor
                };
            });
        }
    }, [selectedSeries]);

    const filteredYAxesDefinitions = useMemo(() => {
        return filteredSeries
            .map(x => x.yAxis)
            .filter(distinct)
            .map((yAxisName, i) => {
                    return yAxisDefinitions.find(y => y.name === yAxisName)!;
                }
            )
    }, [filteredSeries, yAxisDefinitions]);

    const filteredYAxes = useMemo<Axis[]>(() => {
        return filteredYAxesDefinitions.map((axis, i) => {
                return {
                    ticks: {
                        show: false,
                    },
                    grid: {width: 1},
                    stroke: "rgb(102, 102, 102)",
                    label: t(axis.name),
                    labelFont: "13px Helvetica, Arial, sans-serif",
                    labelSize: 30,
                    side: i % 2 === 0 ? 3 : 1,
                    scale: axis.name,
                    values: (self, ticks) => ticks.map(axis.yAxisValueFormatter || axis.valueFormatter)
                }
            }
        )
    }, [filteredYAxesDefinitions, t]);

    const filteredYScales = useMemo<Scales>(() => {
        return Object.fromEntries(filteredYAxesDefinitions
            .map((axis) => {
                    const scale: Scale = {
                        auto: false,
                        range: [axis.min, axis.max],
                        dir: axis.dir as (1 | -1)
                    };
                    return [axis.name, scale]
                }
            ))
    }, [filteredYAxesDefinitions]);

    const [uPlotBoundary, setUPlotBoundary] = useState<HTMLDivElement>();

    const {refs, floatingStyles, context} = useFloating({
        placement: "right",
        whileElementsMounted: autoUpdate,
        middleware: [offset(30), flip({boundary: uPlotBoundary})],
    });
    const clientPoint = useClientPoint(context);
    const {getFloatingProps} = useInteractions([
        clientPoint
    ]);

    const [tooltipValue, setTooltipValue]
        = useState<{ x: number, ys: (number | null | undefined)[] } | null>(null);
    const handleTooltipChanged = useCallback<TooltipChangedListener>(
        (x, ys, px, py) => {
            setTooltipValue({x, ys});
        }, []);
    const {width, height, ref: sizeDetectorRef} = useResizeDetector();

    const data = useMemo<[
        xValues: number[],
        ...yValues: number[][],
    ]>(() => filteredSeries.length > 0 ? [
        [...Array(filteredSeries[0].data.length).keys()].map((x, i) => i * 10), // 100 hz
        ...filteredSeries.map(x => x.data)
    ] : [[], []], [filteredSeries]);

    const zoomingWithTouch = useRef<boolean>(false);

    const callOnSelectionChange = useCallback(() => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            let min = u.scales.x.min;
            let max = Math.min(u.scales.x.max, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }, [onZoomChange, maxX]);

    const zoomFactor = 0.7;
    const zoomIn = () => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            const range = u.scales.x.max - u.scales.x.min;
            const newRange = range * zoomFactor;
            const rangeDiff = range - newRange;
            let min = Math.max(0, u.scales.x.min + rangeDiff / 2);
            let max = Math.min(u.scales.x.max - rangeDiff / 2, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }
    const resetZoom = () => {
        if (!uPlot.current) return;
        onZoomChange(0, maxX);
    }

    const zoomOut = () => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            const range = u.scales.x.max - u.scales.x.min;
            const newRange = range / zoomFactor;
            const rangeDiff = newRange - range;
            let min = Math.max(0, u.scales.x.min - rangeDiff / 2);
            let max = Math.min(u.scales.x.max + rangeDiff / 2, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }

    const handleSetScale = useCallback((u: uPlot, scaleKey: string) => {
        if (!zoomingWithTouch.current) // we only call callOnSelectionChange after the touch zooming is over, to prevent reinitializing the chart
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const xSpaceFn = useCallback<(self: uPlot, axisIdx: number, scaleMin: number, scaleMax: number, plotDim: number) => number>
    ((self, axisIdx, scaleMin, scaleMax, dim) => {
        const range = scaleMax - scaleMin;
        return range < 4000 ? 70 : 50;
    }, []);

    const xValuesFn = useCallback<Axis.DynamicValues>
    ((self, ticks, space) => ticks.map(tick => {
        const index = Math.floor(tick / 1000);
        const distance = distanceSeries?.[index]?.[1]?.toFixed(1);
        const range = ticks[ticks.length - 1] - ticks[0];
        let str;
        if (range < 4000) {
            str = String(moment(tick).utc().format("H:mm:ss.SSS"));
        } else {
            str = String(moment(tick).utc().format("H:mm:ss"));
        }
        if (distance)
            str += `\n${distance} km`;
        return str;
    }), [distanceSeries]);

    const handleCursorMove = useCallback<Cursor.MousePosRefiner>(
        (u, left, top) => {
            const pos = u.valToPos(data[0][u.posToIdx(left)], "x");
            return [pos, top];
        }, [data]);

    const handleZoomingChanged = useCallback((value: boolean) => {
        zoomingWithTouch.current = value;
        if (!value)
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const [cursorActive, setCursorActive] = useState(false);
    const handleCursorChanged = useCallback((u: uPlot) => {
        setCursorActive(u.cursor.left !== 0);
    }, []);

    const options = useMemo<uPlot.Options>(() => {
        return ({
            width: width ?? 800,
            height: height ?? 600,
            padding: [10, 10, 0, 0],
            hooks: {
                setScale: [handleSetScale],
                setCursor: [handleCursorChanged]
            },
            series: [
                {
                    label: "X"
                },
                ...filteredSeries.map(s => ({
                    stroke: seriesColors[s.id],
                    scale: s.yAxis
                }))
            ],
            scales: {
                "x": {
                    time: false,
                    min: zoomStart,
                    max: zoomEnd
                },
                ...filteredYScales
            },
            axes: [
                {
                    grid: {show: false},
                    ticks: {
                        show: true,
                        stroke: "#000000",
                        width: 1
                    },
                    space: xSpaceFn,
                    values: xValuesFn,
                },
                ...filteredYAxes
            ],
            legend: {
                show: false
            },
            cursor: {
                show: true,
                y: false,
                move: handleCursorMove
            },
            plugins: [
                new TouchZoomPlugin(handleZoomingChanged, maxX),
                new TooltipPlugin(handleTooltipChanged),
                new WheelPanPlugin(maxX)
            ]
        });
    }, [width, height, handleSetScale, handleCursorChanged, filteredSeries, zoomStart,
        zoomEnd, filteredYScales, xSpaceFn, xValuesFn, filteredYAxes, handleCursorMove,
        handleZoomingChanged, maxX, handleTooltipChanged, seriesColors]);

    const handleRefChanged = useCallback((e: HTMLDivElement) => {
        sizeDetectorRef(e);
        if (e)
            setUPlotBoundary(e);
    }, [sizeDetectorRef]);

    const tooltipDistance = distanceSeries?.[Math.floor((tooltipValue?.x ?? 0) / (isTracker ? 100 : 1000))]
        ?.[1]
    const [printMode, setPrintMode] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const printResolveRef = useRef<((value: any) => void)>();
    const handlePrint = useReactToPrint({
        removeAfterPrint: true,
        onBeforeGetContent: () => {
            return new Promise((resolve, reject) => {
                printResolveRef.current = resolve;
                setPrintMode(true);
            });
        },
        onAfterPrint: () => {
            setPrintMode(false);
        }
    });

    useLayoutEffect(() => {
        const saved = printResolveRef.current;
        if (printMode && saved) {
            printResolveRef.current = undefined;
            setTimeout(() => {
                saved(true);
            }, 1000)
        }
    }, [printMode]);

    return (
        <FullscreenDialogShim onClose={onDismiss}>
            <div className={`${styles.container} ${printMode && styles.print}`} ref={containerRef}>
                <div ref={handleRefChanged} className={styles.chartContainer}>
                    {height && <UPlot options={options} data={data} uPlotRef={uPlot}/>}
                </div>
                <div className={styles.seriesSelector}>
                    {(printMode ? series.filter(x =>
                        selectedSeries.includes(x.id)) : series)
                        .map(x => {
                                const isSelected = selectedSeries.includes(x.id);
                                return <Toggle key={x.id}
                                               name={x.name}
                                               isSelected={isSelected}
                                               onClick={() => toggleSelectedSeries(x.id)}
                                               color={seriesColors[x.id]}
                                />;
                            }
                        )}
                </div>
                {!printMode && <div className={styles.topRightMaxContainer}
                                    onMouseMove={() => {
                                        setTopRightContainerMouseOver(true)
                                    }}
                                    onMouseOver={() => setTopRightContainerMouseOver(true)}
                                    onMouseLeave={() => setTopRightContainerMouseOver(false)}>
                    <div className={styles.buttons}>
                        <Button onClick={() => {
                            handlePrint(null, () => containerRef.current as ReactInstance);
                        }} outline className={styles.printButton}>
                            <Icon path={mdiPrinter} size={0.8} className="me-2"/>
                            {t("print")}
                        </Button>
                        <Button size="sm" outline={true} className={styles.zoomButton100}
                                onClick={resetZoom}>
                            {t("reset")}
                        </Button>
                        <Button size="sm" outline={true} className={styles.zoomButton}
                                onClick={zoomIn}>
                            <Icon
                                path={mdiPlusCircleOutline} size={0.8}/>
                        </Button>
                        <Button size="sm" outline={true} className={styles.zoomButton} onClick={zoomOut}>
                            <Icon path={mdiMinusCircleOutline}
                                  size={0.8}/>
                        </Button>
                    </div>
                </div>}
                {!topRightContainerMouseOver && cursorActive && !printMode && <div
                    ref={refs.setFloating}
                    style={{
                        ...floatingStyles,
                        pointerEvents: "none",
                        background: "white",
                        boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.6)",
                        borderRadius: 2,
                        padding: 8,
                        fontFamily: "Helvetica, Arial, sans-serif",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.8em",
                        zIndex: 10
                    }}
                    {...getFloatingProps()}>
                    <ChartTooltipContent
                        x={tooltipValue?.x ?? 0}
                        distance={distanceSeries && distanceSeries.length > 0 && tooltipDistance}
                        points={tooltipValue?.ys?.map((x, i) => {
                            return ({
                                color: i < filteredSeries.length && seriesColors[filteredSeries[i].id],
                                y: x
                            });
                        }).filter(x => x.color)}
                        valueFormatters={filteredSeries.map(x =>
                            yAxisDefinitions.find(y => y.name === x.yAxis)!.valueFormatter)}
                        timeFormat={"H:mm:ss.SSS"}
                        units={filteredSeries.map(x => x.unit)}
                    />
                </div>}
            </div>
        </FullscreenDialogShim>
    );
};

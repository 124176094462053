import {App} from "./App";
import {AthletesContextProvider} from "./contexts/AthletesContext";
import {DialogContextProvider} from "./contexts/DialogContext";
import {ResponsiveContextProvider} from "./contexts/ResponsiveContext";
import {UserContextProvider} from "./contexts/UserContext";
import {StatisticsContextProvider} from "./contexts/StatisticsContext";
import {TrainingsFilterContextProvider} from "./contexts/TrainingsFilterContext";
import {LiveContextProvider} from "./contexts/LiveContext";

export const AppWrapper = () => (
    <ResponsiveContextProvider>
        <DialogContextProvider>
            <StatisticsContextProvider>
                <UserContextProvider>
                    <LiveContextProvider>
                        <AthletesContextProvider>
                            <TrainingsFilterContextProvider>
                                <App/>
                            </TrainingsFilterContextProvider>
                        </AthletesContextProvider>
                    </LiveContextProvider>
                </UserContextProvider>
            </StatisticsContextProvider>
        </DialogContextProvider>
    </ResponsiveContextProvider>
);

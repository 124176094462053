import { MouseEventHandler } from 'react';
import styles from './SelectItem.module.css';

interface ISelectItem {
    label: string;
    className?: string;
    onClick: MouseEventHandler<HTMLDivElement>;
}

export const SelectItem = ({ label, onClick, className }: ISelectItem) => {
    return (
        <div
            className={`${styles.container} ${className ?? ''}`}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

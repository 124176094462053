import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {SeriesType} from "../../../../typings/SeriesType";
import {tempoFormatter} from "../StandardGraphs/StandardGraphs";
import {Formatter} from "../../../../utils/formatter";

function sliceAvg(from: number, to: number, arr: number[]) {
    let acc = 0;
    let count = 0;
    for (let i = from; i <= to; i++) {
        acc += arr[i];
        count++;
    }
    return acc / count;
}

function sliceAvg2(from: number, to: number, arr: number[][]) {
    let acc = 0;
    let count = 0;
    for (let i = from; i <= to; i++) {
        for (const a of arr) {
            acc += a[i];
            count++;
        }
    }
    return acc / count;
}

function sliceMin(from: number, to: number, arr: number[]) {
    let min = Infinity;
    for (let i = from; i <= to; i++) {
        if (arr[i] < min)
            min = arr[i];
    }
    return min;
}

function sliceMax(from: number, to: number, arr: number[]) {
    let max = -Infinity;
    for (let i = from; i <= to; i++) {
        if (arr[i] > max)
            max = arr[i];
    }
    return max;
}

function sliceMax2(from: number, to: number, arr: number[][]) {
    let max = -Infinity;
    for (let i = from; i <= to; i++) {
        for (const a of arr) {
            if (a[i] > max)
                max = a[i];
        }
    }
    return max;
}

export interface ILapData {
    columns: string[];
    units: (string | null)[];
    values: string[][];
    lapEndsMs: number[];
}

interface IUseLap {
    lapDistanceM: number;
    heartRateSeries: (number[] | null)[];
    speedSeries: number[] | undefined;
    tempoSeries: number[] | undefined;
    tempo500Series: number[] | undefined;
    strokeSeries: number[] | undefined;
    forceLeftSeries: (number[] | null)[];
    forceRightSeries: (number[] | null)[];
    distanceSeries: number[] | undefined;
    distancePerStrokeSeries: number[] | undefined;
    isTracker: boolean;
    charts: SeriesType[];
    sliceStart: number;
    sliceEnd: number;
}

export function useLap({
                           lapDistanceM,
                           heartRateSeries,
                           speedSeries,
                           tempoSeries,
                           tempo500Series,
                           strokeSeries,
                           forceLeftSeries,
                           forceRightSeries,
                           distanceSeries,
                           distancePerStrokeSeries,
                           isTracker,
                           charts,
                           sliceStart,
                           sliceEnd
                       }: IUseLap): ILapData|null {
    const {t} = useTranslation();

    const distanceSeriesSlice = useMemo(() => {
        if (!distanceSeries)
            return null;
        const divider = isTracker ? 100 : 1000;
        return distanceSeries.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), distanceSeries.length - 1));
    }, [distanceSeries, isTracker, sliceStart, sliceEnd]);

    const speedSeriesSlice = useMemo(() => {
        if (!speedSeries)
            return null;
        const divider = isTracker ? 100 : 1000;
        return speedSeries.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), speedSeries.length - 1));
    }, [speedSeries, isTracker, sliceStart, sliceEnd]);

    const tempoSeriesSlice = useMemo(() => {
        if (!tempoSeries)
            return null;
        const divider = isTracker ? 100 : 1000;
        return tempoSeries.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), tempoSeries.length - 1));
    }, [tempoSeries, isTracker, sliceStart, sliceEnd]);

    const tempo500SeriesSlice = useMemo(() => {
        if (!tempo500Series)
            return null;
        const divider = isTracker ? 100 : 1000;
        return tempo500Series.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), tempo500Series.length - 1));
    }, [tempo500Series, isTracker, sliceStart, sliceEnd]);

    const strokeSeriesSlice = useMemo(() => {
        if (!strokeSeries)
            return null;
        const divider = 1000;
        return strokeSeries.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), strokeSeries.length - 1));
    }, [strokeSeries, sliceStart, sliceEnd]);

    const distancePerStrokeSeriesSlice = useMemo(() => {
        if (!distancePerStrokeSeries)
            return null;
        const divider = 1000;
        return distancePerStrokeSeries.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), distancePerStrokeSeries.length - 1));
    }, [distancePerStrokeSeries, sliceStart, sliceEnd]);

    const forceSeriesSlice = useMemo(() => {
            const nonNullForceSeries = [...forceLeftSeries, ...forceRightSeries]
                .filter(x => x) as number[][];
            if (nonNullForceSeries.length === 0)
                return [];
            const divider = 1000;
            return nonNullForceSeries.map(x => x.slice(Math.floor(sliceStart / divider),
                Math.min(Math.ceil(sliceEnd / divider), x.length - 1)));
        },
        [forceLeftSeries, forceRightSeries, sliceEnd, sliceStart]);

    const heartRateSeriesSlice = useMemo(() => {
        const nonNullHeartRateSeries = heartRateSeries
            .filter(x => x) as number[][]
        if (nonNullHeartRateSeries.length === 0)
            return [];
        const divider = 1000;
        return nonNullHeartRateSeries.map(x => x.slice(Math.floor(sliceStart / divider),
            Math.min(Math.ceil(sliceEnd / divider), x.length - 1)));
    }, [heartRateSeries, sliceStart, sliceEnd]);

    if (distanceSeriesSlice == null || lapDistanceM === 0)
        return null

    const columns = [t("duration"), t("distance")];
    const units: (string | null)[] = [null, "km"];
    const lapDistanceKm = lapDistanceM / 1000;
    const lapCount = Math.ceil(distanceSeriesSlice[distanceSeriesSlice.length - 1] / lapDistanceKm)
    const values: string[][] = [];
    const getLapEnds = () => {
        const res = [];
        let lapIndex = 0;
        for (let i = 0; i < distanceSeriesSlice.length; i++) {
            const distance = distanceSeriesSlice[i] - distanceSeriesSlice[0];
            const lapDistance = (lapIndex + 1) * lapDistanceKm;
            if (distance > lapDistance) {
                res.push((isTracker ? 100 : 1000) * (i - 1));
                lapIndex++;
                if (res.length === lapCount - 1) break
            }
        }
        res.push((isTracker ? 100 : 1000) * (distanceSeriesSlice.length - 1));
        return res;
    }
    const lapEndsMs = getLapEnds();
    let lastLapEndMs = 0;
    let lastSpeedTypeSeriesEndIndex = 0;
    let lastSeriesEndIndex = 0;
    let lastDistance = distanceSeriesSlice[0];
    let durationSum = 0;
    let distanceSum = 0;
    for (let i = 0; i < lapEndsMs.length; i++) {
        const lapEndMs = lapEndsMs[i];
        const speedTypeSeriesEndIndex = Math.floor(lapEndMs / (isTracker ? 100 : 1000));
        const seriesEndIndex = Math.floor(lapEndMs / 1000);
        const row: string[] = [];
        // duration
        const duration = Math.floor((lapEndMs - lastLapEndMs) / 1000);
        lastLapEndMs = lapEndMs;
        row.push(Formatter.secToTimeString(duration));
        durationSum += duration;
        // distance
        const distance = distanceSeriesSlice[speedTypeSeriesEndIndex] - lastDistance;
        row.push(distance.toFixed(2));
        distanceSum += distance;
        lastDistance = distanceSeriesSlice[speedTypeSeriesEndIndex];
        for (const chart of charts) {
            switch (chart) {
                case SeriesType.SPEEDS:
                    if (speedSeriesSlice) {
                        if (i === 0) {
                            columns.push(t("avg speed"), t("max speed"));
                            units.push("km/h", "km/h");
                        }
                        row.push(sliceAvg(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            speedSeriesSlice).toFixed(1));
                        row.push(sliceMax(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            speedSeriesSlice).toFixed(1));
                    }
                    break;
                case SeriesType.TEMPOS:
                    if (tempoSeriesSlice) {
                        if (i === 0) {
                            columns.push(t("avg tempo"), t("max tempo"));
                            units.push(null, null);
                        }
                        row.push(tempoFormatter(sliceAvg(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            tempoSeriesSlice)));
                        row.push(tempoFormatter(sliceMin(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            tempoSeriesSlice)));
                    }
                    break;
                case SeriesType.TEMPO_500S:
                    if (tempo500SeriesSlice) {
                        if (i === 0) {
                            columns.push(t("avg tempo split"), t("max tempo split"));
                            units.push(null, null);
                        }
                        row.push(tempoFormatter(sliceAvg(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            tempo500SeriesSlice)));
                        row.push(tempoFormatter(sliceMin(lastSpeedTypeSeriesEndIndex, speedTypeSeriesEndIndex,
                            tempo500SeriesSlice)));
                    }
                    break;
                case SeriesType.STROKES:
                    if (strokeSeriesSlice) {
                        if (i === 0) {
                            columns.push(t("avg stroke rate"), t("max stroke rate"));
                            units.push("spm", "spm");
                        }
                        row.push(sliceAvg(lastSeriesEndIndex, seriesEndIndex,
                            strokeSeriesSlice).toFixed(1));
                        row.push(sliceMax(lastSeriesEndIndex, seriesEndIndex,
                            strokeSeriesSlice).toFixed(1));
                    }
                    break;
                case SeriesType.HEARTRATE:
                    if (heartRateSeriesSlice.length > 0) {
                        if (i === 0) {
                            columns.push(t("avg heart rate"), t("max heart rate"));
                            units.push("bpm", "bpm");
                        }
                        row.push(sliceAvg2(lastSeriesEndIndex, seriesEndIndex,
                            heartRateSeriesSlice).toFixed(1));
                        row.push(sliceMax2(lastSeriesEndIndex, seriesEndIndex,
                            heartRateSeriesSlice).toFixed(1));
                    }
                    break;
                case SeriesType.DISTANCEPERSTROKE:
                    if (distancePerStrokeSeriesSlice) {
                        if (i === 0) {
                            columns.push(t("avg distance per stroke"), t("max distance per stroke"));
                            units.push("m", "m");
                        }
                        row.push(sliceAvg(lastSeriesEndIndex, seriesEndIndex,
                            distancePerStrokeSeriesSlice).toFixed(3));
                        row.push(sliceMax(lastSeriesEndIndex, seriesEndIndex,
                            distancePerStrokeSeriesSlice).toFixed(3));
                    }
                    break;
                case SeriesType.PULLING_FORCE:
                    if (forceSeriesSlice.length > 0) {
                        if (i === 0) {
                            columns.push(t("avg paddling force"), t("max paddling force"));
                            units.push("N", "N");
                        }
                        row.push(sliceAvg2(lastSeriesEndIndex, seriesEndIndex,
                            forceSeriesSlice).toFixed(1));
                        row.push(sliceMax2(lastSeriesEndIndex, seriesEndIndex,
                            forceSeriesSlice).toFixed(1));
                    }
                    break;
            }
        }
        if (i === 0) {
            columns.push(t("part duration"), t("part distance"));
            units.push(null, "km");
        }
        row.push(Formatter.secToTimeString(durationSum));
        row.push(distanceSum.toFixed(2));
        values.push(row);
        lastSpeedTypeSeriesEndIndex = speedTypeSeriesEndIndex;
        lastSeriesEndIndex = seriesEndIndex;
    }
    const lapEndsAbsoluteMs = lapEndsMs.map(x => x + sliceStart);
    return {
        columns,
        units,
        values,
        lapEndsMs: lapEndsAbsoluteMs
    }
}

import {MutableRefObject, useEffect, useRef, useState} from 'react';
import styles from './TrainingMap.module.css';
import {ILatLng} from "../../../../typings/IApiTrainingCommonBase";
import GoogleMap from "google-maps-react-markers";

interface ITrainingMap {
    selectStart: number;
    selectEnd: number;
    coords: ILatLng[];
    mapRef: MutableRefObject<any>;
    mapsRef: MutableRefObject<any>;
}

export const TrainingMap = ({coords, selectStart, selectEnd, mapRef: externalMapRef, mapsRef: externalMapsRef}: ITrainingMap) => {
    const mapRef = useRef<any | null>(null);
    const mapsRef = useRef<any | null>(null);
    const polyActiveRef = useRef<any>(undefined);
    const polyStartRef = useRef<any>(undefined);
    const polyEndRef = useRef<any>(undefined);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);

    const mapsApiLoaded = (map: any, maps: any) => {
        mapRef.current = map;
        mapsRef.current = maps;
        externalMapRef.current = mapRef.current;
        externalMapsRef.current = mapsRef.current;
        setMapLoaded(true);
    };

    useEffect(() => {
        const map = mapRef.current;
        const maps = mapsRef.current;
        if (!map || !maps) return;
        try {
            if (polyStartRef.current) polyStartRef.current.setMap(null);
            if (polyActiveRef.current) polyActiveRef.current.setMap(null);
            if (polyEndRef.current) polyEndRef.current.setMap(null);
            if (coords.length === 0) return;

            const startCoords = coords.slice(0, selectStart);
            const activeCoords = coords.slice(selectStart, selectEnd);
            const endCoords = coords.slice(selectEnd);

            if (startCoords.length > 0) {
                polyStartRef.current = new maps.Polyline({
                    path: startCoords,
                    geodesic: true,
                    strokeColor: "#FF000044",
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });
                polyStartRef.current.setMap(map);
            }
            if (endCoords.length > 0) {
                polyEndRef.current = new maps.Polyline({
                    path: endCoords,
                    geodesic: true,
                    strokeColor: "#FF000044",
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });
                polyEndRef.current.setMap(map);
            }
            if (activeCoords.length > 0) {
                polyActiveRef.current = new maps.Polyline({
                    path: activeCoords,
                    geodesic: true,
                    strokeColor: "#FF0000",
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });
                polyActiveRef.current.setMap(map);
                const bounds = new maps.LatLngBounds();
                for (const coord of activeCoords) {
                    bounds.extend(coord);
                }
                map.fitBounds(bounds, 10);
            }else {
                const bounds = new maps.LatLngBounds();
                for (const coord of coords) {
                    bounds.extend(coord);
                }
                map.fitBounds(bounds, 10);
            }
        } catch (e) {
            console.log(e);
        }
    }, [mapLoaded, coords, selectStart, selectEnd]);

    return (
        <div className={styles.container}>
            <GoogleMap
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                defaultCenter={{lat: 47, lng: 18}}
                defaultZoom={14}
                onGoogleApiLoaded={({map, maps}) => mapsApiLoaded(map, maps)}
                options={{streetViewControl: false, mapTypeControl: false}}
            />
        </div>
    );
};

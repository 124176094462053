import * as React from "react";
import styles from "./InfoTooltip.module.scss";
import {useTranslation} from "react-i18next";
import {Card} from "reactstrap";

export const InfoTooltip = ({content}: { content: string }) => {
    const {t} = useTranslation();
    return <Card className={styles.container}>
        {t(content)}
    </Card>
}
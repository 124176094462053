import { mdiLogin } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../components/base/Button/Button';
import { SizedBox } from '../../components/base/SizedBox/SizedBox';
import { TextInput } from '../../components/base/TextInput/TextInput';
import { CardLayout } from '../../components/layouts/CardLayout/CardLayout';
import UserContext from '../../contexts/UserContext';
import styles from './ForgottenPasswordPage.module.css';

interface IForgottenPasswordPage {

}

let blocked = false;

export const ForgottenPasswordPage = ({ }: IForgottenPasswordPage) => {
    const { t } = useTranslation();
    const userContext = useContext(UserContext);
    const [email, setEmail] = useState<string>("");
    const [success, setSuccess] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const onResetPassword = useCallback(async () => {
        if (blocked) return;
        if (!email) return setError(t('no email error'));
        blocked = true;
        try {
            const status = await userContext.resetPasswordRequest(email);
            switch (status) {
                case 200:
                    setSuccess(t('reset password request sent'));
                    break;
                case 404:
                    setError(t('reset password email not found'));
                    break;
                default:
                    setError(t('reset password request error'))
                    break;
            }
        } catch (e) {
            setError(t('reset password request error'))
        }
        blocked = false;
    }, [email, userContext, t]);

    return (
        <div className={styles.container}>
            <CardLayout
                title={t('forgotten password title')}
                subtitle={t('forgotten password subtitle')}
            >
                {success &&
                    <div className={styles.successLabel}>{success}</div>
                }
                {error &&
                    <div className={styles.errorLabel}>{error}</div>
                }
                {!error && !success &&
                    <SizedBox height={20} />
                }
                <TextInput
                    label={t('email')}
                    value={email}
                    type="email"
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setError(null);
                    }}
                />
                <SizedBox height={48} />
                <Button
                    label={t("reset password")}
                    onClick={onResetPassword}
                />
                <SizedBox height={24} />
                <div className={styles.linkContainer}>
                    <Link className={styles.link} to={"/login"}>
                        <Icon className={styles.login} path={mdiLogin} size={0.7} />
                        {t('login')}
                    </Link>
                </div>
            </CardLayout>
        </div>
    );
};

import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./ImuGraph.module.scss";
import {forceSeriesColors, zoneWidth} from "../StandardGraphs/StandardGraphs";
import {ChartTooltipContent} from "../ChartTooltipContent/ChartTooltipContent";
import {autoUpdate, Boundary, flip, offset, useFloating} from "@floating-ui/react";
import {TooltipChangedListener, TooltipPlugin} from "../../../uplot/TooltipPlugin";
import {useResizeDetector} from "react-resize-detector";
import {Axis, Cursor} from "uplot";
import moment from "moment";
import {TouchZoomPlugin} from "../../../uplot/TouchZoomPlugin";
import {WheelPanPlugin} from "../../../uplot/WheelPanPlugin";
import {UPlot} from "../../../uplot/UPlot";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiFullscreen} from "@mdi/js";
import {PlotLinesPlugin} from "../../../uplot/PlotLinesPlugin";

const Toggle = ({isSelected, onClick, name, color}: any) => {
    return <div className={`${styles.pill} ${isSelected ? styles.selected : ""}
    ${isSelected}`}
                onClick={onClick}>
        {color && <div
            className={styles.color}
            style={{
                backgroundColor: color
            }}>
        </div>}
        {name}
    </div>
}

const chartPaddingTop = 10;


interface IImuGraph {
    imuSeries: number[][];
    distanceSeries: number[][] | null;
    bandStart: number | null;
    bandEnd: number | null;
    plotLines: number[];
    zoomStart: number;
    zoomEnd: number;
    maxX: number;
    hasZones: boolean;
    isTracker: boolean;
    cursor: MutableRefObject<number>;
    showCrosshair: boolean;
    onZoomChange: (min: number, max: number) => void;
    onCursorChange: (cursor: number) => void;
    onMouseOver: (over: boolean) => void;
    onFullscreenClick: () => void;
}

export const ImuGraph = ({
                             imuSeries,
                             distanceSeries,
                             bandStart,
                             bandEnd,
                             plotLines,
                             zoomStart,
                             zoomEnd,
                             maxX,
                             hasZones,
                             cursor,
                             isTracker,
                             showCrosshair,
                             onZoomChange,
                             onCursorChange,
                             onMouseOver,
                             onFullscreenClick
                         }: IImuGraph) => {
    const {t} = useTranslation();
    const uPlot = useRef<uPlot>();
    const [selectedSeriesIndexes, setSelectedSeriesIndexes] = useState<number[]>([...Array(imuSeries[0].length).keys()]);
    const [topRightContainerMouseOver, setTopRightContainerMouseOver] = useState(false);

    const series = useMemo(() =>
        [...Array(imuSeries[0].length).keys()].map((i) => {
                return ({
                    data: imuSeries.map(x => x[i]),
                    color: forceSeriesColors[i < 3 ? 0 : 1],
                    name: ["x", "y", "z"][i % 3],
                    unit: i < 3 ? "milliG" : "°/s",
                    decimals: i < 3 ? 0 : 1
                });
            }
        ), [imuSeries]);

    const filteredSeries = useMemo(() => {
        return series.filter((x, i) => selectedSeriesIndexes.includes(i))
    }, [selectedSeriesIndexes, series]);

    const toggleSelectedSeries = useCallback((index: number) => {
        if (selectedSeriesIndexes.includes(index)) {
            if (selectedSeriesIndexes.length > 1)
                setSelectedSeriesIndexes(x => x.filter(y => y !== index));
        } else
            setSelectedSeriesIndexes(x => [...x, index]);
    }, [selectedSeriesIndexes]);

    const toggleManySelectedSeries = useCallback((group: string) => {
        if (group === "a") {
            const selectedIndexes = selectedSeriesIndexes.filter(x => x < 3)
            if (selectedIndexes.length > 0) {
                setSelectedSeriesIndexes(x => {
                    const filtered = x.filter(y => y >= 3);
                    return filtered.length === 0 ? x : filtered;
                })
            } else {
                setSelectedSeriesIndexes(x => [...x, 0, 1, 2])
            }
        } else if (group === "g") {
            const selectedIndexes = selectedSeriesIndexes.filter(x => x >= 3)
            if (selectedIndexes.length > 0) {
                setSelectedSeriesIndexes(x => {
                    const filtered = x.filter(y => y < 3);
                    return filtered.length === 0 ? x : filtered;
                })
            } else {
                setSelectedSeriesIndexes(x => [...x, 3, 4, 5])
            }
        }
    }, [selectedSeriesIndexes]);

    const [uPlotBoundary, setUPlotBoundary] = useState<Boundary>();

    const {refs, floatingStyles} = useFloating({
        placement: "right-end",
        whileElementsMounted: autoUpdate,
        middleware: [offset(30), flip({boundary: uPlotBoundary})],
    });

    const [tooltipValue, setTooltipValue]
        = useState<{ x: number, ys: (number | null | undefined)[] } | null>(null);
    const handleTooltipChanged = useCallback<TooltipChangedListener>(
        (x, ys, px, py) => {
            refs.setReference({
                getBoundingClientRect: () =>
                    ({
                        x: px,
                        y: py + 20,
                        top: py + 20,
                        left: px,
                        bottom: py + 20,
                        right: px,
                        width: 0,
                        height: 0,
                    }),
            });
            setTooltipValue({x, ys});
        }, [refs]);
    const handleTooltipVisibleChanged = useCallback(
        (value: boolean) => {
            onMouseOver(value);
        }, [onMouseOver]);
    const {width, ref: sizeDetectorRef} = useResizeDetector();

    const data = useMemo<[
        xValues: number[],
        ...yValues: number[][],
    ]>(() => [
        [...Array(series[0].data.length).keys()].map((x, i) => i * 10), // 100 hz is the frequency
        ...filteredSeries.map(x => x.data)
    ], [filteredSeries, series]);

    const zoomingWithTouch = useRef<boolean>(false);

    const callOnSelectionChange = useCallback(() => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            let min = u.scales.x.min;
            let max = Math.min(u.scales.x.max, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }, [onZoomChange, maxX]);

    const handleSetScale = useCallback((u: uPlot, scaleKey: string) => {
        if (!zoomingWithTouch.current) // we only call callOnSelectionChange after the touch zooming is over, to prevent reinitializing the chart
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleDrawBands = useCallback((u: uPlot) => { // draw bands with custom renderer
        if (bandStart == null || bandEnd == null) return;
        const min = u.scales.x.min ?? 0;
        const max = u.scales.x.max ?? 0;
        // do not draw out of range
        if ((bandStart < min && bandEnd < min) || (bandStart > max && bandEnd > max))
            return;
        let {ctx} = u;
        const height = u.bbox.height - (u.axes[0].ticks?.size ?? 0);

        ctx.save();

        ctx.fillStyle = "rgba(140,140,140,0.07)";

        const clampedBandStart = (bandStart < min && bandEnd > min) ? min : bandStart;
        const clampedBandEnd = (bandEnd > max && bandEnd < max) ? max : bandEnd;
        const startPx = Math.round(u.valToPos(clampedBandStart, "x", true));
        const endPx = Math.round(u.valToPos(clampedBandEnd, "x", true));
        ctx.fillRect(startPx, chartPaddingTop, endPx - startPx, chartPaddingTop + height);

        ctx.restore();
    }, [bandEnd, bandStart]);

    const xSpaceFn = useCallback<(self: uPlot, axisIdx: number, scaleMin: number, scaleMax: number, plotDim: number) => number>
    ((self, axisIdx, scaleMin, scaleMax, dim) => {
        const range = scaleMax - scaleMin;
        return range < 4000 ? 70 : 50;
    }, []);

    const xValuesFn = useCallback<Axis.DynamicValues>
    ((self, ticks, space) => ticks.map(tick => {
        const index = Math.floor(tick / 1000);
        const distance = distanceSeries?.[index]?.[1]?.toFixed(1);
        const range = ticks[ticks.length - 1] - ticks[0];
        let str;
        if (range < 4000) {
            str = String(moment(tick).utc().format("H:mm:ss.SSS"));
        } else {
            str = String(moment(tick).utc().format("H:mm:ss"));
        }
        if (distance)
            str += `\n${distance} km`;
        return str;
    }), [distanceSeries]);

    const handleCursorMove = useCallback<Cursor.MousePosRefiner>((u, left, top) => {
        const pos = u.valToPos(data[0][u.posToIdx(left)], "x");
        return [pos, top];
    }, [data]);

    const handleZoomingChanged = useCallback((value: boolean) => {
        zoomingWithTouch.current = value;
        if (!value)
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleCursorChanged = useCallback((u: uPlot) => {
        if (u.cursor.left !== undefined)
            onCursorChange(Math.floor(u.posToVal(u.cursor.left, "x")))
    }, [onCursorChange]);

    useEffect(() => {
        const t = window.setInterval(() => {
            if (!uPlot.current) return;
            uPlot.current.setCursor({left: uPlot.current.valToPos(cursor.current, "x"), top: 0});
        }, 200);
        return () => clearInterval(t);
    }, [cursor]);

    const options = useMemo<uPlot.Options>(() => {
        return ({
            width: width ?? 1224,
            height: 300,
            padding: [chartPaddingTop, 10, 0, 0],
            hooks: {
                setScale: [handleSetScale],
                drawAxes: [handleDrawBands],
                setCursor: [handleCursorChanged]
            },
            series: [
                {
                    label: "X"
                },
                ...filteredSeries.map(s => ({
                    stroke: s.color
                }))
            ],
            scales: {
                "x": {
                    time: false,
                    min: zoomStart,
                    max: zoomEnd
                },
            },
            axes: [
                {
                    grid: {show: false},
                    ticks: {
                        show: true,
                        stroke: "#000000",
                        width: 1
                    },
                    space: xSpaceFn,
                    values: xValuesFn,
                },
                {
                    ticks: {
                        show: false,
                    },
                    grid: {width: 1},
                    stroke: "rgb(102, 102, 102)",
                    label: t("imu"),
                    labelFont: "13px Helvetica, Arial, sans-serif",
                    labelSize: 30
                },
            ],
            legend: {
                show: false
            },
            cursor: {
                show: true,
                y: false,
                move: handleCursorMove
            },
            plugins: [
                new TouchZoomPlugin(handleZoomingChanged, maxX),
                new TooltipPlugin(handleTooltipChanged, handleTooltipVisibleChanged),
                new WheelPanPlugin(maxX),
                new PlotLinesPlugin(plotLines)
            ]
        });
    }, [width, handleSetScale, handleDrawBands, handleCursorChanged, filteredSeries, zoomStart, zoomEnd,
        xSpaceFn, xValuesFn, t, handleCursorMove, handleZoomingChanged, maxX, handleTooltipChanged,
        handleTooltipVisibleChanged, plotLines]);

    const handleRefChanged = useCallback((e: any) => {
        sizeDetectorRef(e);
        if (e)
            setUPlotBoundary(e);
    }, [sizeDetectorRef]);

    const tooltipDistance = distanceSeries?.[Math.floor((tooltipValue?.x ?? 0) / (isTracker ? 100 : 1000))]
        ?.[1]

    return <>
        <div
            className={styles.container}
        >
            <div style={{flex: 1, display: "flex", flexDirection: "column", minWidth: 0, position: "relative"}}>
                <div ref={handleRefChanged}>
                    <UPlot options={options}
                           data={data}
                           uPlotRef={uPlot}
                    />
                </div>
                <div className={styles.topRightMaxContainer}
                     onMouseMove={() => {
                         setTopRightContainerMouseOver(true)
                     }}
                     onMouseOver={() => setTopRightContainerMouseOver(true)}
                     onMouseLeave={() => setTopRightContainerMouseOver(false)}>
                    <div className={styles.rowWrapper}>
                        <Toggle isSelected={selectedSeriesIndexes.some(x => x < 3)}
                                onClick={() => toggleManySelectedSeries("a")}
                                name={"Acc"}
                                color={forceSeriesColors[0]}
                        />
                        {series
                            ?.map((x, i) => ({x, i}))
                            ?.filter(({x, i}) => i < 3)
                            ?.map(({x, i}) =>
                                <Toggle key={i} isSelected={selectedSeriesIndexes.includes(i)}
                                        onClick={() => toggleSelectedSeries(i)}
                                        name={x.name}
                                />
                            )}
                    </div>
                    <div className={styles.rowWrapper}>
                        <Toggle isSelected={selectedSeriesIndexes.some(x => x >= 3)}
                                onClick={() => toggleManySelectedSeries("g")}
                                name={"Gyro"}
                                color={forceSeriesColors[1]}
                        />
                        {series
                            ?.map((x, i) => ({x, i}))
                            ?.filter(({x, i}) => i >= 3)
                            ?.map(({x, i}) =>
                                <Toggle key={i} isSelected={selectedSeriesIndexes.includes(i)}
                                        onClick={() => toggleSelectedSeries(i)}
                                        name={x.name}
                                />
                            )}
                    </div>
                    <Button size="sm" outline={true} className={styles.fullscreenButton} onClick={onFullscreenClick}>
                        <Icon path={mdiFullscreen}
                              size={0.8}/>
                    </Button>
                </div>
            </div>
            {hasZones && <div className={styles.zonesContainer}>
                <div style={{width: zoneWidth, height: 1}}/>
            </div>}
        </div>
        {showCrosshair && !topRightContainerMouseOver && <div
            ref={refs.setFloating}
            style={{
                ...floatingStyles,
                pointerEvents: "none",
                background: "white",
                boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.6)",
                borderRadius: 2,
                padding: 8,
                fontFamily: "Helvetica, Arial, sans-serif",
                display: "flex",
                alignItems: "center",
                fontSize: "0.8em",
                zIndex: 10
            }}>
            <ChartTooltipContent
                x={tooltipValue?.x ?? 0}
                distance={distanceSeries && distanceSeries.length > 0 && tooltipDistance}
                points={tooltipValue?.ys?.map((x, i) => {
                    return ({
                        color: filteredSeries[i]?.color,
                        y: x
                    });
                }).filter(x => x.color)}
                valueFormatters={filteredSeries.map((s) => (
                    (x: number) => x.toFixed(s.decimals).replace('-0', '0')
                ))}
                timeFormat={"H:mm:ss.SSS"}
                units={filteredSeries.map(x => x.unit)}
            />
        </div>}
    </>;
};

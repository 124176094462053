
interface ISpacer {
    flex?: number;
}

export const Spacer = ({ flex = 1 }: ISpacer) => {
    return (
        <div style={{ flex }} />
    );
};

import {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import {LoginState} from "../../../typings/LoginState";

const NonAuthRoute = () => {
    const userContext = useContext(UserContext);

    if (userContext.loginState === LoginState.loggedout) {
        return <Outlet />;
    } else if (userContext.loginState === LoginState.loggedin) {
        return <Navigate to="/dashboard" replace />;
    }
    return <div></div>;
}

export default NonAuthRoute;

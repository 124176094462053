import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./LiveHome.module.scss";
import AthletesContext from "../../../../contexts/AthletesContext";
import {Spinner} from "../../../base/Spinner/Spinner";
import {Api} from "../../../../utils/api";
import {TrainingSport} from "../../../../typings/TrainingSport";
import {max} from "../../../../utils/arrayUtils";
import Icon from "@mdi/react";
import {mdiChartBar, mdiMap} from "@mdi/js";
import {LiveCharts} from "../LiveCharts/LiveCharts";
import {LiveMap} from "../LiveMap/LiveMap";
import {LiveBoat} from "../LiveBoat/LiveBoat";
import moment from "moment/moment";
import {Card} from "reactstrap";
import {Link} from "react-router-dom";
import UserContext from "../../../../contexts/UserContext";
import {Moment} from "moment";
import {IApiLiveDataSeat} from "../../../../typings/IApiLiveData";
import {LiveApi} from "../../../../utils/liveApi";


export interface ILiveAthleteStat {
    hr: number | null,
    force: number | null,
}

export interface ILiveBoatStat {
    speed: number | null,
    strokeRate: number | null,
    location: { lat: number, lng: number } | null;
}

export interface ILiveBoat {
    id: string;
    color: string;
    isActive: boolean;
    createdAt: Moment | null;
    distance?: number;
    speed?: number;
    strokeRate?: number;
    distancePerStroke: number;
    location?: { lat: number, lng: number };
    sport?: TrainingSport,
    athletes: ILiveBoatAthlete[];
    stats?: ILiveBoatStat[];
    subscriptionExpired: boolean;
}

export interface ILiveBoatAthlete {
    name: string;
    color?: string;
    hr?: number;
    force?: number;
    maxForce?: number;
    forceSeriesLeft?: number[],
    forceSeriesRight?: number[],
    stats?: ILiveAthleteStat[];
}

const statsById: { [key: string]: ILiveBoatStat[] } = {};
const athleteStatsByLocalId: { [key: string]: ILiveAthleteStat[] } = {};
const statsSize = 15 * 60;

function getBoatColor(seats: IApiLiveDataSeat[], liveAthletes: { color: string; id: number }[]) {
    for (const seat of seats) {
        const la = liveAthletes.find(x => x.id === seat.athlete.id)
        if (la)
            return la.color
    }
    return "#ffffff";
}

export const LiveHome = () => {
    const {t} = useTranslation();
    const {athletes} = useContext(AthletesContext);
    const {user} = useContext(UserContext);
    const [liveAthletes, setLiveAthletes] = useState<{
        color: string;
        name: string;
        id: number;
    }[]>([]);
    const [selectedBoatId, setSelectedBoatId] = useState<string>();
    const [boats, setBoats] = useState<ILiveBoat[]>([]);
    const [boatsLoaded, setBoatsLoaded] = useState(false);
    const [tabState, setTabState] = useState("charts");
    const [lastBoatSelectTime, setLastBoatSelectTime] = useState(0);

    useEffect(() => {
        const get = async () => {
            const las = await Api.getLiveAthletes(true);
            setLiveAthletes([...las.map(x => ({
                id: x.athlete.id,
                name: x.athlete.name,
                color: x.color
            })), {
                id: -1,
                name: t("guest"),
                color: "#3ab3e5"
            }]);
        };
        get().catch(console.log);
    }, [t]);

    useEffect(() => {
        let timeOutRef: any;
        let stopped = false;
        const update = async () => {
            const liveDatas = await LiveApi.getData(liveAthletes.map(x => x.id), user!.id)
            // const liveDatas: IApiLiveData[] = [
            //     {
            //         id: "sdf",
            //         sport: TrainingSport.C1,
            //         seats: [
            //             {
            //                 athlete: {
            //                     name: "ath1",
            //                     id: 116,
            //                     hrZones: []
            //                 }
            //             }
            //         ],
            //         subscriptionExpired: true,
            //         createdAt: ""
            //     }
            // ]
            const emptyAthleteStat: ILiveAthleteStat = {
                hr: null,
                force: null
            };
            const emptyBoatStat: ILiveBoatStat = {
                speed: null,
                strokeRate: null,
                location: null
            };
            for (const key of Object.keys(statsById)) {
                if (statsById[key].length > statsSize)
                    statsById[key].shift();
                statsById[key].push(emptyBoatStat);
            }
            for (const key of Object.keys(athleteStatsByLocalId)) {
                if (athleteStatsByLocalId[key].length > statsSize)
                    athleteStatsByLocalId[key].shift();
                athleteStatsByLocalId[key].push(emptyAthleteStat);
            }
            const newBoats = liveDatas.map<ILiveBoat>(ld => {
                const createdAt = (ld.createdAt && moment.utc(ld.createdAt)) || null;
                const isActive = (createdAt && createdAt.diff(moment(), "hours") > -1)!!;
                //TODO refactor: move out of map()
                if (!statsById[ld.id]) {
                    statsById[ld.id] = Array.from({length: statsSize}, () => emptyBoatStat);
                }
                statsById[ld.id][statsById[ld.id].length - 1] = {
                    speed: ld.speedKmph,
                    strokeRate: ld.strokeRateSpm,
                    location: ld.coordinate
                } as ILiveBoatStat;
                let dps = (ld.speedKmph && ld.speedKmph * 1000 / ((ld.strokeRateSpm ?? 0) * 60)) || 0;
                if (Number.isNaN(dps) || !Number.isFinite(dps) || dps === undefined) {
                    dps = 0;
                }
                return {
                    id: ld.id,
                    isActive,
                    color: getBoatColor(ld.seats, liveAthletes),
                    createdAt: createdAt,
                    distance: ld.distanceKm,
                    speed: ld.speedKmph,
                    strokeRate: ld.strokeRateSpm,
                    distancePerStroke: dps,
                    location: ld.coordinate,
                    sport: ld.sport,
                    subscriptionExpired: ld.subscriptionExpired,
                    athletes: ld.seats.map((seat, i) => {
                        const localId = `${ld.id}-${i}`;
                        //TODO refactor: move out of from map
                        if (!athleteStatsByLocalId[localId]) {
                            athleteStatsByLocalId[localId] = Array.from({length: statsSize}, () => emptyAthleteStat);
                        }
                        athleteStatsByLocalId[localId][athleteStatsByLocalId[localId].length - 1] = {
                            hr: seat.hrBpm || 0,
                            force: seat.avgForceN || 0
                        };
                        return ({
                            name: seat.athlete.name,
                            color: liveAthletes.find(x => x.id === seat.athlete.id)?.color,
                            hr: seat.hrBpm,
                            force: seat.avgForceN,
                            maxForce: max(athleteStatsByLocalId[localId].slice(-30).map(x => x.force || 0)),
                            forceSeriesLeft: seat.leftForcesN,
                            forceSeriesRight: seat.rightForcesN,
                            stats: athleteStatsByLocalId[localId],
                        });
                    }),
                    stats: statsById[ld.id],
                };
            });
            setBoats(newBoats.sort((a, b) => Number(b.isActive) - Number(a.isActive)))
            setBoatsLoaded(true);
            // update selected boat to the first one
            const hasNewBoats = newBoats.length > 0;
            const existingSelectedBoat = newBoats.find(x => x.id === selectedBoatId);
            if (hasNewBoats && (selectedBoatId === undefined || !existingSelectedBoat) && !newBoats[0].subscriptionExpired) {
                setSelectedBoatId(newBoats[0].id);
            } else if (existingSelectedBoat && existingSelectedBoat.subscriptionExpired) {
                setSelectedBoatId(undefined);
            }
        };

        const startTimeout = () => {
            if (stopped) return;
            timeOutRef = setTimeout(() => {
                update().catch(console.log)
                    .finally(startTimeout);
            }, 1000);
        }
        startTimeout();
        return () => {
            if (timeOutRef)
                clearTimeout(timeOutRef);
            stopped = true;
        };
    }, [liveAthletes, selectedBoatId, user]);

    const toggleTab = (tab: string) => {
        setTabState(x => x === tab ? "" : tab);
    }

    const selectedBoat = boats.find(x => x.id === selectedBoatId);
    const activeBoats = boats.filter(x => x.isActive);
    const handleBoatClick = (id: string) => {
        setSelectedBoatId(id);
        setLastBoatSelectTime(Date.now())
    };

    function getMapPath() {
        let mapPath = selectedBoatId !== undefined && statsById[selectedBoatId] ?
            statsById[selectedBoatId]
                .map(x => x.location)
                .filter(x => x) : [];
        //return mapPath;
        if (mapPath.length > 1) { // take locations that are close to the previous
            const filtered: any[] = [];

            function haversineDistanceKm(p1: any, p2: any) {
                const R = 6371; // Radius of the Earth in km
                const rlat1 = p1.lat * (Math.PI / 180);
                const rlat2 = p2.lat * (Math.PI / 180);
                const difflat = rlat2 - rlat1;
                const difflon = (p1.lng - p2.lng) * (Math.PI / 180);
                return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2)
                    + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
            }

            let lastElement = mapPath[mapPath.length - 1];
            for (let i = mapPath.length - 1; i >= 0; i--) {
                const distanceThresholdM = 2000;
                if (haversineDistanceKm(mapPath[i], lastElement) * 1000 < distanceThresholdM) {
                    filtered.push(mapPath[i])
                    lastElement = mapPath[i];
                } else break;
            }

            return filtered;
        } else {
            return mapPath;
        }
    }

    if (athletes === null || !boatsLoaded)
        return <div className={styles.loading}>
            <Spinner/>
        </div>
    if (athletes.length === 0)
        return <div className={styles.noAthletes}>
            {t("no athletes")}
        </div>

    if (boatsLoaded && boats.length === 0)
        return <div className={styles.noAthletes}>
            {t("live no athletes")}
            <Link className="btn btn-primary mt-2" to={"/live/settings"}>{t("live settings")}</Link>
        </div>

    return <>
        <div className={styles.container}>
            <div className={styles.height100}>
                <div className={`${styles.boats}`} style={{
                    ...(tabState !== "" && {flex: 1, marginBottom: "2em"}),
                }}>
                    {boats.map((x, i) =>
                        <LiveBoat key={i}
                                  boat={x}
                                  selected={x.id === selectedBoatId}
                                  onClick={() => handleBoatClick(x.id)}/>
                    )}
                </div>
                {tabState !== "" &&
                    <Card className={styles.bottomPanel}>
                        {tabState === "charts" &&
                            <LiveCharts boat={selectedBoat}/>
                        }
                        {tabState === "map" &&
                            <LiveMap boats={activeBoats}
                                     path={getMapPath()}
                                     selectedBoat={selectedBoat}
                                     lastBoatSelectTime={lastBoatSelectTime}
                            />
                        }
                    </Card>
                }
            </div>
        </div>
        <div className={styles.bottomButtons}>
            <a role="button" className={
                `btn ${tabState === "charts" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
               onClick={() => toggleTab("charts")}>
                <Icon path={mdiChartBar} size={1}/>
                {t("charts")}
            </a>
            <a role="button" className={
                `btn ${tabState === "map" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
               onClick={() => toggleTab("map")}>
                <Icon path={mdiMap} size={1}/>
                {t("map")}
            </a>
        </div>
    </>;
};

import axios, {AxiosRequestConfig} from "axios";
import {apiConfig} from "./apiConfig";
import qs from "qs";

const axiosApi = axios.create({
    baseURL: apiConfig.apiUrl,
    paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    },
});

export default axiosApi;

// TODO refactor to delete these functions
/**
 * @deprecated
 */
export async function get(url: string, config: AxiosRequestConfig<any> = {}, queryParams: any = {}) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
        for (const key in queryParams) {
            searchParams.set(key, queryParams[key]);
        }
    }
    const queryUrl = `${url}${!queryParams ? '' : `?${searchParams.toString()}`}`;
    return await axiosApi
        .get(queryUrl, {...config})
        .then(response => response)
        .catch(e => {
            throw e;
        });
}

/**
 * @deprecated
 */
export async function post(url: string, data: any, config: AxiosRequestConfig<any> = {}) {
    return axiosApi
        .post(url, {...data}, {...config})
        .then(response => {
            return response.data;
        })
        .catch(e => {
            console.log(e);
        });
}

/**
 * @deprecated
 */
export async function put(url: string, data: any, config: AxiosRequestConfig<any> = {}) {
    return axiosApi
        .put(url, {...data}, {...config})
        .then(response => response.data)
}

/**
 * @deprecated
 */
export async function del(url: string, config: AxiosRequestConfig<any> = {}) {
    return await axiosApi
        .delete(url, {...config})
        .then(response => response.data)
}

export const ax = {
    get,
    post,
    put,
    del,
};

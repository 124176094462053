import {mdiChevronDown, mdiChevronUp} from '@mdi/js';
import Icon from '@mdi/react';
import {SizedBox} from '../SizedBox/SizedBox';
import styles from './DropdownLabel.module.scss';

interface IDropdownLabel {
    label: string | JSX.Element;
    open: boolean;
    preset?: "bootstrap";
    className?: string;
    disabled?: boolean;
}

export const DropdownLabel = ({label, open, preset, disabled, className}: IDropdownLabel) => {
    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : ""} ${preset === "bootstrap" ? styles.bootstrap: ""} ${className}`}>
            <div style={{flexShrink: 0}}>{label}</div>
            <SizedBox width={4}/>
            <div style={{flex: 1}}/>
            <Icon style={{flexShrink: 0}} path={open ? mdiChevronUp : mdiChevronDown} size={1}/>
        </div>
    );
};

import {Button, Input, Table} from "reactstrap";
import * as React from "react";
import {useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../../base/SizedBox/SizedBox";
import styles from "./Lap.module.scss"
import {useReactToPrint} from "react-to-print";
import {getTrainingFullName} from "../../../../utils/trainingName";
import {ITrainingCommon} from "../../../../typings/ITrainingCommon";
import {mdiPrinter, mdiReorderVertical} from "@mdi/js";
import Icon from "@mdi/react";
import {ILapData} from "./LapHook";

const lapDistanceMs = [25, 50, 100, 250, 500, 1000, 2000]

interface ILap {
    lapDistanceM: number;
    onLapDistanceChange: (value: number) => void;
    lap: ILapData | null;
    training: ITrainingCommon;
    showLapLines: boolean;
    onToggleLapLines: () => void;
}

export function Lap({lapDistanceM, onLapDistanceChange, lap, training,
                        showLapLines, onToggleLapLines}: ILap) {
    const {t} = useTranslation();
    const tableRef = useRef(null);
    const handlePrint = useReactToPrint({
        removeAfterPrint: true
    });

    function lapDistanceToString(lapDistanceM: number) {
        if (lapDistanceM < 1000) {
            return t("lap distance m", {m: lapDistanceM})
        }
        if (lapDistanceM >= 1000) {
            const formattedNum = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            }).format(lapDistanceM / 1000);
            return t("lap distance km", {km: formattedNum})
        }
    }

    const trainingFullName = useMemo(() => getTrainingFullName(training, t), [t, training]);

    const table = <Table bordered responsive hover
                         cssModule={{"table-responsive": `table-responsive ${styles.tableResponsive}`}}>
        <thead>
        <tr>
            <th></th>
            {lap && lap.columns.map(x => <th key={x}>{x}</th>)}
        </tr>
        </thead>
        <tbody>
        {lap && lap.values.map((row, i) => <tr key={i}>
            <th scope="row">{i + 1}</th>
            {row.map((x, j) => {
                const value = lap.units[j] ? `${x} ${lap.units[j]}` : x
                return <td key={j} className={styles.td}>
                    {value}
                </td>;
            })}
        </tr>)}
        </tbody>
    </Table>;
    return <>
        <div className={"d-flex align-items-center"}>
            {t("laps")}
            <Input
                type="select"
                value={lapDistanceM}
                onChange={(e) => onLapDistanceChange(parseInt(e.target.value))}
                className={styles.select}
            >
                {lapDistanceMs.map(x => <option key={x} value={x}>{lapDistanceToString(x)}</option>)}
            </Input>
            {lapDistanceM > 0 && <>
                <Button onClick={onToggleLapLines} outline
                        className={`${styles.printButton} toggleButton ${showLapLines ? "selected" : ""}`}>
                    <Icon path={mdiReorderVertical} size={0.8} className="me-2"/>
                    {t("show lines")}
                </Button>
                <Button onClick={() => {
                    handlePrint(null, () => tableRef.current);
                }} outline className={styles.printButton}>
                    <Icon path={mdiPrinter} size={0.8} className="me-2"/>
                    {t("print")}
                </Button>
            </>}
        </div>
        <SizedBox height={8}/>
        {table}
        <div style={{display: "none"}}>
            <div ref={tableRef} className={styles.printWrapper}>
            <style type="text/css" media="print">{"\
                @page { size: landscape; margin: 6.35mm 6.35mm 6.35mm 6.35mm !important; }\
                "}</style>
                <div
                    className={styles.printTitle}>{t("laps print title", {name: trainingFullName})}</div>
                {table}
            </div>
        </div>
    </>;
}
import * as React from "react";
import {MutableRefObject, useCallback, useMemo, useRef, useState} from "react";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";
import {useTranslation} from "react-i18next";
import Highcharts from "highcharts";
import {useDoubleTap} from "../../../hooks/useDoubleTap";
import {forceSeriesColors} from "../../paddlemate/AnalyticsPanel/StandardGraphs/StandardGraphs";
import {isTwoPaddle} from "../../../typings/TrainingSport";
import {ChartTooltipContent} from "../../paddlemate/AnalyticsPanel/ChartTooltipContent/ChartTooltipContent";
import styles from "./LactateChartDialog.module.scss";
import {ChartComp, ChartTooltip} from "../../paddlemate/AnalyticsPanel/LineChart/LineChart";
import {Button as BsButton} from "reactstrap";
import Icon from "@mdi/react";
import {mdiMinusCircleOutline} from "@mdi/js";
import {LactateSample} from "./LactateChartDialog";

export const LactateChartDialogChart = ({
                                            chartRef, allowSelect,
                                            training, distanceSeries, speedSeries,
                                            forceLeftSeries, forceRightSeries,
                                            samples, selectedSample, isTracker, athleteIndex, onRangeSelected
                                        }: {
    chartRef: MutableRefObject<any>,
    allowSelect: boolean,
    training: ITrainingCommon,
    distanceSeries: number[][] | null,
    speedSeries: number[][] | null,
    forceLeftSeries: (number[][] | null)[],
    forceRightSeries: (number[][] | null)[],
    isTracker: boolean,
    athleteIndex: number | null,
    samples: LactateSample[],
    selectedSample: LactateSample | null,
    onRangeSelected: (from: number, to: number) => void
}) => {
    const {t} = useTranslation();
    const [boundary, setBoundary] = useState<Element | null>(null);
    const [crosshairRef, setCrosshairRef] = useState<any | null>(null);
    const [selectedPoints, setSelectedPoints] = useState<any[]>([]);
    const innerChartRef = useRef<Highcharts.Chart>();

    const resetZoom = useCallback(() => {
        innerChartRef.current?.xAxis[0]?.setExtremes(undefined, undefined);
    }, []);

    const zoomOut = useCallback(() => {
        const xAxis = innerChartRef.current?.xAxis[0];
        if (!xAxis) return;
        const extremes = xAxis?.getExtremes();
        const zoomFactor = 0.7;
        if (extremes.min !== extremes.dataMin && extremes.max !== extremes.dataMax!) {
            const range = extremes.max - extremes.min;
            const newRange = range / zoomFactor;
            const rangeDiff = newRange - range;
            let min = Math.max(extremes.dataMin!, extremes.min - rangeDiff / 2);
            let max = Math.min(extremes.max + rangeDiff / 2, extremes.dataMax!);
            if (min === extremes.dataMin && max !== extremes.dataMax!)
                min = extremes.dataMin;
            if (max === extremes.dataMax! && min !== 0)
                max = extremes.dataMax!;
            xAxis.setExtremes(min, max);
        }
    }, []);

    const {onClick: dtClick} = useDoubleTap(resetZoom);

    const handleClick = useCallback((e: any) => {
        dtClick(e);
    }, [dtClick]);

    const afterDrawCrosshair = useCallback((e: any) => {
        if (e.e.chartX === undefined) return;
        //set crosshair ref to position tooltip
        const px = e.e.chartX + e.target.chart.container.getBoundingClientRect().left;
        const py = boundary?.getBoundingClientRect()?.top || 0;
        setCrosshairRef({
            getBoundingClientRect: () =>
                ({
                    x: px,
                    y: py + 20,
                    top: py + 20,
                    left: px,
                    bottom: py + 20,
                    right: px,
                    width: 0,
                    height: 0,
                })
        });
        //set selected points for tooltip content
        const chart = e.target;
        const lastPoint = chart.series[0].data[chart.series[0].data.length - 1];
        const xPoint = e.point.x;
        const dataIndex = Math.round((chart.series[0].data.length - 1) * xPoint / lastPoint.x);
        const points = chart.series.map((x: any) => {
            const point = x.data[dataIndex];
            return ({
                x: point.x,
                y: point.y,
                color: point.color,
                xError: Math.abs(point.x - xPoint)
            });
        });
        setSelectedPoints(points);
    }, [boundary]);
    const innerSetRef = useCallback((e: any) => {
        innerChartRef.current = e?.chart;
        chartRef.current = e?.chart;
        setBoundary(e?.container?.current);
    }, [chartRef]);
    const xAxisLabelFormatter = useCallback(
        (self: any) => {
            let s;
            if (self.dateTimeLabelFormat)
                s = Highcharts.dateFormat(self.dateTimeLabelFormat, self.value);
            else
                s = "0";
            if (distanceSeries) {
                s += "<br />";
                const dp = distanceSeries[Math.floor(self.value / 1000)];
                const distance = dp?.[1] || 0;
                s += Highcharts.numberFormat(distance, 1) + " km";
            }
            return `<div style="text-align: center">${s}</div>`;
        },
        [distanceSeries],
    );
    const [showCrosshair, setShowCrosshair] = useState(false);
    const {series, units} = useMemo(() => {
        const series = [];
        const units = [];
        if (speedSeries) {
            series.push({
                data: speedSeries,
                color: "#000000"
            });
            units.push("km/h");
        }
        const forceSeries = forceLeftSeries
            .map((_, i) => [
                {
                    data: forceLeftSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(training.sport) ? 2 : 1)) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                },
                {
                    data: forceRightSeries[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(training.sport) ? 2 : 1) + 1) % forceSeriesColors.length],
                    dataGrouping: {
                        approximation: "max"
                    }
                }
            ])
            .filter((_, i) => athleteIndex === null || i === athleteIndex).flat()
            .filter(x => x.data);
        if (forceSeries) {
            series.push(...forceSeries);
            units.push(...forceSeries.map(x => "N"));
        }
        return {series, units};
    }, [athleteIndex, forceLeftSeries, forceRightSeries, speedSeries, training.sport]);

    const tooltipFormatter = useCallback((x: number, points: any, unit: string | string[]) => {
        const distance = distanceSeries?.[Math.floor(x / (isTracker ? 100 : 1000))]?.[1];
        return <ChartTooltipContent
            x={x}
            distance={distance?.toFixed(1)}
            points={points}
            units={unit}
        />;
    }, [distanceSeries, isTracker]);
    const title = useMemo(() => {
        const title: string[] = [];
        if (speedSeries)
            title.push(t("avg speed"));
        if (units.includes("N"))
            title.push(t("avg pulling force"));
        return title;
    }, [speedSeries, t, units]);
    const options = useMemo(() => (
        {
            chart: {
                marginLeft: 40, height: 240,
                ...(!allowSelect && {
                    zooming: {type: false},
                    panning: {enabled: false}
                })
            },
            title: {text: title.join(", "), style: {fontWeight: 400, fontSize: "14px"}},
            yAxis: {
                min: 0
            }
        }), [allowSelect, title]);

    const [showResetZoom, setShowResetZoom] = useState(false);

    const handleExtremes = useCallback((e: any) => {
        if (e.min != null && e.max != null) {
            setShowResetZoom(true);
            onRangeSelected(Math.round(e.min), Math.round(e.max));
        } else
            setShowResetZoom(false);
    }, [onRangeSelected]);

    const plotBands = useMemo(() =>
        samples
            .filter(x => x.from != null)
            .map(x => ({
                from: x.from,
                to: x.to,
                color: selectedSample === x ? "rgb(184,216,230)" : "rgba(140,140,140,0.07)",
            })), [samples, selectedSample]);

    return <div className={styles.lineChart}
                onClick={handleClick}
                onMouseMove={() => setShowCrosshair(true)}
                onMouseLeave={() => setShowCrosshair(false)}>
        <ChartComp innerSetRef={innerSetRef}
                   showXLabels={true}
                   plotBands={plotBands}
                   setExtremes={handleExtremes}
                   xAxisLabelFormatter={xAxisLabelFormatter}
                   afterDrawCrosshair={afterDrawCrosshair}
                   series={series}
                   options={options}/>
        <div className={styles.chartButtonContainer}>
            {showResetZoom && <BsButton size="sm" outline={true}
                                        style={{marginRight: 10}}
                                        onClick={resetZoom}>
                {t("reset")}
            </BsButton>}
            {showResetZoom && <BsButton size="sm" outline={true}
                                        onClick={zoomOut}>
                <Icon path={mdiMinusCircleOutline}
                      size={0.8}/>
            </BsButton>}
        </div>
        {showCrosshair && <ChartTooltip reference={crosshairRef}
                                        boundary={boundary}
                                        points={selectedPoints}
                                        tooltipFormatter={tooltipFormatter}
                                        units={units}/>}
    </div>
};
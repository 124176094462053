import * as React from "react";
import styles from "./InfoTooltipButton.module.scss";
import Icon from "@mdi/react";
import {mdiInformationOutline} from "@mdi/js";
import {forwardRef} from "react";

interface IInfoTooltipButton {
    className: string
}

export const InfoTooltipButton = forwardRef<
    HTMLDivElement,
    any
>(({className}: IInfoTooltipButton, propRef) => {
    return <div ref={propRef} className={`${styles.button} ${className}`}>
        <Icon path={mdiInformationOutline} size={0.75}/>
    </div>
});
import * as React from "react";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import styles from "./ConnectServicePage.module.css";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Api} from "../../utils/api";
import {externalServices} from "../../components/paddlemate/ExternalConnections/ExternalConnections";

export const ConnectServicePage = () => {
    const {t} = useTranslation();
    const {service} = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const handled = useRef(false);

    useEffect(() => {
        if (!service || !externalServices.map(x => x.id).includes(service)) {
            navigate("/profile");
            return;
        }
        if (handled.current) return

        if (service === "strava") {
            handled.current = true
            const storedState = localStorage.getItem("strava_oauth2_state");
            localStorage.removeItem("strava_oauth2_state");
            const state = searchParams.get("state");
            const error = searchParams.get("error");
            if (storedState !== state || error) {
                navigate(`/profile?failure-ec=${service}`);
                return;
            }
            Api.connectStrava(searchParams.get("code")!, searchParams.get("scope")!)
                .then(() => {
                    navigate(`/profile?success-ec=${service}`);
                }).catch(() => {
                navigate(`/profile?failure-ec=${service}`);
            })
        } else if (service === "garmin") {
            handled.current = true
            const storedState = localStorage.getItem("garmin_oauth_state");
            localStorage.removeItem("garmin_oauth_state");
            const state = searchParams.get("state");
            const oauthToken = searchParams.get("oauth_token");
            const oauthVerifier = searchParams.get("oauth_verifier");
            if (storedState !== state || !oauthToken || !oauthVerifier || oauthVerifier === "null") {
                navigate(`/profile?failure-ec=${service}`);
                return;
            }
            Api.connectGarmin(oauthToken, oauthVerifier)
                .then(() => {
                    navigate(`/profile?success-ec=${service}`);
                }).catch(() => {
                navigate(`/profile?failure-ec=${service}`);
            })
        }
    }, [service, navigate, searchParams, t]);

    return (
        <DashboardLayout>
            <SizedBox height={8}/>
            <div className={styles.topRow}>
                <div className={styles.title}>{t("profile")}</div>
            </div>
            <div className={styles.container}>

            </div>
        </DashboardLayout>
    );
};

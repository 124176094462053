import {ReactNode, RefCallback, RefObject, useCallback, useEffect, useRef} from "react";
import styles from "./Dropdown.module.css";
import {autoUpdate, flip, shift, useFloating} from "@floating-ui/react";

interface IDropdown {
    anchor: RefObject<HTMLDivElement>;
    children: ReactNode;
    setOpen: (open: boolean) => void;
    className?: string;
}

export const Dropdown = ({anchor, children, setOpen, className}: IDropdown) => {
    const {refs, floatingStyles, isPositioned} = useFloating({
        placement: "bottom",
        open: true,
        onOpenChange: x => {
            setOpen(x);
        },
        whileElementsMounted: autoUpdate,
        middleware: [shift({padding: 30}), flip({})],
    });

    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleRef = useCallback<RefCallback<HTMLDivElement>>((ref) => {
        containerRef.current = ref;
        refs.setFloating(ref);
    }, [refs]);

    useEffect(() => {
        if (isPositioned && containerRef.current && document.activeElement !== containerRef.current) {
            containerRef.current.tabIndex = -1;
            containerRef.current.focus()
        }
    }, [isPositioned]);

    useEffect(() => {
        refs.setReference(anchor.current);
    }, [anchor, refs]);

    return <div
        ref={handleRef}
        className={`${styles.container} ${className ? className : ""}`}
        onBlur={() => setOpen(false)}
        style={{
            ...floatingStyles,
            zIndex: 10,
            opacity: isPositioned ? 1 : 0
        }}
    >
        {children}
    </div>;
};

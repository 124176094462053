import {useTranslation} from 'react-i18next';
import {StatCategory} from '../../../typings/StatCategory';
import {Formatter} from '../../../utils/formatter';
import {GlanceIndicator} from '../GlanceIndicator/GlanceIndicator';
import styles from './GlanceStats.module.css';
import {StatisticsData} from "../../../typings/StatisticsData";

interface IGlanceStats {
    selected: StatCategory;
    setSelected: (cat: StatCategory) => void;
    stats: StatisticsData | null;
}

const getStatIcon = (cat: StatCategory) => {
    switch (cat) {
        case StatCategory.Sessions:
            return '/icons/ic_date.png';
        case StatCategory.Duration:
            return '/icons/ic_time.png';
        case StatCategory.Distance:
            return '/icons/ic_distance.png';
        case StatCategory.Calories:
            return '/icons/ic_calories.png';
        case StatCategory.AverageForce:
            return '/icons/ic_paddlingforce.png';
        case StatCategory.AverageStrokeRate:
            return '/icons/ic_strokes.png';
    }
}

export const GlanceStats = ({ selected, setSelected, stats }: IGlanceStats) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <GlanceIndicator
                icon={getStatIcon(StatCategory.Sessions)}
                selected={selected === StatCategory.Sessions}
                onClick={() => setSelected(StatCategory.Sessions)}
                text={t('sessions')}
                value={stats?.trainingSessions.toString() ?? ''}
            />
            <GlanceIndicator
                icon={getStatIcon(StatCategory.Duration)}
                selected={selected === StatCategory.Duration}
                onClick={() => setSelected(StatCategory.Duration)}
                text={t('duration')}
                value={stats?.durationS === undefined ? '' : Formatter.secToTimeString(stats.durationS)}
                smallValue={stats?.durationS === undefined ? '' : `${t('session avg')}: ${Formatter.secToTimeString(stats.durationS / (stats.trainingSessions || 1))}`}
            />
            <GlanceIndicator
                icon={getStatIcon(StatCategory.Distance)}
                selected={selected === StatCategory.Distance}
                onClick={() => setSelected(StatCategory.Distance)}
                text={t('distance')}
                value={`${stats?.lengthKm === undefined ? '' : stats.lengthKm.toFixed(1)} km`}
                smallValue={stats?.lengthKm === undefined ? '' : `${t('session avg')}: ${(stats.lengthKm / (stats.trainingSessions || 1)).toFixed(1)} km`}
            />
            <GlanceIndicator
                icon={getStatIcon(StatCategory.Calories)}
                selected={selected === StatCategory.Calories}
                onClick={() => setSelected(StatCategory.Calories)}
                text={t('calories')}
                value={`${stats?.caloriesKcal === undefined ? '' : stats.caloriesKcal.toFixed(1)} kcal`}
                smallValue={stats?.caloriesKcal === undefined ? '' : `${t('session avg')}: ${(stats?.caloriesKcal / (stats?.trainingSessions || 1)).toFixed(1)} kcal`}
            />
            <GlanceIndicator
                icon={getStatIcon(StatCategory.AverageForce)}
                selected={selected === StatCategory.AverageForce}
                onClick={() => setSelected(StatCategory.AverageForce)}
                text={t('average force')}
                value={`${stats?.avgPaddlingForceN === undefined ? '' : stats.avgPaddlingForceN.toFixed(0).replace('-0', '0')} N`}
            />
            <GlanceIndicator
                icon={getStatIcon(StatCategory.AverageStrokeRate)}
                selected={selected === StatCategory.AverageStrokeRate}
                onClick={() => setSelected(StatCategory.AverageStrokeRate)}
                text={t('average stroke rate')}
                value={`${stats?.avgStrokeRateSpm === undefined ? '' : stats.avgStrokeRateSpm.toFixed(1)} / min`}
            />
        </div>
    );
};

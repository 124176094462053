import moment from "moment";
import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../components/base/Button/Button";
import {DateSelector} from "../../components/base/DateTimeSelector/DateSelector";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {Spacer} from "../../components/base/Spacer/Spacer";
import {YesCancelDialog} from "../../components/dialogs/YesCancelDialog/YesCancelDialog";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import DialogContext from "../../contexts/DialogContext";
import UserContext from "../../contexts/UserContext";
import {GenderType} from "../../typings/GenderType";
import {SportType} from "../../typings/SportType";
import {Api} from "../../utils/api";
import styles from "./ProfilePage.module.css";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useParams} from "react-router-dom";
import {IUser} from "../../typings/IUser";
import {Spinner} from "../../components/base/Spinner/Spinner";
import {HeartRateZones} from "../../components/paddlemate/HeartRateZones/HeartRateZones";
import {ExternalConnections} from "../../components/paddlemate/ExternalConnections/ExternalConnections";

const minYear = moment().year() - 100;
const maxYear = moment().year() - 10;

const weights = Array.from({length: 144}, (_, i) => i + 41);
const heights = Array.from({length: 240}, (_, i) => i + 61);

export const ProfilePage = () => {
    const {t} = useTranslation();
    const {
        user: selfUser,
        logout,
        reloadUser,
        incomingCoachingRequests: coachingRequests,
        acceptCoachingRequest,
        deleteCoachingRequest
    } = useContext(UserContext);
    const {userId: userIdParam} = useParams();
    const readonly = useMemo(() => userIdParam != null && selfUser?.id?.toString() !== userIdParam, [selfUser, userIdParam]);
    const {showError, openDialog} = useContext(DialogContext);

    const [user, setUser] = useState<IUser>();
    useEffect(() => {
        if (userIdParam) {
            const load = async () => {
                setUser(await Api.getUser(userIdParam));
            };
            load().catch(console.log);
        } else if (selfUser != null)
            setUser(selfUser);
    }, [userIdParam, selfUser]);

    const [gender, setGenderRaw] = useState<GenderType>();
    const [birthDate, setBirthDateRaw] = useState<Date>();
    const [height, setHeightRaw] = useState<number>();
    const [weight, setWeightRaw] = useState<number>();
    const [sport, setSportRaw] = useState<SportType>();
    const [club, setClubRaw] = useState<string>();
    useEffect(() => {
        if (!user) return;
        setGenderRaw(user.gender || undefined);
        setBirthDateRaw(new Date(user.birthDate));
        setHeightRaw(user.heightCm || undefined);
        setWeightRaw(user.weightKg || undefined);
        setSportRaw(user.sport || undefined);
        setClubRaw(user.club || undefined);
    }, [user]);

    const [changedProfilePic, setChangedProfilePic] = useState<File | null>(null);
    const profilePictureRef = useRef<HTMLImageElement>(null);

    const setGender = useCallback((d: GenderType) => {
        setGenderRaw(d);
        setChanged(true);
    }, []);
    const setBirthDate = useCallback((d: Date) => {
        setBirthDateRaw(d);
        setChanged(true);
    }, []);
    const setHeight = useCallback((d: number) => {
        setHeightRaw(d);
        setChanged(true);
    }, []);
    const setWeight = useCallback((d: number) => {
        setWeightRaw(d);
        setChanged(true);
    }, []);
    const setSport = useCallback((d: SportType) => {
        setSportRaw(d);
        setChanged(true);
    }, []);
    const setClub = useCallback((d: string) => {
        setClubRaw(d);
        setChanged(true);
    }, []);

    const [changed, setChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const setFile = (e: any) => {
        if (e.target.files.length !== 1) showError(t("single image upload limit"));
        setChangedProfilePic(e.target.files[0]);
        setChanged(true);
        if (profilePictureRef.current) {
            profilePictureRef.current.src = URL.createObjectURL(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (!readonly)
            reloadUser().catch(console.log);
    }, [readonly]); // do not add reloadUser, as it depends on user

    const onSave = async () => {
        let success = false;
        try {
            setSaving(true);
            await Api.updateUserRequest(user!.id, {
                birthdate: birthDate,
                sport: sport,
                club: club,
                gender: gender,
                heightCm: height,
                weightKg: weight,
            });
            if (changedProfilePic !== null) {
                try {
                    await Api.uploadProfilePicture(user!.id, changedProfilePic);
                } catch (e) {
                    showError(t("image upload failed"));
                    console.log(e);
                }
            }
            setChanged(false);
            await reloadUser();
            success = true;
        } catch (err) {
            success = false;
        } finally {
            setSaving(false);
        }
        if (!success) showError(t("an error happened please try later"));
    };

    const deleteAccount = async () => {
        const success = await Api.accountDelete(user!.id);
        if (!success) {
            showError(t("an error happened please try later"));
            return;
        }
        logout();
    };

    const confirmOnceMore = () => {
        openDialog(
            <YesCancelDialog
                title={t("please confirm")}
                subtitle={t("do you want to delete your account")}
                onYes={deleteAccount}
            />
        );
    };

    const onDeleteAccountWrapper = async () => {
        openDialog(
            <YesCancelDialog
                title={t("are you sure")}
                subtitle={t("account deletion is not reversible")}
                onYes={confirmOnceMore}
            />
        );
    };

    if (!user)
        return <DashboardLayout>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16, padding: 20}}>
                <Spinner/>
            </div>
        </DashboardLayout>;

    return (
        <DashboardLayout>
            <SizedBox height={8}/>
            <div className={styles.topRow}>
                <div className={styles.title}>{t("profile")}</div>
                {!readonly &&
                    <div className={styles.deleteAccount} onClick={onDeleteAccountWrapper}>{t("delete account")}</div>}
            </div>
            <div className={styles.container}>
                <div style={{alignSelf: "center"}}>
                    <label htmlFor="profile-pic-input">
                        <img ref={profilePictureRef} src={user!.profilePictureUrl} className={styles.profilePic}
                             style={{...(!readonly && {cursor: "pointer"})}}/>
                    </label>
                    <input
                        className={styles.hidden}
                        id="profile-pic-input"
                        type="file"
                        onChange={setFile}
                        disabled={readonly}
                        accept=".jpg,.jpeg,.png,image/jpeg,image/png"
                    />
                </div>
                <div className={styles.name} style={{alignSelf: "center"}}>{user!.name}</div>
                <div style={{alignSelf: "center"}}>{user!.email}</div>
                <SizedBox height={48}/>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{t("gender")}</Label>
                            <Input
                                type="select"
                                value={gender || ""}
                                onChange={(e) => setGender(parseInt(e.target.value))}
                                disabled={readonly}
                            >
                                <option value={""} disabled={true}>{"---"}</option>
                                <option value={GenderType.Woman}>{t("female")}</option>
                                <option value={GenderType.Man}>{t("male")}</option>
                                <option value={GenderType.Other}>{t("other")}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{t("birth date")}</Label>
                            {birthDate && <DateSelector
                                date={birthDate}
                                minYear={minYear}
                                maxYear={maxYear}
                                setDate={setBirthDate}
                                highlighted={true}
                                preset={"bootstrap"}
                                disabled={readonly}
                            />}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{t("sport")}</Label>
                            <Input
                                type="select"
                                value={sport || ""}
                                onChange={(e) => setSport(parseInt(e.target.value))}
                                disabled={readonly}
                            >
                                <option value={""} disabled={true}>{"---"}</option>
                                <option value={SportType.Kayaking}>{t("kayak")}</option>
                                <option value={SportType.Canoeing}>{t("canoe")}</option>
                                <option value={SportType.Suping}>{t("sup")}</option>
                                <option value={SportType.Rowing}>{t("rowing")}</option>
                                <option value={SportType.Sculling}>{t("sculling")}</option>
                                <option value={SportType.DragonBoating}>{t("dragon boat")}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <FormGroup>
                            <Label>{t("height")}</Label>
                            <Input
                                type="select"
                                value={height || ""}
                                onChange={(e) => setHeight(parseInt(e.target.value))}
                                disabled={readonly}
                            >
                                <option value={""} disabled={true}>{"---"}</option>
                                {heights.map(h => <option key={h} value={h}>{`${h} cm`}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <Label>{t("weight")}</Label>
                            <Input
                                type="select"
                                value={weight || ""}
                                onChange={(e) => {
                                    setWeight(parseInt(e.target.value));
                                }}
                                disabled={readonly}
                            >
                                <option value={""} disabled={true}>{"---"}</option>
                                {weights.map(h => <option key={h} value={h}>{`${h} kg`}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>{t("club")}</Label>
                            <Input
                                value={club || ""}
                                onChange={(e) => {
                                    setClub(e.target.value);
                                }}
                                disabled={readonly}/>
                        </FormGroup>
                    </Col>
                </Row>
                <SizedBox height={16}/>
                {saving && <Spinner cssOverride={{alignSelf: "center"}}/>}
                {!readonly && !saving &&
                    <Button style={{alignSelf: "center"}}
                            label={t("save")}
                            disabled={!changed}
                            onClick={onSave}
                    />
                }
                <SizedBox height={32}/>
                <HeartRateZones user={user} readonly={readonly}/>
                {!readonly && <>
                    <SizedBox height={32}/>
                    {coachingRequests !== null && coachingRequests.length !== 0 &&
                        <>
                            <div className={styles.coachingHeadline}>
                                <div style={{fontWeight: 700}}>{t("coaching requests")}</div>
                                <div className={styles.requestCounter}>{coachingRequests.length}</div>
                            </div>
                            <div className={styles.coachingSubtitle}>
                                {t("coaching requests subtitle")}
                            </div>
                        </>
                    }
                    {coachingRequests !== null &&
                        <div className={styles.listBox}>
                            {coachingRequests.map(req => (
                                <div className={styles.coach}>
                                    <div className={styles.coachName}>{req.coachEmail}</div>
                                    <Spacer flex={1}/>
                                    <Button
                                        label={t("reject")}
                                        secondary={true}
                                        onClick={() => deleteCoachingRequest(req)}
                                    />
                                    <SizedBox width={8}/>
                                    <Button
                                        label={t("accept")}
                                        onClick={() => acceptCoachingRequest(req)}
                                    />
                                </div>
                            ))}
                        </div>
                    }
                    <SizedBox height={32}/>
                    <ExternalConnections />
                </>}
                <SizedBox height={32}/>
            </div>
        </DashboardLayout>
    );
};

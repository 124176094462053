import {useCallback, useContext} from "react";
import {useTranslation} from "react-i18next";
import styles from "./NotFoundPage.module.css";
import UserContext from "../../contexts/UserContext";
import {LoginState} from "../../typings/LoginState";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {Button} from "../../components/base/Button/Button";
import {useNavigate} from "react-router-dom";

const NotFoundPage = () => {
    const {t} = useTranslation();
    const userContext = useContext(UserContext);
    const navigate = useNavigate()
    const onLogin = useCallback(() => {
        navigate("/login")
    }, [navigate]);

    if (userContext.loginState === LoginState.loggedin) {
        return <DashboardLayout>
            <div className={styles.container}>
                <SizedBox height={16}/>
                {t("page not found")}
            </div>
        </DashboardLayout>;
    }

    return (
        <div className={styles.container}>
            <SizedBox height={16}/>
            {t("page not found")}
            <SizedBox height={16}/>
            <Button
                label={t("go to login")}
                onClick={onLogin}
            />
        </div>
    );
};

export default NotFoundPage;

import { ReactNode } from 'react';
import styles from './Labelled.module.css';

interface ILabelled {
    label?: string | null;
    children: ReactNode;
}

export const Labelled = ({ label, children }: ILabelled) => {
    return (
        <div className={styles.container}>
            {label && <div className={styles.label}>{label}</div>}
            {children}
        </div>
    );
};

import {IAthlete} from "../typings/IAthlete";
import {ICoach} from "../typings/ICoach";
import {ICoachingRequest} from "../typings/ICoachingRequest";
import {IToken} from "../typings/IToken";
import {IUser} from "../typings/IUser";
import {TimeGrouping} from "../typings/TimeGrouping";
import {apiConfig} from "./apiConfig";
import {ApiEC} from "./ApiEC";
import axiosApi, {get} from "./axios";
import {Transform} from "./transfrom";
import {Moment} from "moment";
import {IApiTrainingCommonBase} from "../typings/IApiTrainingCommonBase";
import {IConnectedServices} from "../typings/IConnectedServices";
import {IGarminRequestTokenResponse} from "../typings/IGarminRequestTokenResponse";
import {ILiveAthlete} from "../typings/ILiveAthlete";
import {IUserSeries, IUserSeriesData} from "../typings/IUserSeries";
import {ITrainingCommonFile} from "../typings/ITrainingCommonFile";

export const Api = {
    loginPassword: async (email: string, password: string) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "password");
        formData.append("client_id", apiConfig.apiClientId);
        formData.append("client_secret", apiConfig.apiClientSecret);
        formData.append("username", email);
        formData.append("password", password);
        const res = await axiosApi.post<IToken>("/connect/token", formData);
        if (!res.data || !res.data.access_token) {
            throw new Error(ApiEC.LoginFailed);
        }
        return res.data;
    },
    refresh: async (refreshToken: string) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "refresh_token");
        formData.append("client_id", apiConfig.apiClientId);
        formData.append("client_secret", apiConfig.apiClientSecret);
        formData.append("refresh_token", refreshToken);
        try {
            const res = await axiosApi.post<IToken>("/connect/token", formData);
            if (!res.data || !res.data.access_token) {
                throw new Error(ApiEC.LoginFailed);
            }
            return res.data as IToken;
        } catch (e: any) {
            if (e.response.status === 400 || e.response.status === 401) {
                throw new Error(ApiEC.Logout);
            } else throw e;
        }
    },
    resetPassword: async (password: string, encodedHash: string) => {
        const res = await axiosApi.post("/api/Users/resetPassword", {
            password,
            encodedHash,
        });
        return res.status;
    },
    resetPasswordRequest: async (email: string) => {
        const res = await axiosApi.post("/api/Users/requestPasswordReset", {
            email
        });
        return res.status;
    },
    getUser: async (userId: string) => {
        const res = await axiosApi.get(`/api/Users/${userId}`);
        if (!res.data || !res.data.id) {
            throw new Error(ApiEC.UserGetFailed);
        }
        return res.data as IUser;
    },
    fetchAthletes: async (coachId: number) => {
        const res = await axiosApi.get(`/api/coaches/${coachId}/athletes`);
        if (!res.data || (res.data.length === undefined)) {
            throw new Error('fetchathletes-failed');
        }
        return res.data as IAthlete[];
    },
    deleteAthlete: async (coachId: number, athleteId: number) => {
        const res = await axiosApi.delete(`/api/coaches/${coachId}/athletes/${athleteId}`);
        return res.status === 200;
    },
    getStatistics: async (timeGrouping: TimeGrouping, from: Date, to: Date, userId?: number, coachId?: number) => {
        const res = await get(
            !!userId ? `/api/statistics/${userId}` : '/api/statistics',
            {},
            {
                groupBy: timeGrouping,
                from: from.toISOString(),
                to: to.toISOString(),
                ...(!!coachId && ({coachId}))
            }
        );
        if (!res.data) {
            throw new Error('getstatistics-failed');
        }
        const d = res.data;
        return Transform.statisticsResponse(d, timeGrouping);
    },
    getTrainings: async (userId: number | null = null, coachId: number | null = null, limit = 100, offset: number = 0,
                         from: Moment | null = null, to: Moment | null = null) => {
        const res = await axiosApi.get(`/api/training-commons`, {
            params: {
                ...(userId ? {userId} : {coachId}),
                limit: limit + 1,
                offset: offset,
                from: from?.toISOString(),
                to: to?.toISOString(),
            }
        });
        if (!res.data || (res.data.length === undefined)) {
            throw new Error('training-commons-failed');
        }
        const trainingCommons = (res.data as any[]).map((t) => ({
            ...t,
            startAt: new Date(t.startAt),
            endAt: new Date(t.endAt),
        } as IApiTrainingCommonBase));
        const hasMore = trainingCommons.length > limit;
        if (hasMore) trainingCommons.splice(trainingCommons.length - 1, 1);
        return {
            trainingCommons: trainingCommons,
            hasMore,
        };
    },
    getTrainingCommon: async (trainingCommonId: number) => {
        const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}`);
        if (!resDetail.data) {
            throw new Error('training-common-failed');
        }
        const training = Transform.trainingCommonResponse(resDetail.data);
        return training;
    },
    getTrainingCommonFile: async (trainingCommonId: number) => {
        const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}/file`);
        if (!resDetail.data) {
            throw new Error('training-common-file-failed');
        }
        const training = Transform.trainingCommonFileResponse(resDetail.data);
        return training;
    },
    exportTrainingCommon: async (trainingCommonId: number, format: string,
                                 filenameDate: string, slice?: number[], trainingIndexes?: number[]) => {
        const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}/export`, {
            responseType: 'arraybuffer',
            params: {
                format,
                slice,
                trainingIndexes,
                filenameDate
            }
        });
        if (!resDetail.data) {
            throw new Error('training-common-export-failed');
        }
        return resDetail.data;
    },
    updateTrainingCommon: async (trainingCommonId: number, data: {
        notes?: string | undefined,
        userSeries?: IUserSeriesData[],
        reportedProblem?: string
    }) => {
        const res = await axiosApi.put(`/api/training-commons/${trainingCommonId}`, data);
        return res.status === 200;
    },
    getMyCoaches: async (myUserId: number) => {
        const res = await axiosApi.get(`/api/athletes/${myUserId}/coaches`);
        if (!res.data) {
            throw new Error('getting current users coaches failed');
        }
        return res.data as ICoach[];
    },
    getCoachingRequests: async (myUserId: number) => {
        const res = await axiosApi.get(`/api/CoachingRequests`);
        if (!res.data) {
            throw new Error('getting current users coaches failed');
        }
        return res.data as ICoachingRequest[];
    },
    sendCoachingRequest: async (email: string) => {
        const res = await axiosApi.post(`/api/CoachingRequests`, {email});
        return res.status === 200;
    },
    acceptCoachingRequest: async (requestId: number) => {
        const res = await axiosApi.put(`/api/CoachingRequests/${requestId}`, {});
        return res.status === 200;
    },
    deleteCoachingRequest: async (requestId: number) => {
        const res = await axiosApi.delete(`/api/CoachingRequests/${requestId}`);
        return res.status === 200;
    }
    ,
    deleteCoach: async (athleteId: number, coachId: number) => {
        const res = await axiosApi.delete(`/api/athletes/${athleteId}/coaches/${coachId}`);
        return res.status === 200;
    },
    uploadProfilePicture: async (userId: number, f: File) => {
        const formData = new FormData();
        formData.append("file", f);
        const res = await axiosApi.post(`/api/Users/${userId}/profilePicture`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Content-Disposition": `form-data; name="${f.name}"`,
            },
        });
        return res.status === 200;
    },
    updateUserRequest: async (userId: number, updateData: any) => {
        const res = await axiosApi.put(`/api/Users/${userId}`, updateData);
        return res.status === 200;
    },
    accountDelete: async (userId: number) => {
        const res = await axiosApi.delete(`/api/Users/${userId}`);
        return res.status === 200;
    },
    getConnectedServices: async (): Promise<IConnectedServices> => {
        const res = await axiosApi.get<IConnectedServices>(`/api/ConnectedServices/`);
        return res.data;
    },
    connectStrava: async (code: string, scope: string) => {
        await axiosApi.post(`/api/ConnectedServices/strava`, {
            code,
            scope
        });
    },
    disconnectStrava: async () => {
        await axiosApi.delete(`/api/ConnectedServices/strava`);
    },
    requestTokenGarmin: async (): Promise<IGarminRequestTokenResponse> => {
        const res = await axiosApi.post<IGarminRequestTokenResponse>(`/api/ConnectedServices/garmin/request`);
        return res.data;
    },
    connectGarmin: async (oauthToken: string, oauthVerifier: string) => {
        await axiosApi.post(`/api/ConnectedServices/garmin`, {
            oauthToken,
            oauthVerifier
        });
    },
    disconnectGarmin: async () => {
        await axiosApi.delete(`/api/ConnectedServices/garmin`);
    },
    addLiveAthlete: async (athleteId: number): Promise<ILiveAthlete> => {
        const res = await axiosApi.post(`/api/LiveAthletes/${athleteId}`);
        return res.data;
    },
    getLiveAthletes: async (on?: boolean): Promise<ILiveAthlete[]> => {
        const res = await axiosApi.get<ILiveAthlete[]>(`/api/LiveAthletes`, {
            params: {
                ...(on !== undefined && {on})
            }
        });
        return res.data;
    },
    updateLiveAthlete: async (athleteId: number, color: string, on: boolean): Promise<ILiveAthlete> => {
        const res = await axiosApi.put<ILiveAthlete>(`/api/LiveAthletes/${athleteId}`, {
            color,
            on
        });
        return res.data;
    }
}

import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import styles from "./HeartRateZones.module.scss";
import {HrZonesType} from "../../../typings/HrZonesType";
import moment from "moment/moment";
import {IUser} from "../../../typings/IUser";
import {Button} from "../../base/Button/Button";
import {Api} from "../../../utils/api";
import DialogContext from "../../../contexts/DialogContext";
import {SizedBox} from "../../base/SizedBox/SizedBox";
import {Spinner} from "../../base/Spinner/Spinner";
import IsEqual from "lodash/isEqual";

export interface IHeartRateZones {
    user: IUser;
    readonly: boolean;
}

const hrZonesMinPercentAutoMax = 50;

export const HeartRateZones = ({user, readonly}: IHeartRateZones) => {
    const {t} = useTranslation();
    const {showError} = useContext(DialogContext);

    const [hrZoneSettingsSnapshot, setHrZoneSettingsSnapshot] = useState(user.hrZoneSettings);
    const [saving, setSaving] = useState<boolean>(false);

    const [type, setType] = useState(user.hrZoneSettings.type);
    const [zoneCount, setZoneCount] = useState(user.hrZoneSettings.zones.length);
    const [zones, setZones] = useState(user.hrZoneSettings.zones);
    const autoMaxHr = useMemo(() => {
        const age = moment().diff(moment(user.birthDate), "year", false);
        return 220 - age;
    }, [user]);

    useEffect(() => {
        setType(user.hrZoneSettings.type)
        setZoneCount(user.hrZoneSettings.zones.length)
        setZones(user.hrZoneSettings.zones)
    }, [user]);

    const resetZones = useCallback((maxBpm?: number) => {
        setZones(zones => [...Array(zoneCount).keys()].map((zone, i) => {
            let bpm;
            let percent = Math.floor(hrZonesMinPercentAutoMax + i / (zoneCount - 1) * (100 - hrZonesMinPercentAutoMax));
            if (type === HrZonesType.Auto)
                bpm = Math.floor(autoMaxHr * percent / 100);
            else if (type === HrZonesType.Max) {
                const oldMax = maxBpm ?? zones[zones.length - 1].bpm;
                bpm = Math.floor(oldMax * percent / 100);
            } else {
                bpm = zoneCount === zones.length ? zones[i].bpm : Math.floor(autoMaxHr * percent / 100);
                percent = zoneCount === zones.length ? zones[i].percent : percent;
            }
            return ({
                bpm,
                percent
            });
        }));
    }, [autoMaxHr, type, zoneCount]);

    const invalidZones = useMemo(() => {
        const res = zones.map((x, i) => ({
            bpm: Number.isNaN(zones[i].bpm) || (i > 0 && zones[i - 1].bpm >= zones[i].bpm) || zones[i].bpm < 50 || zones[i].bpm > 220,
            percent: Number.isNaN(zones[i].percent) || (i > 0 && zones[i - 1].percent >= zones[i].percent) || zones[i].percent > 100,
        }));
        if (type === HrZonesType.Max) {
            const anyInvalidBeforeMax = res.slice(0, -1).some(x => x.bpm || x.percent);
            if (anyInvalidBeforeMax)
                res[res.length - 1].bpm = true;
        }
        return res;
    }, [zones, type]);

    useEffect(() => {
        resetZones();
    }, [type, zoneCount, resetZones]);

    const setZoneBpm = useCallback((i: number, x: number) => {
        if (type === HrZonesType.Manual) {
            if (i === zones.length - 1) // we are changing the last
            {
                // change all based on max hr
                setZones(old => old.map((zone, j) => {
                    return ({
                        bpm: i === j ? x : zone.bpm,
                        percent: i === j ? 100 : Math.floor(zone.bpm / x * 100)
                    });
                }));
            } else
                setZones(old => {
                    const oldMax = old[old.length - 1].bpm;
                    old[i] = {
                        bpm: x,
                        percent: Math.floor(x / oldMax * 100)
                    };
                    return [...old];
                });
        } else
            resetZones(x);
    }, [resetZones, type, zones.length]);

    const setZonePercent = useCallback((i: number, x: number) => {
        setZones(old => {
            const oldMax = old[old.length - 1].bpm;
            old[i] = {
                bpm: Math.floor(oldMax * x / 100),
                percent: x
            };
            return [...old];
        });
    }, []);

    const changed = useMemo(() =>
        !IsEqual(hrZoneSettingsSnapshot, {
            type: type,
            zones: zones
        }), [hrZoneSettingsSnapshot, type, zones]);

    const onSave = async () => {
        let success = false;
        try {
            setSaving(true);
            const hrZoneSettings = {
                type: type,
                zones: zones
            };
            await Api.updateUserRequest(user!.id, {
                hrZoneSettings: hrZoneSettings,
            });
            setHrZoneSettingsSnapshot(hrZoneSettings);
            success = true;
        } catch (err) {
            success = false;
        } finally {
            setSaving(false);
        }
        if (!success) showError(t("an error happened please try later"));
    };

    return <>
        <Label style={{fontWeight: 700}}>{t("heartrate zones")}</Label>
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={8}>
                        <FormGroup>
                            <Label>{t("hr zone type")}</Label>
                            <Input
                                type="select"
                                value={type.toString()}
                                onChange={(e) => setType(parseInt(e.target.value))}
                                disabled={readonly}
                            >
                                <option value={HrZonesType.Auto}>{t("hr zone auto")}</option>
                                <option value={HrZonesType.Max}>{t("hr zone max")}</option>
                                <option value={HrZonesType.Manual}>{t("hr zone manual")}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    {/*We only allow 5 zones now*/}
                    {/*<Col md={4}>*/}
                    {/*    <FormGroup>*/}
                    {/*        <Label>{t("hr zone count")}</Label>*/}
                    {/*        <Input*/}
                    {/*            type="select"*/}
                    {/*            value={zones.length}*/}
                    {/*            onChange={(e) => setZoneCount(parseInt(e.target.value))}*/}
                    {/*            disabled={readonly}*/}
                    {/*        >*/}
                    {/*            <option value={6}>{t("hr zone count format", {count: 5})}</option>*/}
                    {/*            <option value={8}>{t("hr zone count format", {count: 7})}</option>*/}
                    {/*        </Input>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <div className={styles.hrZones}>
                        <div className={styles.zonesArea}>
                            {zones.slice(0, -1).map((zone, i) => (
                                <div className={styles.zone} key={`${i}`}>
                                    <div className={styles.zoneLabel}>{t("zone x", {zone: i + 1})}</div>
                                    <div className={styles.outliner}/>
                                </div>
                            ))}
                            <div className={styles.maxLabel}>{t("max heart rate")}</div>
                        </div>
                        <div className={styles.zoneDetailsArea}>
                            {zones.map((zone, i) => {
                                const isLastItem = i === zones.length - 1;
                                return <div className={styles.numbersContainer} key={i}>
                                    <InputGroup style={{width: 136}}>
                                        <Input
                                            value={Number.isNaN(zone.bpm) ? "" : zone.bpm}
                                            onChange={(e) => setZoneBpm(i, parseInt(e.target.value))}
                                            disabled={(type !== HrZonesType.Manual &&
                                                !(isLastItem && type === HrZonesType.Max)) || readonly}
                                            invalid={invalidZones[i].bpm}
                                        />
                                        <InputGroupText>bpm</InputGroupText>
                                    </InputGroup>
                                    <InputGroup style={{width: 116, marginLeft: 10}}>
                                        <Input
                                            value={Number.isNaN(zone.percent) ? "" : zone.percent}
                                            onChange={(e) => setZonePercent(i, parseInt(e.target.value))}
                                            disabled={type !== HrZonesType.Manual || isLastItem ||
                                                readonly}
                                            invalid={invalidZones[i].percent}
                                        />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </div>;
                            })}
                        </div>
                    </div>
                    <FormFeedback
                        style={{
                            display: invalidZones.some(x =>
                                x.bpm || x.percent) ? "block" : "none"
                        }}>{t("heart rate zones error")}</FormFeedback>
                </Row>
            </Col>
            <Col md={6} className={"mt-5 mt-md-0"}>
                <h6>{t("hr zone types")}</h6>
                <ul>
                    <li>
                        <span>{t("hr zone auto")}</span>
                        <p>{t("hr zone auto description")}</p>
                    </li>
                    <li>
                        <span>{t("hr zone max")}</span>
                        <p>{t("hr zone max description")}</p>
                    </li>
                    <li>
                        <span>{t("hr zone manual")}</span>
                        <p>{t("hr zone manual description")}</p>
                    </li>
                </ul>
            </Col>
        </Row>
        {!readonly && <>
            <SizedBox height={16}/>
            {saving && <Spinner cssOverride={{alignSelf: "center"}}/>}
            {!saving &&
                <Button style={{alignSelf: "center"}}
                        label={t("save")}
                        disabled={!changed || invalidZones.some(x => x.bpm || x.percent)}
                        onClick={onSave}
                />
            }
        </>
        }
    </>;
};
export enum SportType {
    Kayaking = 1,
    Canoeing = 2,
    Suping = 3,
    Rowing = 4,
    DragonBoating = 5,
    Sculling = 6,
}

export const getSportIcon = (type: SportType): string => {
    switch (type) {
        case SportType.Kayaking:
            return '/icons/sports/ic_kayak.png';
        case SportType.Canoeing:
            return '/icons/sports/ic_canoe.png';
        case SportType.Suping:
            return '/icons/sports/ic_sup.png';
        case SportType.Rowing:
            return '/icons/sports/ic_paddle.png';
        case SportType.DragonBoating:
            return '/icons/sports/ic_paddle.png';
        case SportType.Sculling:
            return '/icons/sports/ic_paddle.png';
    }
}

import {Navigate, Route, Routes} from "react-router-dom";
import {DialogContainer} from "./components/base/DialogContainer/DialogContainer";
import AuthRoute from "./components/routes/AuthRoute/AuthRoute";
import NonAuthRoute from "./components/routes/NonAuthRoute/NonAuthRoute";
import {TrainingsContextProvider} from "./contexts/TrainingsContext";
import {AnalyticsPage} from "./pages/AnalyticsPage/AnalyticsPage";
import {CoachesPage} from "./pages/CoachesPage/CoachesPage";
import {SummaryPage} from "./pages/SummaryPage/SummaryPage";
import {ForgottenPasswordPage} from "./pages/ForgottenPasswordPage/ForgottenPasswordPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {ResetPasswordPage} from "./pages/ResetPasswordPage/ResetPasswordPage";
import {AthletesPage} from "./pages/AthletesPage/AthletesPage";
import {TrainingsPage} from "./pages/TrainingsPage/TrainingsPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import {useAxiosInterceptor} from "./utils/axiosInterceptors";
import {ConnectServicePage} from "./pages/ConnectServicePage/ConnectServicePage";
import React from "react";
import {LivePage} from "./pages/LivePage/LivePage";
import {LiveHome} from "./components/paddlemate/live/LiveHome/LiveHome";
import {LiveSettings} from "./components/paddlemate/live/LiveSettings/LiveSettings";

const MaybeReset = () => {
    const searchString = window.location.search;
    if (!searchString || !searchString.length || searchString[0] !== "?") {
        return <Navigate to="/login" replace/>;
    }
    const hashParts = searchString.split("hash=");
    if (!hashParts || !hashParts.length || hashParts.length === 1) {
        return <Navigate to="/login" replace/>;
    }
    const hash = hashParts[1].split("?")[0];
    if (!hash || !hash.length) {
        return <Navigate to="/login" replace/>;
    }
    return <Navigate to={`/reset-password?hash=${hash}`} replace/>;
};

export const App = () => {
    useAxiosInterceptor();
    return (
        <>
            <Routes>
                <Route path="/" element={<MaybeReset/>}/>
                <Route element={<NonAuthRoute/>}>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/forgotten-password" element={<ForgottenPasswordPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                </Route>
                <Route element={<AuthRoute/>}>
                    <Route path="/dashboard"
                           element={<SummaryPage/>}/>
                    <Route path="/trainings" element={
                        <TrainingsContextProvider>
                            <TrainingsPage/>
                        </TrainingsContextProvider>}/>
                    <Route path="/trainings/:trainingId" element={<AnalyticsPage/>}/>
                    <Route path="/trainees" element={<AthletesPage/>}/>
                    <Route path="/coaches" element={<CoachesPage/>}/>
                    <Route path="/profile">
                        <Route index element={<ProfilePage/>}/>
                        <Route path="/profile/:userId" element={<ProfilePage/>}/>
                    </Route>
                    <Route path="/connect/:service" element={<ConnectServicePage/>}/>
                    <Route path="/analytics/:trainingId"
                           element={<TrainingsContextProvider><AnalyticsPage/></TrainingsContextProvider>}/>
                    <Route path="/live" element={<LivePage/>}>
                        <Route index={true} element={<LiveHome/>}/>
                        <Route path="settings" element={<LiveSettings/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
            <DialogContainer/>
        </>
    );
};

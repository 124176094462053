import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {Spinner} from "../../components/base/Spinner/Spinner";
import {TimeSelector} from "../../components/base/TimeSelector/TimeSelector";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import {AthleteSelector} from "../../components/paddlemate/AthleteSelector/AthleteSelector";
import {GlanceStats} from "../../components/paddlemate/GlanceStats/GlanceStats";
import {GranularitySelector} from "../../components/paddlemate/GranularitySelector/GranularitySelector";
import AthletesContext from "../../contexts/AthletesContext";
import ResponsiveContext from "../../contexts/ResponsiveContext";
import styles from "./SummaryPage.module.css";
import UserContext from "../../contexts/UserContext";
import StatisticsContext from "../../contexts/StatisticsContext";
import {StatisticsData} from "../../typings/StatisticsData";
import {StatCategory} from "../../typings/StatCategory";
import {GroupByPeriod} from "../../typings/GroupByPeriod";
import {Formatter} from "../../utils/formatter";
import {Api} from "../../utils/api";
import {TimeInterval} from "../../typings/TimeInterval";
import {TimeGrouping} from "../../typings/TimeGrouping";
import {useLocaleData} from "../../hooks/useLocaleData";
import {GroupByDateSelector} from "../../components/base/IntervalSelector/GroupByDateSelector";

const getTimeGrouping = (timeInterval: TimeInterval, granularity: GroupByPeriod) => {
    switch (timeInterval) {
        case TimeInterval.Week:  // Only 1 valid option for week timeInterval
            return TimeGrouping.DayOfWeek;
        case TimeInterval.Month:
            if (granularity === GroupByPeriod.Months) {  // Monthly not available
                return TimeGrouping.WeekOfMonth;
            } else if (granularity === GroupByPeriod.Weeks) {
                return TimeGrouping.WeekOfMonth;
            } else {
                return TimeGrouping.DayOfMonth;
            }
        case TimeInterval.Year:
            if (granularity === GroupByPeriod.Days) {
                return TimeGrouping.DayOfYear;
            } else if (granularity === GroupByPeriod.Weeks) {
                return TimeGrouping.WeekOfYear;
            } else {
                return TimeGrouping.Month;
            }
    }
    return TimeGrouping.DayOfWeek;
}

export const SummaryPage = () => {
    const {t, i18n} = useTranslation();
    const {chartWidth} = useContext(ResponsiveContext);
    const {
        startDate, endDate, granularity,
        timeInterval, loadCached, statCategory, setStatCategory
    } = useContext(StatisticsContext);
    const {user, isCoach} = useContext(UserContext);
    const {athlete, athleteId} =
        useContext(AthletesContext);
    const [stats, setStats] =
        useState<StatisticsData | null>(null);
    const [loading, setLoading] =
        useState<boolean>(false);
    useEffect(() => {
        loadCached();
    }, [loadCached]);

    useEffect(() => {
        if (athleteId === undefined || isCoach === undefined || startDate === undefined || endDate === undefined) return;
        const get = async () => {
            try {
                setLoading(true);
                const stats = await Api.getStatistics(
                    getTimeGrouping(timeInterval, granularity),
                    startDate || new Date(0),
                    endDate || new Date(),
                    athleteId !== null ? athleteId : (!isCoach ? user!.id : undefined),
                    (isCoach && athleteId === null) ? user!.id : undefined);
                setStats(stats);
            } catch (e) {
                throw e;
            } finally {
                setLoading(false);
            }
        };
        get().catch(console.log);
    }, [athleteId, startDate, endDate, granularity, isCoach, timeInterval, user]);

    const [series, setSeries] =
        useState<Highcharts.SeriesOptionsType[]>([{
            dataLabels: {
                enabled: false,
            },
            label: {
                enabled: false,
            },
            name: undefined,
            showInLegend: false,
            data: [],
            type: "column",
        }]);
    const [xAxis, setXAxis] =
        useState<Highcharts.XAxisOptions>({
            tickWidth: 1,
            categories: [] as string[],
            labels: {
                enabled: true,
            },
            tickLength: 1,
        });

    const seriesY = useMemo(() => {
        if (!stats) return [];
        switch (statCategory) {
            case StatCategory.Sessions:
                return stats.trainingSessionsSeries;
            case StatCategory.Duration:
                return stats.durationSSeries.map(v => v / 60);
            case StatCategory.Distance:
                return stats.lengthKmSeries;
            case StatCategory.Calories:
                return stats.caloriesKcalSeries;
            case StatCategory.AverageForce:
                return stats.avgPaddlingForceNSeries;
            case StatCategory.AverageStrokeRate:
                return stats.avgStrokeRateSpmSeries;
        }
    }, [statCategory, stats]);

    const localeData = useLocaleData();

    const seriesX = useMemo(() => {
        if (granularity === GroupByPeriod.Days) {
            return seriesY.map((_, i) => localeData.weekdays()[(i + 1) % 7]);
        } else if (granularity === GroupByPeriod.Weeks) {
            return seriesY.map((_, i) => t('week x', {week: i + 1}));
        } else {
            return Array.from(Array(12).keys()).map(x =>
                Formatter.capitalizeFirstLetter(localeData.months()[(x) % 12]));
        }
    }, [granularity, seriesY, t, localeData]);
    const seriesFormatter = useCallback((x: any, y: any) => {
        switch (statCategory) {
            case StatCategory.Sessions:
                return `<div>${t('number of sessions tooltip')} <b>${x}</b>: <b>${y}</b></div>`;
            case StatCategory.Duration:
                return `<div>${t('sum training duration tooltip')} <b>${x}</b>: <b>${Formatter.secToTimeString(y)}</b></div>`;
            case StatCategory.Distance:
                return `<div>${t('sum distance tooltip')} <b>${x}</b>: <b>${(y as number).toFixed(1)} km</b></div>`;
            case StatCategory.Calories:
                return `<div>${t('sum calories tooltip')} <b>${x}</b>: <b>${Math.round(y as number)} kcal</b></div>`;
            case StatCategory.AverageForce:
                return `<div>${t('average pulling force tooltip')} <b>${x}</b>: <b>${(y as number).toFixed(0).replace('-0', '0')} N</b></div>`;
            case StatCategory.AverageStrokeRate:
                return `<div>${t('average strokerate tooltip')} <b>${x}</b>: <b>${(y as number).toFixed(1)} / min</b></div>`;
        }
        return '';
    }, [statCategory, t]);

    const [options, setOptions] =
        useState<Highcharts.Options>({
            accessibility: {
                enabled: false
            },
            chart: {
                type: "column",
                width: chartWidth,
                height: 400,
            },
            title: {
                text: "Training Sessions",
            },
            tooltip: {
                outside: true,
                useHTML: true,
                formatter: function (): string {
                    const self = this as any;
                    return seriesFormatter(self.x, self.y);
                },
            },
            yAxis: {
                title: {
                    text: "Number of sessions",
                },
            },
        });

    useEffect(() => {
        setOptions((options) => ({
            ...options,
            title: {
                text: t(`${statCategory} chart title`)
            },
            yAxis: {
                title: {
                    text: t(`${statCategory} chart y axis label`)
                },
            },
            tooltip: {
                ...options.tooltip,
                formatter: function (): string {
                    const self = this as any;
                    return seriesFormatter(self.x, self.y);
                }
            }
        }));
    }, [seriesFormatter, statCategory, t]);

    useEffect(() => {
        if (!stats) return;
        setXAxis((xAxis) => ({
            ...xAxis,
            categories: seriesX,
        }));
        setSeries((series) => ([{
            ...series[0],
            data: seriesY as any,
        }]));
    }, [statCategory, stats, startDate, endDate, seriesX, seriesY]);

    let title: string;
    if (!isCoach)
        title = t("statistics");
    else if (athlete?.name)
        title = t("x training report", {name: athlete!.name});
    else
        title = t("x training report", {name: t("every athlete")});

    return (
        <DashboardLayout>
            <SizedBox height={8}/>
            <div className={styles.header}>
                <div className={styles.nameText}>
                    {title}
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.headerLabel}>Athlete:</div>
                    <AthleteSelector/>
                </div>
            </div>
            <SizedBox height={8}/>
            <TimeSelector/>
            <SizedBox height={16}/>
            {!loading && stats && <GlanceStats
                selected={statCategory}
                setSelected={setStatCategory}
                stats={stats}
            />}
            <SizedBox height={40}/>
            {!loading && stats && stats.timePointsSeries.length > 0 &&
                <div style={{
                    position: "relative",
                    borderRadius: 16,
                    overflow: "hidden",
                }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            ...options,
                            chart: {
                                ...options.chart,
                                width: chartWidth,
                            },
                            xAxis,
                            series,
                        }}
                        allowChartUpdate={true}
                        updateArgs={[true, true, true]}
                    />
                    <GranularitySelector
                        className={styles.granularitySelector}
                    />
                </div>
            }
            {loading &&
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Spinner/>
                </div>
            }
        </DashboardLayout>
    );
};

import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/base/Button/Button';
import { SizedBox } from '../../components/base/SizedBox/SizedBox';
import { Spacer } from '../../components/base/Spacer/Spacer';
import { CoachingRequestDialog } from '../../components/dialogs/CoachingRequestDialog/CoachingRequestDialog';
import { YesCancelDialog } from '../../components/dialogs/YesCancelDialog/YesCancelDialog';
import DashboardLayout from '../../components/layouts/DashboardLayout/DashboardLayout';
import { CoachingRequestItem } from '../../components/paddlemate/CoachingRequestItem/CoachingRequestItem';
import AthletesContext from '../../contexts/AthletesContext';
import DialogContext from '../../contexts/DialogContext';
import UserContext from '../../contexts/UserContext';
import { IAthlete } from '../../typings/IAthlete';
import { ICoachingRequest } from '../../typings/ICoachingRequest';
import { Api } from '../../utils/api';
import styles from "./AthletesPage.module.css";
import {Spinner} from "../../components/base/Spinner/Spinner";

interface IAthletesPage {

}

export const AthletesPage = ({ }: IAthletesPage) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showError, openDialog } = useContext(DialogContext)
    const { athletes, deletedAthletes, refreshAthletes } = useContext(AthletesContext);
    const { user, isCoach } = useContext(UserContext);
    const [coachingRequests, setCoachingRequests] =
        useState<ICoachingRequest[] | null>(null);

    const onAthleteOpen = (athlete: IAthlete) => {
        navigate(`/profile/${athlete.id}`);
    };

    useEffect(() => {
        if (!user) return;

        let cancelled = false;

        (async () => {
            try {
                const coachingRequests = await Api.getCoachingRequests(user.id);
                if (cancelled) return;
                setCoachingRequests(coachingRequests.filter(req => req.athleteId !== user.id));
            } catch (e) {
                console.log(e);
            }
        })();

        return () => { cancelled = true };
    }, [user]);

    const onSend = async (email: string) => {
        let success = false;
        try {
            success = await Api.sendCoachingRequest(email);
        } catch (err) {
            success = false;
        }
        if (!success) {
            showError(t('error sending request'));
            return false;
        }
        try {
            const coachingRequests = await Api.getCoachingRequests(user!.id);
            setCoachingRequests(coachingRequests.filter(req => req.athleteId !== user!.id));
        } catch (err) { }
        return true;
    }

    const onNewRequest = () => {
        openDialog(<CoachingRequestDialog onSend={onSend} />);
    }

    const onResend = async (req: ICoachingRequest) => {
        const email = req.athleteEmail;

        let success = false;
        try {
            success = await Api.deleteCoachingRequest(req.id);
        } catch (err) {
            success = false;
        }
        if (!success) {
            showError(t('error resending request'));
            return;
        }
        try {
            success = await Api.sendCoachingRequest(email);
        } catch (err) {
            success = false;
        }
        if (!success) {
            showError(t('error sending request'));
            return;
        }
        try {
            const coachingRequests = await Api.getCoachingRequests(user!.id);
            setCoachingRequests(coachingRequests.filter(req => req.athleteId !== user!.id));
        } catch (err) { }
    }

    const onDelete = async (req: ICoachingRequest) => {
        const success = await Api.deleteCoachingRequest(req.id);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        setCoachingRequests((coachingRequests) => {
            const newCoachingRequests = coachingRequests!.slice();
            const index = newCoachingRequests!.findIndex(c => c.id === req.id);
            if (index !== -1) newCoachingRequests!.splice(index, 1);
            return newCoachingRequests;
        });
    }

    const onDeleteAthlete = async (athleteId: number) => {
        const success = await Api.deleteAthlete(user!.id, athleteId);
        if (!success) {
            showError(t('error deleting request'));
            return;
        }
        refreshAthletes();
    }

    const onDeleteAthleteWrapper = (e: any, athleteId: number) => {
        e.preventDefault();
        e.stopPropagation();
        openDialog(
            <YesCancelDialog
                title={t('are your sure you want to delete it')}
                onYes={() => onDeleteAthlete(athleteId)}
            />
        );
    }

    useEffect(() => {
        if (user === null) return;
        if (!isCoach) navigate('/dashboard');
    }, [user, isCoach]);

    return (
        <DashboardLayout>
            <SizedBox height={8} />
            <div className={styles.title}>{t('trainees')}</div>
            <div className={styles.listBox}>
                {athletes === null &&
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16, padding: 20}}>
                        <Spinner/>
                    </div>
                }
                {athletes?.length === 0 && <div style={{
                    display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16,
                    padding: 20, fontSize: 18
                }}>
                    {t("no athletes")}
                </div>}
                {athletes?.map(athlete => (
                    <div
                        key={athlete.id}
                        className={styles.athlete}
                        onClick={() => onAthleteOpen(athlete)}
                    >
                        <img src={athlete.profilePictureUrl} className={styles.profilePic} />
                        <SizedBox width={8} />
                        <div>
                            <div className={styles.name}>{athlete.name}</div>
                            <div className={styles.club}>{athlete.club}</div>
                        </div>
                        <Spacer flex={1} />
                        {/* <div className={styles.trainingCountContainer}>
                            <span className={styles.trainingCountText}>{t('trainings last week')}:</span><span className={styles.trainingCount}>{athlete.lastWeekTrainingCount}</span>
                        </div> */}
                        <div className={styles.deleteIcon} onClick={(e) => onDeleteAthleteWrapper(e, athlete.id)}>
                            <Icon size={1.2} path={mdiClose} />
                        </div>
                    </div>
                ))}
                {deletedAthletes && deletedAthletes.length !== 0 &&
                    <div className={styles.listBox}>
                        {deletedAthletes.map(athlete => (
                            <CoachingRequestItem
                                key={athlete.id}
                                request={{
                                    athleteId: athlete.id,
                                    athleteName: athlete.name,
                                    athleteEmail: "",
                                    athleteProfilePictureUrl: athlete.profilePictureUrl,
                                    coachEmail: "",
                                    denied: true,
                                    id: 0,
                                }}
                                onResend={() => { }}
                                onDelete={() => onDeleteAthlete(athlete.id)}
                                didAthleteDelete={true}
                            />
                        ))}
                    </div>
                }
            </div>
            <SizedBox height={16} />
            <div className={styles.title}>{t('coaching requests')}</div>
            {coachingRequests === null &&
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16, padding: 20}}>
                    <Spinner/>
                </div>
            }
            {coachingRequests?.length === 0 && <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16,
                padding: 20, fontSize: 18
            }}>
                {t("no coaching requests")}
            </div>}
            {coachingRequests !== null &&
                <div className={styles.listBox}>
                    {coachingRequests.map(req => (
                        <CoachingRequestItem
                            key={req.id}
                            request={req}
                            onResend={() => onResend(req)}
                            onDelete={() => onDelete(req)}
                        />
                    ))}
                </div>
            }
            <SizedBox height={24} />
            <div className={styles.newRequestRow}>
                <Button
                    label={t('new request')}
                    onClick={onNewRequest}
                />
            </div>

        </DashboardLayout>
    );
};

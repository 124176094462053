//import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {AppWrapper} from './AppWrapper';
import './scss/global.css';
import './scss/_theme.scss';
import './scss/_bootstrap.scss';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import {ErrorPage} from "./components/ErrorPage";
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/hu'
import {CaptureConsole} from '@sentry/integrations';

if (process.env.REACT_APP_SENTRY_DSN)
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
            new CaptureConsole({
                levels: ["error", "assert"]
            })
        ],
        tracesSampleRate: (process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE && +process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || undefined,
        replaysSessionSampleRate: (process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE && +process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || undefined,
        replaysOnErrorSampleRate: 1.0,
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorPage}>
        <BrowserRouter>
            <AppWrapper/>
        </BrowserRouter>
    </Sentry.ErrorBoundary>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

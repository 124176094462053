import React, {useCallback, useEffect, useRef, useState} from 'react';
import OkDialog from '../components/dialogs/OkDialog/OkDialog';
import {useLocation} from "react-router-dom";

interface IDialog {
    dialog: JSX.Element;
    key: string;
    onDismiss?: () => void
}

interface IDialogContext {
    dialogs: IDialog[],
    openDialog: (modal: JSX.Element, onDismiss?: () => void) => string;
    dismissDialog: (key: string) => void;
    showOkDialog: (title: string, message: string, onDismiss?: () => void) => void;
    showError: (message: string, onDismiss?: () => void) => void;
}

const defaultDialogContextValues: IDialogContext = {
    dialogs: [],

    openDialog: (modal: JSX.Element, onDismiss) => "",
    dismissDialog: (key) => {
    },

    showOkDialog: (title, message, onDismiss) => {
    },
    showError: (message, onDismiss) => {
    },
};

export const DialogContext = React.createContext(defaultDialogContextValues);

const genKey = () => {
    const rnd = Math.floor(Math.random() * 10000);
    return `u-${Date.now()}${rnd}`;
}

export const DialogContextProvider = ({children}: { children: React.ReactNode }) => {
    const dialogsRef = useRef<IDialog[]>([]);
    const [dialogs, setDialogs] = useState<IDialog[]>([]);

    const openDialog = useCallback((dialog: JSX.Element, onDismiss?: () => void) => {
        const key = genKey();
        const newDialogs = [...dialogsRef.current, {dialog, key, onDismiss}];
        setDialogs(newDialogs);
        dialogsRef.current = newDialogs;
        return key;
    }, []);

    const dismissDialog = useCallback((key: string) => {
        const newDialogs = dialogsRef.current.slice();
        const index = newDialogs.findIndex(dialog => dialog.key === key);
        if (index === -1) return;
        const d = newDialogs[index];
        newDialogs.splice(index, 1);
        setDialogs(newDialogs);
        dialogsRef.current = newDialogs;
        d.onDismiss?.();
    }, []);

    const showError = useCallback((message: string, onDismiss?: () => void) => {
        const errorDialog = <OkDialog dialogKey={genKey()} message={message}/>;
        openDialog(errorDialog, onDismiss);
    }, [openDialog]);

    const showOkDialog = useCallback((title: string, message: string, onDismiss?: () => void) => {
        const errorDialog = <OkDialog dialogKey={genKey()} message={message} title={title}/>;
        openDialog(errorDialog, onDismiss);
    }, [openDialog]);

    const location = useLocation();
    useEffect(() => {
        for (const dialog of dialogsRef.current) {
            dismissDialog(dialog.key);
        }
    }, [dismissDialog, location]);

    return (
        <DialogContext.Provider value={{
            dialogs,
            openDialog,
            dismissDialog,
            showError,
            showOkDialog,
        }}>
            {children}
        </DialogContext.Provider>
    );
}

export default DialogContext;

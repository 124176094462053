import {FallbackRender} from "@sentry/react/types/errorboundary";
import {TFunction, withTranslation} from "react-i18next";
import {Alert, Button, Col, Container, Row} from "reactstrap";

const ErrorPageContent = withTranslation()
(({t, resetError}: { t: TFunction<"translation", undefined>, resetError: () => void }) => {
    return <Container style={{marginTop: 100}}>
        <Row className={"justify-content-center"}>
            <Col style={{maxWidth: 560}}>
                <Alert color="danger">
                    <h4 className="alert-heading">
                        {t("fatal error")}
                    </h4>
                    {t("fatal error reload instruction")}:
                    <div style={{marginTop: 20, display: "flex", justifyContent: "center"}}><Button
                        onClick={() => {
                            resetError();
                        }}
                    >
                        {t("fatal error reload")}
                    </Button></div>
                    <hr />
                    <div>{t("fatal error contact")} <a
                        href={`mailto:${process.env.REACT_APP_API_CONTACT_EMAIL}`}>{process.env.REACT_APP_API_CONTACT_EMAIL}</a>
                    </div>
                </Alert>
            </Col>
        </Row>
    </Container>;
});
export const ErrorPage: FallbackRender = ({resetError}) => {
    return <ErrorPageContent resetError={resetError}/>
}
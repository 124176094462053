import moment from "moment";
import Highcharts from "highcharts";

const getStandardChartOptions = (height: number|null, unitText?: string) => {
    return {
        accessibility: {
            enabled: false
        },
        chart: {
            type: "line",
            height: height,
            marginLeft: 80,
            animation: false,
            zooming: {
                type: 'x',
                singleTouch: true
            },
            panning: {
                enabled: true,
                type: 'x'
            },
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                },
            }
        },
        title: {
            text: '',
        },
        plotOptions: {
            line: {
                animation: {
                    duration: 0,
                },
            },
            series: {
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                },
                animation: {
                    duration: 0,
                },
                lineWidth: 1
            },
        },
        drilldown: {
            animation: {
                duration: 0,
            },
        },
        tooltip: {
            useHTML: true,
            shared: true,
            formatter: function (): string {
                const self = this as any;
                return `${moment(self.x).utc().format("H:mm:ss")} <b>${self.y.toFixed(1)}</b> ${unitText}`;
            },
            positioner: function (w: number, __: number, p: Highcharts.TooltipPositionerPointObject) {
                const self = this as any;
                return {
                    x: p.plotX + w+40 > self.chart.plotWidth ? (p.plotX - w/2) : (p.plotX+w),
                    y: 20,
                };
            },
            followTouchMove: false
        },
        xAxis: {
            tickWidth: 1,
            crosshair: {
                color: '#999',
                width: 1,
                // snap: false,
            },
            type: "datetime",
            dateTimeLabelFormats: { // don't display day, week, etc.
                millisecond: '%H:%M:%S.%L',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '',
                week: '',
                month: '',
                year: ''
            },
            minRange: 1,
            minPadding: 0,
            maxPadding: 0
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                formatter: function (self: any) {
                    return Math.round((self.value + Number.EPSILON) * 100) / 100;
                }
            }
        },
        legend: {
            enabled: false,
        },
    }
}

const getZoneChartOptions = (chartHeight: number|null) => {
    return {
        accessibility: {
            enabled: false
        },
        chart: {
            type: 'bar',
            height: chartHeight,
        },
        title: {
            text: '',
        },
        tooltip: {
            useHTML: true,
            formatter: function (): string {
                const self = this as any;
                return `<b>${(self.y as number).toFixed(1)}%</b>`;
            },
        },
        yAxis: {
            title: {
                text: ''
            },
            min: 0,
            maxPadding: 0
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0,
                shadow: false,
                dataLabels: {
                    enabled: true,
                    inside: true,
                    backgroundColor: "transparent",
                    format: "{y:.0f}%",
                    align: "left",
                    allowOverlap: true,
                    style: {
                        fontSize: '12px',
                        textShadow: false,
                        fontWeight: 'normal'
                    }
                }
            }
        }
    }
}

export const ChartHelper = {
    getStandardChartOptions,
    getZoneChartOptions,
};

import {DateSelector} from '../DateTimeSelector/DateSelector';
import styles from './ExactTimeSelector.module.css';

interface IExactTimeSelector {
    startDate: Date|undefined;
    setStartDate: (date: Date) => void;
    endDate: Date|undefined;
    setEndDate: (date: Date) => void;
}

export const ExactTimeSelector = ({startDate, setStartDate, endDate, setEndDate}: IExactTimeSelector) => {
    return (
        <div className={styles.container}>
            <DateSelector date={startDate} setDate={setStartDate} highlighted={true}/>
            &nbsp;-&nbsp;
            <DateSelector date={endDate} setDate={setEndDate} highlighted={true}/>
        </div>
    );
};

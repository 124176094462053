import {useContext, useRef} from "react";
import customAxios from "./axios";
import UserContext from "../contexts/UserContext";
import {LoginState} from "../typings/LoginState";
import {ApiEC} from "./ApiEC";


export const useAxiosInterceptor = () => {
    const {
        loginState, refreshToken, accessTokenRef,
        isAccessTokenExpired, logout
    } = useContext(UserContext);
    const accessTokenInterceptor = useRef<number>();
    const refreshTokenInterceptor = useRef<number>();
    if (accessTokenInterceptor.current)
        customAxios.interceptors.request.eject(accessTokenInterceptor.current);
    accessTokenInterceptor.current = customAxios.interceptors.request.use(
        async (config) => {
            if (accessTokenRef.current) {
                const bearer = `Bearer ${accessTokenRef.current}`;
                if (config.headers) {
                    config.headers.Authorization = bearer;
                } else {
                    config.headers = {authorization: bearer};
                }
            }
            return config;
        },
        (error) => Promise.reject(error)
    );
    if (refreshTokenInterceptor.current)
        customAxios.interceptors.request.eject(refreshTokenInterceptor.current);
    refreshTokenInterceptor.current = customAxios.interceptors.request.use(
        async (config) => {
            if (config.url !== "/connect/token" && (loginState === LoginState.loggedin && isAccessTokenExpired())) {
                try {
                    await refreshToken?.();
                } catch (error: any) {
                    if (error.message === ApiEC.Logout)
                        await logout();
                    throw error;
                }
            }
            return config;
        },
        (error) => Promise.reject(error)
    );
};

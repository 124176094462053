import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import styles from "./LiveStats.module.scss";
import {ILiveBoat, ILiveBoatAthlete} from "../LiveHome/LiveHome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import ResponsiveContext from "../../../../contexts/ResponsiveContext";
import {max} from "../../../../utils/arrayUtils";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiMinusCircleOutline, mdiPlusCircleOutline} from "@mdi/js";
import {isRowing} from "../../../../typings/TrainingSport";
import {speedToTempo500} from "../../../../utils/unitHelpers";

export const LiveStats = ({boat, athlete}: { boat: ILiveBoat, athlete: ILiveBoatAthlete }) => {
    const {t} = useTranslation();
    const {windowSize} = useContext(ResponsiveContext);
    const chartRef = useRef<any>();
    const [selectedSeries, setSelectedSeries] = useState([
        "speed", "hr", "strokeRate", "force"
    ]);

    useEffect(() => {
        // highcharts need to be resized manually
        chartRef.current?.chart.reflow();
    }, [windowSize]);

    const getFormatter = (unit: string) => {
        return function (self: any) {
            const val = Math.round((self.value + Number.EPSILON));
            return `${val} ${unit}`;
        }
    }

    const maxOrDefaultFn = (def: number) => {
        return (data: (number | null)[] | undefined) =>
            Math.max(def, (data && max(data.map(x => x ? x : 0) || [0])) || 0)
    }

    const availableSeries: any[] = [];
    if (boat.sport && isRowing(boat.sport)) {
        availableSeries.push({
            key: "speed",
            name: "tempo split",
            color: "#DCBC1C",
            formatter: function (self: any) {
                return Highcharts.dateFormat("%M:%S", self.value * 60 * 1000);
            },
            data: boat.stats?.map(x => speedToTempo500(x.speed || 8)),
            maxY: (data: (number)[] | undefined) => {
                return (data && max(data)) || 8;
            },
            reversedY: true
        });
    } else {
        availableSeries.push({
            key: "speed",
            name: "speed",
            color: "#DCBC1C",
            formatter: getFormatter("km/h"),
            data: boat.stats?.map(x => x.speed),
            maxY: maxOrDefaultFn(10)
        });
    }

    availableSeries.push(...[{
        key: "strokeRate",
        name: "stroke rate",
        color: "#ADDC1C",
        formatter: getFormatter("spm"),
        data: boat.stats?.map(x => x.strokeRate),
        maxY: maxOrDefaultFn(10)
    }, {
        key: "hr",
        name: "heartrate",
        color: "#DC1C1C",
        formatter: getFormatter("bpm"),
        data: athlete.stats?.map(x => x.hr === undefined ? null : x.hr),
        maxY: maxOrDefaultFn(150)
    }, {
        key: "force",
        name: "live force",
        color: "#1C83DC",
        formatter: function (self: any) {
            const val = Math.round((self.value + Number.EPSILON) * 100) / 100;
            return `${val} N`;
        },
        data: athlete.stats?.map(x => x.force),
        maxY: maxOrDefaultFn(100)
    }]);

    const toggleSelection = (key: string) => {
        setSelectedSeries(x => {
            if (selectedSeries.includes(key)) {
                return (x.length > 1 && x.filter(y => y !== key)) || x
            } else
                return [...x, key]
        });
    };

    const zoomFactor = 0.7;
    const zoomIn = () => {
        if (!chartRef.current)
            return;
        const xAxis = chartRef.current.chart.xAxis[0];
        const range = xAxis.max - xAxis.min;
        const newRange = range * zoomFactor;
        const rangeDiff = range - newRange;
        let min = Math.max(0, xAxis.min + rangeDiff / 2);
        let max = Math.min(xAxis.max - rangeDiff / 2, xAxis.dataMax);
        if (min === 0 && max !== xAxis.dataMax)
            min = 0;
        if (max === xAxis.dataMax && min !== 0)
            max = xAxis.dataMax;
        xAxis.setExtremes(min, max);
    }

    const zoomOut = () => {
        if (!chartRef.current)
            return;
        const xAxis = chartRef.current.chart.xAxis[0];
        const range = xAxis.max - xAxis.min;
        const newRange = range / zoomFactor;
        const rangeDiff = newRange - range;
        let min = Math.max(xAxis.dataMin, xAxis.min - rangeDiff / 2);
        let max = Math.min(xAxis.max + rangeDiff / 2, xAxis.dataMax);
        if (min === 0 && max !== xAxis.dataMax)
            min = 0;
        if (max === xAxis.dataMax && min !== 0)
            max = xAxis.dataMax;
        xAxis.setExtremes(min, max);
    }

    const resetZoom = () => {
        if (!chartRef.current)
            return;
        chartRef.current.chart.xAxis[0].setExtremes();
    }

    return <div
        className={styles.container}>
        <div
            className={styles.chartContainer}>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                updateArgs={[true, true, false]}
                options={{
                    accessibility: {
                        enabled: false
                    },
                    chart: {
                        type: "line",
                        animation: false,
                        panning: {
                            enabled: true,
                            type: "x"
                        },
                        zooming: {
                            type: "x"
                        },
                        resetZoomButton: {
                            theme: {
                                style: {
                                    display: 'none'
                                }
                            },
                        }
                    },
                    plotOptions: {
                        line: {
                            animation: {
                                duration: 0,
                            },
                        },
                        series: {
                            marker: {
                                enabled: false,
                                states: {
                                    hover: {
                                        enabled: false
                                    }
                                }
                            },
                            states: {
                                hover: {
                                    enabled: false
                                },
                                inactive: {
                                    opacity: 1
                                }
                            },
                            animation: {
                                duration: 0,
                            },
                        },
                    },
                    title: {
                        text: "",
                    },
                    xAxis: {
                        reversed: true,
                        tickWidth: 1,
                        labels: {
                            enabled: true,
                            useHTML: true,
                            formatter: (self: any) => {
                                return `<div style="text-align: center">${Highcharts.dateFormat("%M", (self.axis.dataMax - self.value) * 1000)}</div>`;
                            },
                        },
                        minPadding: 0,
                        maxPadding: 0,
                        tickInterval: 60, // 1 minute
                    },
                    yAxis: availableSeries.map((x, i) => ({
                        title: {text: undefined},
                        labels: {
                            enabled: selectedSeries.includes(x.key),
                            formatter: x.formatter,
                            style: {
                                color: x.color
                            }
                        },
                        opposite: availableSeries.filter(x => selectedSeries.includes(x.key)).indexOf(x) % 2 === 1,
                        max: x.maxY(x.data),
                        min: 0,
                        reversed: x.reversedY
                    })),
                    series: availableSeries.map((x, i) => ({
                        data: x.data,
                        yAxis: i,
                        visible: selectedSeries.includes(x.key),
                        color: x.color
                    })),
                    tooltip: {
                        enabled: false,
                        followTouchMove: false
                    },
                    legend: {
                        enabled: false,
                    },
                }}
            />
            <div className={styles.zoomButtons}>
                <Button size="sm" outline={true} className={styles.zoomButton100}
                        onClick={resetZoom}>
                    {t("reset")}
                </Button>
                <Button size="sm" outline={true} className={styles.zoomButton}
                        onClick={zoomIn}>
                    <Icon
                        path={mdiPlusCircleOutline} size={0.8}/>
                </Button>
                <Button size="sm" outline={true} className={styles.zoomButton} onClick={zoomOut}>
                    <Icon path={mdiMinusCircleOutline}
                          size={0.8}/>
                </Button>
            </div>
        </div>
        <div className={styles.legend}>
            {availableSeries.map((x, i) => {
                    const isSelected = selectedSeries.includes(x.key);
                    return <div className={`${styles.athlete} ${isSelected ? styles.selected : ""}`}
                                onClick={() => toggleSelection(x.key)} key={x.key}>
                        <div className={styles.athleteInner}>
                            <span className={styles.athleteName}>{t(x.name)}</span>
                        </div>

                        <div
                            className={`${styles.color}`}
                            style={{backgroundColor: x.color}}>
                        </div>
                    </div>
                }
            )}
        </div>
    </div>
}

import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';

const year = (new Date()).getFullYear();

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <span>{t('copyright notice', { year })}</span>
        </div>
    );
};

import {mdiLogin} from '@mdi/js';
import Icon from '@mdi/react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Button} from '../../components/base/Button/Button';
import {SizedBox} from '../../components/base/SizedBox/SizedBox';
import {TextInput} from '../../components/base/TextInput/TextInput';
import {CardLayout} from '../../components/layouts/CardLayout/CardLayout';
import UserContext from '../../contexts/UserContext';
import styles from './ResetPasswordPage.module.css';

interface IResetPasswordPage {

}

let blocked = false;

export const ResetPasswordPage = ({}: IResetPasswordPage) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const [password, setPassword] = useState<string>("");
    const [done, setDone] = useState<boolean>(false);
    const [success, setSuccess] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [encodedHash, setEncodedHash] = useState<string>("");

    const onResetPassword = useCallback(async () => {
        if (blocked) return;
        if (!password) return setError(t('no password error'));
        if (password.length < 8) return setError(t('password too short error'));
        blocked = true;
        try {
            const status = await userContext.resetPassword(password, encodedHash);
            switch (status) {
                case 200:
                    setSuccess(t('password reset successful'));
                    setDone(true);
                    break;
                default:
                    setError(t('reset password error'))
                    break;
            }
        } catch (e) {
            setError(t('reset password error'))
        }
        blocked = false;
    }, [password, encodedHash, userContext, t]);

    const onLogin = useCallback(async () => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('hash')) {
            setEncodedHash(params.get('hash') as string);
        } else {
            navigate('/login');
        }
    }, []);


    return (
        <div className={styles.container}>
            <CardLayout
                title={t('reset password title')}
                subtitle={t('reset password subtitle')}
            >
                {success &&
                  <div className={styles.successLabel}>{success}</div>
                }
                {error &&
                  <div className={styles.errorLabel}>{error}</div>
                }
                {!error && !success &&
                  <SizedBox height={20}/>
                }
                {!done &&
                  <>
                    <TextInput
                      label={t('new password')}
                      value={password}
                      type="password"
                      onChange={(e) => {
                          setPassword(e.target.value);
                          setError(null);
                      }}
                    />
                    <SizedBox height={48}/>
                    <Button
                      label={t("reset password")}
                      onClick={onResetPassword}
                    />
                    <SizedBox height={24}/>
                    <div className={styles.linkContainer}>
                      <Link className={styles.link} to={"/login"}>
                        <Icon className={styles.login} path={mdiLogin} size={0.7}/>
                          {t('login')}
                      </Link>
                    </div>
                  </>
                }
                {done &&
                  <Button
                    label={t("login")}
                    onClick={onLogin}
                  />
                }
            </CardLayout>
        </div>
    );
};

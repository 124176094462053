import moment from 'moment';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import sanitize from 'sanitize-filename';
import DialogContext from '../../../contexts/DialogContext';
import {genderToString} from '../../../typings/GenderType';
import {getTrainingSportStringFromNumber} from '../../../typings/TrainingSport';
import {Button} from '../../base/Button/Button';
import {SizedBox} from '../../base/SizedBox/SizedBox';
import {Toggle} from '../../base/Toggle/Toggle';
import DialogShim from '../DialogShim';
import styles from './ExportDialog.module.css';
import {ISlice} from "../../paddlemate/AnalyticsPanel/AnalyticsPanel";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";
import {ITrainingCommonTraining} from "../../../typings/ITrainingCommonTraining";
import {Api} from "../../../utils/api";
import {ITrainingCommonFile} from "../../../typings/ITrainingCommonFile";
import {getTrainingFullName} from "../../../utils/trainingName";

import {ILapData} from "../../paddlemate/AnalyticsPanel/Lap/LapHook";

interface IExportDialog {
    dialogKey?: string;
    training: ITrainingCommon;
    trainingFile: ITrainingCommonFile | null;
    selectedTrainings: ITrainingCommonTraining[] | null;
    slice: ISlice | null;
    lap: ILapData | null;
}

function download(name: string, content: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function downloadBinary(name: string, content: ArrayBuffer) {
    const url = window.URL.createObjectURL(new Blob([content]));
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', name);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function getAtTime<T>(time: number, range: number, arr: T[] | undefined | null) {
    if (!arr) return '';
    let index = Math.floor((time / range) * arr.length);
    if (index >= arr.length) return arr[arr.length - 1];
    return arr[index];
}

const parseSeries = (series: string) => series.split(',').map(val => parseFloat(val));

enum ApiFormat {
    fit = "fit",
    gpx = "gpx",
    tcx = "tcx",
}

export const ExportDialog = ({dialogKey: key, training, trainingFile, slice, selectedTrainings,
                                 lap}: IExportDialog) => {
    const dialogContext = useContext(DialogContext);
    const [onlySelectedTrainings, setOnlySelectedTrainings] = useState<boolean>(true);
    const [doSlice, setDoSlice] = useState<boolean>(true);
    const [jsonLoading, setJsonLoading] = useState<boolean>(false);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);
    const apiFormats = Object.values(ApiFormat);
    const [apiLoadings, setApiLoadings] = useState<{ [key in ApiFormat]?: boolean }>({});
    const {t: translate} = useTranslation();

    const onDismiss = () => dialogContext.dismissDialog(key!);
    if (key === undefined) console.warn('Dialog without a key opened!');

    const onJson = async () => {
        try {
            setJsonLoading(true);

            let trainings = [...training.trainings];
            if (onlySelectedTrainings && selectedTrainings) {
                trainings = trainings.filter(x => selectedTrainings.includes(x))
            }

            let sliceIndex: any = null;
            if (doSlice && slice) {
                const start = Math.floor(slice.startMs / 1000);
                sliceIndex = {
                    start: start,
                    end: start + Math.floor((slice.endMs - slice.startMs) / 1000)
                }
                if (sliceIndex.end === sliceIndex.start)
                    sliceIndex.end++;
            }
            const sliceFn = (series: ((number | string)[] | undefined), rate = 1) => {
                if (Array.isArray(series)) {
                    if (sliceIndex)
                        return series.slice(sliceIndex.start * rate, sliceIndex.end * rate).join(",");
                    else
                        return series.join(",");
                } else return null
            };
            const data = {
                name: training.name ?? null,
                sport: training.sport ?? null,
                lengthKm: training.lengthKm ?? null,
                startAt: training.startAt ?? null,
                endAt: training.endAt ?? null,
                speedKphSeries: sliceFn(training.speedData),
                lengthKmSeries: sliceFn(training.distanceData),
                strokeRateSpmSeries: sliceFn(training.strokeData),
                distancePerStrokeMSeries: sliceFn(training.distancePerStrokeData),
                coordinateSeries: sliceFn(training.gpsData?.map(x => `${x.lat}|${x.lng}`)),
                recorderDevice: training.recorderDevice,
                imuSeries: trainingFile?.imuSeries,
                trainings: trainings.map(t => {
                    return {
                        user: t.user,
                        leftPaddlingForceNSeries: sliceFn(t.leftPaddlingForceNSeries),
                        detailedLeftPaddlingForceNSeries: sliceFn(t.detailedLeftPaddlingForceNSeries, 100),
                        rightPaddlingForceNSeries: sliceFn(t.rightPaddlingForceNSeries),
                        detailedRightPaddlingForceNSeries: sliceFn(t.detailedRightPaddlingForceNSeries, 100),
                        leftBatterySeries: sliceFn((t.leftBatterySeries &&
                            parseSeries(t.leftBatterySeries!)) || []),
                        rightBatterySeries: sliceFn((t.rightBatterySeries &&
                            parseSeries(t.rightBatterySeries!)) || []),
                        heartRateBpmSeries: sliceFn(t.heartRateBpmSeries),
                        devices: t.devices,
                    }
                })
            };

            const name = trainings.map(t => t.user?.name ?? "guest athlete").join("_") + "_" +
                training.name + "_" +
                (moment(training.startAt).format("YYYY-MM-DD_HH-mm"));
            const safeName = sanitize(name).replace(/[\s]/g, "_");

            download(`${safeName}.json`, JSON.stringify(data));
        } finally {
            setJsonLoading(false);
        }
    }

    const onCsv = async () => {
        try {
            setCsvLoading(true);
            let trainings = [...training.trainings];
            if (onlySelectedTrainings && selectedTrainings) {
                trainings = trainings.filter(x => selectedTrainings.includes(x))
            }

            const rows = [];
            const userCount = trainings.length;
            rows.push('name,trainingname,age,sex,type,starttime,endtime,podinfo,baseinfo,trackerinfo');
            rows.push([
                trainings.map(t => t.user?.name ?? translate("guest athlete")).join('/'),
                training.name.split(',').join('_').split('\\').join('_').split('"').join('_'),
                trainings.map(t =>
                    ((t.user)?.birthdate ? (-1 * moment((t.user)?.birthdate)
                        .diff(moment(), 'year', false)).toString() : '')).join('/'),
                trainings.map(t => (genderToString((t.user)?.gender))).join('/'),
                getTrainingSportStringFromNumber(training.sport),
                moment(training.startAt).utc().format('YYYY.MM.DD HH:mm:ss'),
                moment(training.endAt).utc().format('YYYY.MM.DD HH:mm:ss'),
                trainings.map(training =>
                    training.devices.map(d => `/${d.hw}/${d.sw}`).join('/')).join('/'),
                trainings.map(training =>
                    training.devices.map(d => `${d.baseSn}/${d.baseHw}/${d.baseCalibration}`).join('/')).join('/'),
                '',  // trackerinfo?
            ].join(','));

            const headerRow: string[] = [];
            headerRow.push('timestamp');
            for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                headerRow.push(`force_${userIndex + 1}_l`);
                headerRow.push(`averageforce_${userIndex + 1}_l`);
                headerRow.push(`force_${userIndex + 1}_r`);
                headerRow.push(`averageforce_${userIndex + 1}_r`);
            }
            headerRow.push('strokerate', 'distance_per_stroke', 'speed', 'length');
            for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                headerRow.push(`battery_${userIndex + 1}_l`);
                headerRow.push(`battery_${userIndex + 1}_r`);
            }
            for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                headerRow.push(`heartrate_${userIndex + 1}`);
            }
            headerRow.push('gpslat', 'gpslon', 'imuAx', 'imu_a_y', 'imu_a_z', 'imu_g_x', 'imu_g_y', 'imu_g_z');

            rows.push(headerRow.join(','));

            const range = ((new Date(training.endAt)).getTime() - (new Date(training.startAt).getTime()));
            let fromMs = (doSlice && slice) ? slice.startMs : 0;
            let toMs = (doSlice && slice) ? slice.endMs : range;
            for (let i = fromMs; i <= toMs; i += 10) {
                const hours = Math.floor(i / 1000 / 60 / 60);
                const mins = Math.floor((i - (hours * 1000 * 60 * 60)) / 1000 / 60);
                const secs = Math.floor((i - (hours * 1000 * 60 * 60) - (mins * 1000 * 60)) / 1000);
                const ms = i % 1000;
                const time = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}:${ms.toString().padStart(3, '0')}`
                let row = `${time},`;
                for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                    row += getAtTime(i, range, trainings[userIndex].detailedLeftPaddlingForceNSeries) + ',';
                    row += getAtTime(i, range, trainings[userIndex].leftPaddlingForceNSeries) + ',';
                    row += getAtTime(i, range, trainings[userIndex].detailedRightPaddlingForceNSeries) + ',';
                    row += getAtTime(i, range, trainings[userIndex].rightPaddlingForceNSeries) + ',';
                }
                row += getAtTime(i, range, training.strokeData) + ',';
                row += getAtTime(i, range, training.distancePerStrokeData) + ',';
                row += getAtTime(i, range, training.speedData) + ',';
                row += getAtTime(i, range, training.distanceData) + ',';
                for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                    const leftBatterySeries = trainings[userIndex].leftBatterySeries &&
                        parseSeries(trainings[userIndex].leftBatterySeries!);
                    const rightBatterySeries = trainings[userIndex].rightBatterySeries &&
                        parseSeries(trainings[userIndex].rightBatterySeries!);
                    if (leftBatterySeries && leftBatterySeries!.length > 0) {
                        row += getAtTime(i, range, leftBatterySeries);
                    }
                    row += ',';
                    if (rightBatterySeries && rightBatterySeries!.length > 0) {
                        row += getAtTime(i, range, rightBatterySeries);
                    }
                    row += ',';
                }
                for (let userIndex = 0; userIndex < userCount; userIndex += 1) {
                    row += getAtTime(i, range, trainings[userIndex].heartRateBpmSeries) + ',';
                }
                const iLatLng = getAtTime(i, range, training.gpsData);
                row += iLatLng === '' ? ',' : `${iLatLng?.lat ?? ''},${iLatLng?.lng ?? ''}`;
                row += ",";
                const imu = getAtTime(i, range, trainingFile?.imuSeries)
                if (imu === "") {
                    row += ',,,,,'
                } else {
                    row += imu.join(",")
                }
                rows.push(row);
            }

            const name = trainings.map(t => t.user?.name ?? "guest athlete").join("_") + "_" +
                training.name + "_" +
                (moment(training.startAt).format("YYYY-MM-DD_HH-mm"));
            const safeName = sanitize(name).replace(/[\s]/g, "_");

            download(`${safeName}.csv`, rows.join('\n'));

            if(lap) {
                // download laps csv
                const csvRows = [];
                csvRows.push(lap.columns.join(","));
                csvRows.push(...lap.values.map(x => x.join(',')));
                download(`${safeName}.laps.csv`, csvRows.join('\n'));
            }
        } finally {
            setCsvLoading(false);
        }
    }

    const onApiFormat = async (format: ApiFormat) => {
        try {
            setApiLoadings(x => ({...x, [format]: true}));

            let trainingIndexes = undefined;
            if (onlySelectedTrainings && selectedTrainings) {
                trainingIndexes = [];
                for (let i = 0; i < training.trainings.length; i++) {
                    const tct = training.trainings[i];
                    if (selectedTrainings.includes(tct))
                        trainingIndexes.push(i);
                }
            }
            const apiSlice = doSlice && slice ? [slice.startMs, slice.endMs] : undefined;
            const filenameDate = moment(training.startAt).format("YYYY-MM-DD_HH-mm");
            const data = await Api.exportTrainingCommon(training.id, format, filenameDate, apiSlice, trainingIndexes);
            const name = getTrainingFullName(training, translate);
            const safeName = sanitize(name).replace(/[\s]/g, "_");

            const extension = (trainingIndexes ?? []).length > 1 ? "zip" : format;
            downloadBinary(`${safeName}.${extension}`, data);
        } finally {
            setApiLoadings(x => ({...x, [format]: false}));
        }
    }

    return (
        <DialogShim
            title={translate('export dialog title')}
            onClose={onDismiss}
        >
            <SizedBox height={20}/>
            <div className={styles.grid}>
                <Button
                    label={translate("export session json")}
                    onClick={onJson}
                    loading={jsonLoading}
                />
                <div className={styles.description}>
                    {translate("export session json description")}
                </div>
                <Button
                    label={translate("export session csv")}
                    onClick={onCsv}
                    loading={csvLoading}
                />
                <div className={styles.description}>
                    {translate("export session csv description")}
                </div>
                {apiFormats.map(format =>
                    <div key={format} style={{display: "contents"}}>
                        <Button
                            label={translate("export session format", {format: format.toUpperCase()})}
                            onClick={() => onApiFormat(format)}
                            loading={apiLoadings[format]}
                        />
                        <div className={styles.description}>
                            {translate("export session format description", {format: format.toUpperCase()})}
                        </div>
                    </div>)}
            </div>
            <div className={styles.toggleContainer}>
                <SizedBox height={16}/>
                <Toggle
                    label={translate("only the selected section")}
                    onToggle={() => setDoSlice(!doSlice)}
                    value={doSlice}
                    disabled={slice === null}
                />
            </div>
            {training.trainings.length > 1 && <div className={styles.toggleContainer}>
                <SizedBox height={16}/>
                <Toggle
                    label={translate("only the selected athletes")}
                    onToggle={() => setOnlySelectedTrainings(x => !x)}
                    value={onlySelectedTrainings}
                    disabled={selectedTrainings === null}
                />
            </div>}
        </DialogShim>
    );
};

import styles from "./AthleteToggle.module.scss";
import Icon from "@mdi/react";
import {mdiAccount} from "@mdi/js";
import {forceSeriesColors} from "../StandardGraphs/StandardGraphs";
import {isTwoPaddle, TrainingSport} from "../../../../typings/TrainingSport";
import {useTranslation} from "react-i18next";
import {IAthlete} from "../../../../typings/IAthlete";

interface IAthleteToggle {
    isSelected: boolean;
    onClick: () => void;
    index?: number | undefined;
    sport: TrainingSport;
    athlete: IAthlete;
    shortName: boolean;
}

export const getAthleteDisplayName = (athlete: IAthlete, short: boolean) => {
    return short ? `${athlete.firstName[0]}${athlete.lastName[0] || ""}`
        : `${athlete.firstName} ${athlete.lastName || ""}`;
}

export const AthleteToggle = ({isSelected, onClick, index, sport, athlete, shortName}: IAthleteToggle) => {
    const {t} = useTranslation();
    let displayName;
    if (athlete)
        displayName = getAthleteDisplayName(athlete, shortName);

    return <div className={`${styles.athlete} ${isSelected ? styles.selected : ""}
    ${isSelected && index === undefined ? styles.selected2 : ""}`}
                onClick={onClick}>
        <div className={styles.athleteInner}>
            {athlete && <>
                {athlete.profilePictureUrl && <img className={styles.profilePicture} alt=""
                                                   src={athlete.profilePictureUrl}/>}
                <span
                    className={styles.athleteName}>{displayName}</span>
            </>}
            {!athlete && <>
                <Icon className={styles.profilePicture} color={"#000"} path={mdiAccount}/>
                {t("guest")}
            </>}
        </div>

        {index !== undefined && <div
            className={`${styles.color} ${styles.left}`}
            style={{
                backgroundColor: isSelected ?
                    forceSeriesColors[(index * (isTwoPaddle(sport) ? 2 : 1))
                    % forceSeriesColors.length] : "transparent"
            }}>
        </div>}
        {index !== undefined && isTwoPaddle(sport) && <div
            className={`${styles.color} ${styles.right}`}
            style={{
                backgroundColor: isSelected ?
                    forceSeriesColors[(index * 2 + 1) % forceSeriesColors.length] : "transparent"
            }}>
        </div>}
    </div>
}
import {useCallback, useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GroupByPeriod} from '../../../typings/GroupByPeriod';
import {TimeInterval} from '../../../typings/TimeInterval';
import {Dropdown} from '../../base/Dropdown/Dropdown';
import {DropdownLabel} from '../../base/DropdownLabel/DropdownLabel';
import {SelectItem} from '../../base/SelectItem/SelectItem';
import styles from './GranularitySelector.module.css';
import StatisticsContext from "../../../contexts/StatisticsContext";

interface IGranularitySelector {
    className?: string;
}

export const GranularitySelector = ({ className }: IGranularitySelector) => {
    const { t } = useTranslation();
    const { granularity, setGranularity, timeInterval } = useContext(StatisticsContext);
    const granularitySelector = useRef<HTMLDivElement>(null);
    const [granularitySelectorOpen, setGranularitySelectorOpen] = useState<boolean>(false);

    const onGranularitySelectorClick = useCallback((granularity: GroupByPeriod) => {
        setGranularity(granularity);
        setGranularitySelectorOpen(false);
    }, [setGranularity])

    return (
        <div
            className={`${styles.container} ${className ? className : ''}`}
            ref={granularitySelector}
            onClick={granularitySelectorOpen ? undefined : () => setGranularitySelectorOpen(true)}
        >
            <DropdownLabel
                label={t(granularity)}
                open={granularitySelectorOpen}
            />
            {granularitySelectorOpen &&
                <Dropdown anchor={granularitySelector} setOpen={setGranularitySelectorOpen}>
                    <SelectItem
                        label={t("days")}
                        onClick={() => onGranularitySelectorClick(GroupByPeriod.Days)}
                    />
                    {timeInterval !== TimeInterval.Week &&
                        <SelectItem
                            label={t("weeks")}
                            onClick={() => onGranularitySelectorClick(GroupByPeriod.Weeks)}
                        />
                    }
                    {timeInterval === TimeInterval.Year &&
                        <SelectItem
                            label={t("months")}
                            onClick={() => onGranularitySelectorClick(GroupByPeriod.Months)}
                        />
                    }
                </Dropdown>
            }
        </div>
    );
};

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import { Button } from '../../base/Button/Button';
import DialogShim from '../DialogShim';
import styles from './YesCancelDialog.module.css';

interface IYesCancelDialog {
    dialogKey?: string;
    onYes: () => any;
    title: string;
    subtitle?: string;
}

export const YesCancelDialog = ({ onYes, title, subtitle, dialogKey: key }: IYesCancelDialog) => {
    const dialogContext = useContext(DialogContext);
    const { t } = useTranslation();
    const onDismiss = () => dialogContext.dismissDialog(key!);
    if (key === undefined) console.warn('Dialog without a key opened!');

    const onYesWrapper = () => {
        onDismiss();
        onYes();
    }

    return (
        <DialogShim
            title={title}
            onClose={onDismiss}
        >
            {subtitle &&
                <div className={styles.subtitle}>{subtitle}</div>
            }
            <div className={styles.buttonRow}>
                <Button
                    label={t('cancel')}
                    secondary={true}
                    onClick={onDismiss}
                    className={styles.w120}
                />
                <Button
                    label={t('yes')}
                    onClick={onYesWrapper}
                    className={styles.w120}
                />
            </div>
        </DialogShim>
    );
};

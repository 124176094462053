import React, { createContext, useCallback, useEffect, useState } from "react";

export enum ScreenType {
    fullhd = 1600,
    widescreen = 1200,
    desktop = 1024,
    tablet = 772,
    mobile = 0,
}

const ResponsiveContext = createContext({
    screenType: ScreenType.fullhd,
    isMobile: false,
    chartWidth: 1224,
    windowSize: [window.innerWidth, window.innerHeight],

    fullScreen: false,
    setFullScreen: (val: boolean) => { },
});

export const ResponsiveContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [screenType, _setScreenType] = useState<ScreenType>(ScreenType.fullhd);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [chartWidth, setChartWidth] = useState<number>(1024);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const [windowSize, setWindowSize] = useState<number[]>([window.innerWidth, window.innerHeight]);

    const setScreenType = useCallback((type: ScreenType) => {
        if (type === ScreenType.mobile) setIsMobile(true);
        else setIsMobile(false);
        _setScreenType(type);
    }, []);

    useEffect(() => {
        if (fullScreen) setChartWidth(width);
        else setChartWidth(width > 1224 ? 1224 : width);
    }, [fullScreen, width]);

    useEffect(() => {
        const onResize = () => {
            const width = document.documentElement.clientWidth;
            if (width < ScreenType.tablet) {
                setScreenType(ScreenType.mobile);
            } else if (width < ScreenType.desktop) {
                setScreenType(ScreenType.tablet);
            } else if (width < ScreenType.widescreen) {
                setScreenType(ScreenType.desktop);
            } else if (width < ScreenType.fullhd) {
                setScreenType(ScreenType.widescreen);
            } else {
                setScreenType(ScreenType.fullhd);
            }

            setChartWidth(() => {
                return width > 1224 ? 1224 : width;
            });

            setWidth(width);

            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        onResize();
        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [setScreenType]);

    return (
        <ResponsiveContext.Provider value={{
            screenType,
            isMobile,
            chartWidth,
            windowSize,

            fullScreen,
            setFullScreen,
        }}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export default ResponsiveContext;

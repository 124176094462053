import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Formatter} from '../../../utils/formatter';
import {GlanceIndicatorSmall} from '../GlanceIndicatorSmall/GlanceIndicatorSmall';
import styles from './GlanceStatsSmall.module.css';
import {isRowing, TrainingSport} from "../../../typings/TrainingSport";
import {speedToTempo500} from "../../../utils/unitHelpers";
import Highcharts from "highcharts";

interface IStatsGlanceSmall {
    avgPaddlingForceN?: number;
    maxPaddlingForceN?: number;
    avgStrokeRateSpm: number | null;
    maxStrokeRateSpm: number | null;
    lengthKm: number;
    startAt?: Date;
    endAt?: Date;
    avgSpeedKph: number | null;
    maxSpeedKph: number | null;
    avgHeartRateBpm?: number;
    maxHeartRateBpm?: number;
    sport: TrainingSport
}

export enum SmallStatCategory {
    Force,
    StrokeRate,
    Distance,
    Duration,
    Speed,
    HeartRate
}

export const getStatIcon = (cat: SmallStatCategory) => {
    switch (cat) {
        case SmallStatCategory.Force:
            return '/icons/ic_paddlingforce.png';
        case SmallStatCategory.StrokeRate:
            return '/icons/ic_strokes.png';
        case SmallStatCategory.Distance:
            return '/icons/ic_distance.png';
        case SmallStatCategory.Duration:
            return '/icons/ic_time.png';
        case SmallStatCategory.Speed:
            return '/icons/ic_speed.png';
        case SmallStatCategory.HeartRate:
            return '/icons/ic_heartrate.png';
    }
}

export const StatsGlanceSmall = ({
                                     avgPaddlingForceN,
                                     maxPaddlingForceN,
                                     avgStrokeRateSpm,
                                     maxStrokeRateSpm,
                                     lengthKm,
                                     startAt,
                                     endAt,
                                     avgSpeedKph,
                                     maxSpeedKph,
                                     avgHeartRateBpm,
                                     maxHeartRateBpm,
                                     sport,
                                 }: IStatsGlanceSmall) => {
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Force)}
                text={t('avg pulling force')}
                value={avgPaddlingForceN === undefined ? "" : `${avgPaddlingForceN.toFixed(0).replace('-0', '0')} N`}
                unavailable={avgPaddlingForceN === undefined}
                smallValue={maxPaddlingForceN === undefined || maxPaddlingForceN === -Infinity ? '' : `${t('max')} ${maxPaddlingForceN.toFixed(0).replace('-0', '0')} N`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.StrokeRate)}
                text={t('avg stroke rate')}
                value={avgStrokeRateSpm == null ? "" : `${avgStrokeRateSpm.toFixed(1)} / min`}
                unavailable={avgStrokeRateSpm === undefined}
                smallValue={!maxStrokeRateSpm ? '' : `${t('max')} ${maxStrokeRateSpm.toFixed(1)} / min`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Distance)}
                text={t('distance')}
                unavailable={lengthKm === null}
                value={`${lengthKm == null ? '' : lengthKm.toFixed(1)} km`}
            />
            <GlanceIndicatorSmall
                icon={getStatIcon(SmallStatCategory.Duration)}
                text={t('duration')}
                value={(startAt || endAt) === undefined ? '' : Formatter.secToTimeString(moment(endAt).diff(moment(startAt), 'seconds'))}
            />
            {isRowing(sport) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.Speed)}
                    text={t('avg tempo split')}
                    value={avgSpeedKph == null ? '' : `${Highcharts.dateFormat("%M:%S", speedToTempo500(avgSpeedKph) * 60 * 1000)} /500m`}
                    smallValue={maxSpeedKph == null ? '' : `${t('max')} ${Highcharts.dateFormat("%M:%S", speedToTempo500(maxSpeedKph))} /500m`}
                />}
            {!isRowing(sport) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.Speed)}
                    text={t('avg speed')}
                    value={avgSpeedKph == null ? '' : `${avgSpeedKph.toFixed(1)} km/h`}
                    smallValue={maxSpeedKph == null ? '' : `${t('max')} ${maxSpeedKph.toFixed(1)} km/h`}
                />}
            {(avgHeartRateBpm === undefined || isNaN(avgHeartRateBpm) || avgHeartRateBpm === 0) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.HeartRate)}
                    text={t('avg heart rate')}
                    value={''}
                    unavailable={true}
                />
            }
            {!(avgHeartRateBpm === undefined || isNaN(avgHeartRateBpm) || avgHeartRateBpm === 0) &&
                <GlanceIndicatorSmall
                    icon={getStatIcon(SmallStatCategory.HeartRate)}
                    text={t('avg heart rate')}
                    value={avgHeartRateBpm === undefined ? '' : `${avgHeartRateBpm.toFixed(1)} bpm`}
                    smallValue={maxHeartRateBpm === undefined ? '' : `${t('max')} ${maxHeartRateBpm.toFixed(1)} bpm`}
                />
            }
        </div>
    );
};

import React, {createContext, useState} from "react";

const LiveContext = createContext({
    live: false, setLive: (x: boolean) => {
    }
});

export const LiveContextProvider = ({children}: { children: React.ReactNode }) => {
    const [live, setLive] = useState(false);

    return (
        <LiveContext.Provider value={{
            live, setLive
        }}>
            {children}
        </LiveContext.Provider>
    );
};

export default LiveContext;

import {ClipLoader} from "react-spinners";
import {CSSProperties} from "react";

interface ISpinner {
    size?: number;
    cssOverride?: CSSProperties;
}

export const Spinner = ({ size, cssOverride }: ISpinner) => {
    return (
        <ClipLoader
            color="inherit"
            cssOverride={cssOverride}
            size={size ?? 40}
            aria-label="Loading Spinner"
        />
    );
};

import React, {ReactNode, useContext} from "react";
import {Footer} from "../../paddlemate/Footer/Footer";
import {DashboardHeader} from "./DashboardHeader/DashboardHeader";
import styles from "./DashboardLayout.module.css";
import LiveContext from "../../../contexts/LiveContext";

interface IDashboardLayout {
    children?: ReactNode;
    fullScreen?: boolean;
    scrollable?: boolean;
}

const DashboardLayout = ({children, scrollable = true, fullScreen = false}: IDashboardLayout) => {
    const {live} = useContext(LiveContext);
    return (
        <div className={!fullScreen ? styles.container : styles.containerFullScreen}>
            {!live && !fullScreen && <DashboardHeader/>}
            {scrollable && <div className={`${!fullScreen ? styles.overflowContainer : styles.overflowContainerFullScreen} overflowContainer`}>
                <div className={!fullScreen ? styles.contentContainer : styles.contentContainerFullScreen}>
                    {children}
                </div>
                {!live && !fullScreen && <Footer/>}
            </div>}
            {!scrollable &&
                <div className={styles.contentContainerNotScrollable}>
                    {children}
                </div>}
        </div>
    );
};

export default DashboardLayout;

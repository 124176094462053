import moment from "moment";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {GroupByPeriod} from "../typings/GroupByPeriod";
import {TimeInterval} from "../typings/TimeInterval";
import {
    createEnumParser,
    DATE_QUERY_PARAM,
    GRANULARITY_QUERY_PARAM,
    parseDateQuery,
    serializeDateQuery,
    serializeEnum,
    STAT_CATEGORY_QUERY_PARAM,
    TIME_INTERVAL_QUERY_PARAM,
    useQuery
} from "../hooks/useQuery";
import {StatCategory} from "../typings/StatCategory";

interface IStatisticsContext {
    timeInterval: TimeInterval;
    date: Date | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    granularity: GroupByPeriod;
    statCategory: StatCategory;

    setTimeInterval: (interval: TimeInterval) => void;
    setDate: (date: Date) => void;
    setGranularity: (granularity: GroupByPeriod) => void;
    loadCached: () => void;
    setStatCategory: (statCategory: StatCategory) => void;
}

const dv: IStatisticsContext = {
    timeInterval: TimeInterval.Week,
    date: moment().startOf("isoWeek").toDate(),
    startDate: undefined,
    endDate: undefined,
    granularity: GroupByPeriod.Days,
    statCategory: StatCategory.Sessions,

    setTimeInterval: () => {
    },
    setDate: () => {
    },
    setGranularity: () => {
    },
    loadCached: () => {
    },
    setStatCategory: () => {
    },
};

export const StatisticsContext = React.createContext(dv);

export const StatisticsContextProvider = ({children}: { children: React.ReactNode }) => {
    const [cachedDate, setCachedDate] =
        useState<Date>(moment().toDate());
    const {value: date, update: setDate} = useQuery(
        DATE_QUERY_PARAM, parseDateQuery, serializeDateQuery
    );
    useEffect(() => {
        if (date !== undefined) {
            setCachedDate(date);
        }
    }, [date]);

    const [cachedTimeInterval, setCachedTimeInterval] =
        useState<TimeInterval>(dv.timeInterval);
    const {value: timeInterval, update: setTimeInterval} = useQuery(
        TIME_INTERVAL_QUERY_PARAM, createEnumParser<TimeInterval>(TimeInterval), serializeEnum
    );
    useEffect(() => {
        if (cachedTimeInterval !== undefined) {
            setCachedTimeInterval(cachedTimeInterval);
        }
    }, [cachedTimeInterval]);
    const timeIntervalOrDefault = timeInterval || dv.timeInterval;

    const [cachedGranularity, setCachedGranularity] =
        useState<GroupByPeriod>(dv.granularity);
    const {value: granularity, update: setGranularity} = useQuery(
        GRANULARITY_QUERY_PARAM, createEnumParser<GroupByPeriod>(GroupByPeriod), serializeEnum
    );
    useEffect(() => {
        if (cachedGranularity !== undefined) {
            setCachedGranularity(cachedGranularity);
        }
    }, [cachedGranularity]);
    const granularityOrDefault = granularity || dv.granularity;

    const [cachedStatCategory, setCachedStatCategory] =
        useState<StatCategory>(dv.statCategory);
    const {value: statCategory, update: setStatCategory} = useQuery(
        STAT_CATEGORY_QUERY_PARAM, createEnumParser<StatCategory>(StatCategory), serializeEnum
    );
    useEffect(() => {
        if (statCategory !== undefined) {
            setCachedStatCategory(statCategory);
        }
    }, [statCategory]);

    const loadCached = useCallback(() => {
        if (date === undefined) {
            if (cachedDate !== undefined) {
                setDate(cachedDate);
            }
        }
        if (timeInterval === undefined) {
            if (cachedTimeInterval !== undefined) {
                setTimeInterval(cachedTimeInterval);
            }
        }
        if (granularity === undefined) {
            if (cachedGranularity !== undefined) {
                setGranularity(cachedGranularity);
            }
        }
        if (statCategory === undefined) {
            if (cachedStatCategory !== undefined) {
                setStatCategory(cachedStatCategory);
            }
        }
    }, [date, timeInterval, granularity, statCategory, cachedDate, setDate,
        cachedTimeInterval, setTimeInterval, cachedGranularity, setGranularity,
        cachedStatCategory, setStatCategory]);

    const momentTimeInterval = useMemo(() =>
        timeIntervalOrDefault === TimeInterval.Week ?
        "isoWeek" : timeIntervalOrDefault, [timeIntervalOrDefault]);

    const startDate = useMemo(() =>
        date && moment(date).startOf(momentTimeInterval).toDate(), [momentTimeInterval, date]);

    const endDate = useMemo(() =>
        date && moment(date).endOf(momentTimeInterval).toDate(), [momentTimeInterval, date]);

    const setGranularityWrapper = useCallback((granularity: GroupByPeriod) => {
        switch (timeIntervalOrDefault) {
            case TimeInterval.Week:  // Only 1 valid option for week timeInterval
                setGranularity(GroupByPeriod.Days);
                break;
            case TimeInterval.Month:
                if (granularity === GroupByPeriod.Weeks) {
                    setGranularity(GroupByPeriod.Weeks);
                } else {
                    setGranularity(GroupByPeriod.Days);
                }
                break;
            case TimeInterval.Year:
                if (granularity === GroupByPeriod.Days) {
                    setGranularity(GroupByPeriod.Days);
                } else if (granularity === GroupByPeriod.Weeks) {
                    setGranularity(GroupByPeriod.Weeks);
                } else {
                    setGranularity(GroupByPeriod.Months);
                }
                break;
        }
    }, [setGranularity, timeIntervalOrDefault]);

    return (
        <StatisticsContext.Provider value={{
            timeInterval: timeIntervalOrDefault,
            date,
            startDate,
            endDate,
            granularity: granularityOrDefault,
            statCategory: statCategory || dv.statCategory,

            setTimeInterval,
            setDate,
            setGranularity: setGranularityWrapper,
            loadCached,
            setStatCategory
        }}>
            {children}
        </StatisticsContext.Provider>
    );
};

export default StatisticsContext;

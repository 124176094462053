import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import { Button } from '../../base/Button/Button';
import { SizedBox } from '../../base/SizedBox/SizedBox';
import { TextInput } from '../../base/TextInput/TextInput';
import DialogShim from '../DialogShim';
import styles from './CoachingRequestDialog.module.css';

interface ICoachingRequestDialog {
    dialogKey?: string;
    onSend: (email: string) => Promise<boolean>;
}

export const CoachingRequestDialog = ({ dialogKey: key, onSend }: ICoachingRequestDialog) => {
    const dialogContext = useContext(DialogContext);
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const onDismiss = () => dialogContext.dismissDialog(key!);
    if (key === undefined) console.warn('Dialog without a key opened!');

    const onSendWrapper = async () => {
        if (loading) return;
        setLoading(true);
        await onSend(email);
        onDismiss();
    }

    return (
        <DialogShim
            title={t('send coaching request')}
            onClose={onDismiss}
        >
            <SizedBox height={10} />
            <TextInput
                label={t('athlete email address')}
                value={email}
                type="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
            />
            <div className={styles.buttonRow}>
                {!loading &&
                    <Button
                        label={t('cancel')}
                        onClick={onDismiss}
                        secondary={true}
                        className={styles.w120}
                    />
                }
                {loading &&
                    <div />
                }
                <Button
                    label={!loading ? t('send invitation') : t('sending request')}
                    onClick={!loading ? onSendWrapper : () => { }}
                    className={styles.w120}
                />
            </div>
        </DialogShim>
    );
};

import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Input} from "reactstrap";

export const DecimalNumberInput = ({value, style, onFocus, onBlur, onChange, onError, invalid}: any) => {
    const format = useCallback((x: number | null) => x === null ? "" : String(x), []);
    const [innerValue, setInnerValue] = useState(format(value));
    useEffect(() => {
        setInnerValue(format(value));
    }, [format, value]);
    const onChangeInner = useCallback((v: string, manual: boolean) => {
        if (v === "") {
            onError("empty");
        } else { // check format
            if (/^[0-9]{0,7}[.,]?[0-9]{1,4}$/.exec(v)) {
                onError(null);
                if (manual)
                    onChange(parseFloat(v.replace(',', '.')));
            } else {
                onError("format");
            }
        }
    }, [onChange, onError]);
    useEffect(() => {
        onChangeInner(innerValue, false);
    }, [innerValue, onChangeInner]);
    const handleValueChange = (e: any) => {
        const v = e.target.value;
        setInnerValue(v);
        onChangeInner(v, true);
    };
    return <Input type={"text"}
                  value={innerValue}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  style={style}
                  onChange={handleValueChange}
                  placeholder={"X.X"}
                  invalid={invalid}/>
}
import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {max, min} from "../../../../utils/arrayUtils";
import {forceSeriesColors, zoneWidth} from "../StandardGraphs/StandardGraphs";
import {isTwoPaddle, TrainingSport} from "../../../../typings/TrainingSport";
import {UPlot} from "../../../uplot/UPlot";
import uPlot, {Axis, Cursor} from "uplot";
import {useResizeDetector} from "react-resize-detector";
import {autoUpdate, Boundary, flip, offset, useFloating} from "@floating-ui/react";
import {TooltipChangedListener, TooltipPlugin} from "../../../uplot/TooltipPlugin";
import {TouchZoomPlugin} from "../../../uplot/TouchZoomPlugin";
import {WheelPanPlugin} from "../../../uplot/WheelPanPlugin";
import styles from "./DetailedPaddleForceGraph.module.scss";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiFullscreen, mdiMinusCircleOutline, mdiPlusCircleOutline} from "@mdi/js";
import {ChartTooltipContent} from "../ChartTooltipContent/ChartTooltipContent";
import {Tooltip, TooltipContent, TooltipTrigger} from "../../../base/Tooltip/Tooltip";
import {InfoTooltipButton} from "../../InfoTooltipButton/InfoTooltipButton";
import {InfoTooltip} from "../../InfoTooltip/InfoTooltip";
import * as React from "react";
import {PlotLinesPlugin} from "../../../uplot/PlotLinesPlugin";


interface IDetailedPaddleForceGraph {
    leftForces: (number[] | null)[];
    rightForces: (number[] | null)[];
    forceMin: number,
    forceMax: number,
    distanceSeries: number[][] | null;
    bandStart: number | null,
    bandEnd: number | null,
    plotLines: number[],
    zoomStart: number,
    zoomEnd: number,
    maxX: number;
    sport: TrainingSport;
    hasZones: boolean;
    isTracker: boolean;
    cursor: MutableRefObject<number>;
    showCrosshair: boolean;
    isMultiAthlete: boolean;
    onZoomChange: (min: number, max: number) => void;
    onCursorChange: (cursor: number) => void;
    onMouseOver: (over: boolean) => void;
    onFullscreenClick: () => void;
}

const detailedChartHeight = 300;
const chartPaddingTop = 10;

export const DetailedPaddleForceGraph = ({
                                             leftForces,
                                             rightForces,
                                             forceMin,
                                             forceMax,
                                             distanceSeries,
                                             bandStart,
                                             bandEnd,
                                             plotLines,
                                             zoomStart,
                                             zoomEnd,
                                             maxX,
                                             sport,
                                             hasZones,
                                             cursor,
                                             isTracker,
                                             showCrosshair,
                                             isMultiAthlete,
                                             onZoomChange,
                                             onCursorChange,
                                             onMouseOver,
                                             onFullscreenClick
                                         }: IDetailedPaddleForceGraph) => {
    const {t} = useTranslation();
    const uPlot = useRef<uPlot>();

    const series = useMemo(() =>
        leftForces.map((_, i) => {
            return [
                {
                    data: leftForces[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1)) % forceSeriesColors.length]
                },
                {
                    data: rightForces[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1) + 1) % forceSeriesColors.length]
                }
            ];
        }).flat().filter(x => x.data), [leftForces, rightForces, sport]);
    const leftForcesSelection = leftForces.filter(x => x != null)
        .map(x => x!.slice(Math.floor(zoomStart / 10), Math.min(Math.ceil(zoomEnd / 10), x!.length - 1))) as number[][];
    const rightForcesSelection = rightForces.filter(x => x != null)
        .map(x => x!.slice(Math.floor(zoomStart / 10), Math.min(Math.ceil(zoomEnd / 10), x!.length - 1))) as number[][];
    const hasLeftRight = leftForcesSelection.length > 0 && rightForcesSelection.length > 0;

    const minMaxAvgZoom = useMemo(() => {
        const leftRightNonNull = [leftForcesSelection, rightForcesSelection];
        const minMaxes = leftRightNonNull
            .map(x => x.map(forces => {
                return forces
                    .reduce((res, force) => {
                        res.sum += force
                        if (res.min > force)
                            res.min = force;
                        else if (res.max < force)
                            res.max = force;
                        return res;
                    }, {min: Infinity, max: -Infinity, sum: 0});
            }));
        const minMaxAvgs = minMaxes.map((minMax, i) => {
            const minMaxSum = minMax.reduce((acc, x) => {
                return acc + x.sum;
            }, 0);
            const minMaxLength = leftRightNonNull[i].reduce((acc, x) => {
                return acc + x.length;
            }, 0);
            return ({
                min: min(minMax.map(x => x.min)),
                max: max(minMax.map(x => x.max)),
                avg: minMaxSum / minMaxLength,
            });
        });
        return {left: minMaxAvgs[0], right: minMaxAvgs[1]};
    }, [leftForcesSelection, rightForcesSelection]);

    const [uPlotBoundary, setUPlotBoundary] = useState<Boundary>();

    const {refs, floatingStyles} = useFloating({
        placement: "right-end",
        whileElementsMounted: autoUpdate,
        middleware: [offset(30), flip({boundary: uPlotBoundary})],
    });

    const [tooltipValue, setTooltipValue]
        = useState<{ x: number, ys: (number | null | undefined)[] } | null>(null);
    const handleTooltipChanged = useCallback<TooltipChangedListener>(
        (x, ys, px, py) => {
            refs.setReference({
                getBoundingClientRect: () =>
                    ({
                        x: px,
                        y: py + 20,
                        top: py + 20,
                        left: px,
                        bottom: py + 20,
                        right: px,
                        width: 0,
                        height: 0,
                    }),
            });
            setTooltipValue({x, ys});
        }, [refs]);
    const handleTooltipVisibleChanged = useCallback(
        (value: boolean) => {
            onMouseOver(value);
        }, [onMouseOver]);
    const {width, ref: sizeDetectorRef} = useResizeDetector();

    const data = useMemo<[
        xValues: number[],
        ...yValues: number[][],
    ]>(() => [
        [...Array(series[0].data!!.length).keys()].map((x, i) => i * 10), // 100 hz is the frequency
        ...series.map(x => x.data!!)
    ], [series]);
    const zoomingWithTouch = useRef<boolean>(false);

    const callOnSelectionChange = useCallback(() => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            let min = u.scales.x.min;
            let max = Math.min(u.scales.x.max, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }, [onZoomChange, maxX]);

    const zoomFactor = 0.7;
    const zoomIn = () => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            const range = u.scales.x.max - u.scales.x.min;
            const newRange = range * zoomFactor;
            const rangeDiff = range - newRange;
            let min = Math.max(0, u.scales.x.min + rangeDiff / 2);
            let max = Math.min(u.scales.x.max - rangeDiff / 2, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }
    const resetZoom = () => {
        if (!uPlot.current) return;
        onZoomChange(0, maxX);
    }

    const zoomOut = () => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            const range = u.scales.x.max - u.scales.x.min;
            const newRange = range / zoomFactor;
            const rangeDiff = newRange - range;
            let min = Math.max(0, u.scales.x.min - rangeDiff / 2);
            let max = Math.min(u.scales.x.max + rangeDiff / 2, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }

    const handleSetScale = useCallback((u: uPlot, scaleKey: string) => {
        if (!zoomingWithTouch.current) // we only call callOnSelectionChange after the touch zooming is over, to prevent reinitializing the chart
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleDrawBands = useCallback((u: uPlot) => { // draw bands with custom renderer
        if (bandStart == null || bandEnd == null) return;
        const min = u.scales.x.min ?? 0;
        const max = u.scales.x.max ?? 0;
        // do not draw out of range
        if ((bandStart < min && bandEnd < min) || (bandStart > max && bandEnd > max))
            return;
        let {ctx} = u;
        const height = u.bbox.height - (u.axes[0].ticks?.size ?? 0);

        ctx.save();

        ctx.fillStyle = "rgba(140,140,140,0.07)";

        const clampedBandStart = (bandStart < min && bandEnd > min) ? min : bandStart;
        const clampedBandEnd = (bandEnd > max && bandEnd < max) ? max : bandEnd;
        const startPx = Math.round(u.valToPos(clampedBandStart, "x", true));
        const endPx = Math.round(u.valToPos(clampedBandEnd, "x", true));
        ctx.fillRect(startPx, chartPaddingTop, endPx - startPx, chartPaddingTop + height);

        ctx.restore();
    }, [bandEnd, bandStart]);

    const xSpaceFn = useCallback<(self: uPlot, axisIdx: number, scaleMin: number, scaleMax: number, plotDim: number) => number>
    ((self, axisIdx, scaleMin, scaleMax, dim) => {
        const range = scaleMax - scaleMin;
        return range < 4000 ? 70 : 50;
    }, []);

    const xValuesFn = useCallback<Axis.DynamicValues>
    ((self, ticks, space) => ticks.map(tick => {
        const index = Math.floor(tick / 1000);
        const distance = distanceSeries?.[index]?.[1]?.toFixed(1);
        const range = ticks[ticks.length - 1] - ticks[0];
        let str;
        if (range < 4000) {
            str = String(moment(tick).utc().format("H:mm:ss.SSS"));
        } else {
            str = String(moment(tick).utc().format("H:mm:ss"));
        }
        if (distance)
            str += `\n${distance} km`;
        return str;
    }), [distanceSeries]);

    const handleCursorMove = useCallback<Cursor.MousePosRefiner>((u, left, top) => {
        const pos = u.valToPos(data[0][u.posToIdx(left)], "x");
        return [pos, top];
    }, [data]);

    const handleZoomingChanged = useCallback((value: boolean) => {
        zoomingWithTouch.current = value;
        if (!value)
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleCursorChanged = useCallback((u: uPlot) => {
        if (u.cursor.left !== undefined)
            onCursorChange(Math.floor(u.posToVal(u.cursor.left, "x")))
    }, [onCursorChange]);

    useEffect(() => {
        const t = window.setInterval(() => {
            if (!uPlot.current) return;
            uPlot.current.setCursor({left: uPlot.current.valToPos(cursor.current, "x"), top: 0});
        }, 200);
        return () => clearInterval(t);
    }, [cursor]);

    const options = useMemo<uPlot.Options>(() => {
        return ({
            width: width ?? 1224,
            height: detailedChartHeight,
            padding: [chartPaddingTop, 10, 0, 0],
            hooks: {
                setScale: [handleSetScale],
                drawAxes: [handleDrawBands],
                setCursor: [handleCursorChanged]
            },
            series: [
                {
                    label: "X"
                },
                ...series.map(s => ({
                    stroke: s.color
                }))
            ],
            scales: {
                "x": {
                    time: false,
                    min: zoomStart,
                    max: zoomEnd
                },
                "y": {
                    range: [Math.max(-50, forceMin), forceMax + 5],
                }
            },
            axes: [
                {
                    grid: {show: false},
                    ticks: {
                        show: true,
                        stroke: "#000000",
                        width: 1
                    },
                    space: xSpaceFn,
                    values: xValuesFn,
                },
                {
                    ticks: {
                        show: false,
                    },
                    grid: {width: 1},
                    stroke: "rgb(102, 102, 102)",
                    label: t("detailed force"),
                    labelFont: "13px Helvetica, Arial, sans-serif",
                    labelSize: 30
                },
            ],
            legend: {
                show: false
            },
            cursor: {
                show: true,
                y: false,
                move: handleCursorMove
            },
            plugins: [
                new TouchZoomPlugin(handleZoomingChanged, maxX),
                new TooltipPlugin(handleTooltipChanged, handleTooltipVisibleChanged),
                new WheelPanPlugin(maxX),
                new PlotLinesPlugin(plotLines)
            ]
        });
    }, [width, handleSetScale, handleDrawBands, handleCursorChanged, series, zoomStart,
        zoomEnd, forceMin, forceMax, xSpaceFn, xValuesFn, t, handleCursorMove,
        handleZoomingChanged, maxX, handleTooltipChanged, handleTooltipVisibleChanged, plotLines]);

    const handleRefChanged = useCallback((e: any) => {
        sizeDetectorRef(e);
        if (e)
            setUPlotBoundary(e);
    }, [sizeDetectorRef]);

    const tooltipDistance = distanceSeries?.[Math.floor((tooltipValue?.x ?? 0) / (isTracker ? 100 : 1000))]
        ?.[1]

    if (series.length === 0)
        return null;
    return <>
        <div
            className={styles.container}
        >
            <div style={{flex: 1, display: "flex", flexDirection: "column", minWidth: 0, position: "relative"}}>
                <div ref={handleRefChanged}>
                    <UPlot options={options}
                           data={data}
                           uPlotRef={uPlot}
                    />
                </div>
                <div className={styles.topRight}>
                    <div className={styles.buttons}>
                        <Button size="sm" outline={true} className={styles.zoomButton100}
                                onClick={resetZoom}>
                            {t("reset")}
                        </Button>
                        <Button size="sm" outline={true} className={styles.zoomButton}
                                onClick={zoomIn}>
                            <Icon
                                path={mdiPlusCircleOutline} size={0.8}/>
                        </Button>
                        <Button size="sm" outline={true} className={styles.zoomButton} onClick={zoomOut}>
                            <Icon path={mdiMinusCircleOutline}
                                  size={0.8}/>
                        </Button>
                        <Button size="sm" outline={true} className={styles.fullscreenButton}
                                onClick={onFullscreenClick}>
                            <Icon path={mdiFullscreen}
                                  size={0.8}/>
                        </Button>
                    </div>
                    {leftForcesSelection.length > 0 && <div
                        className={styles.avgMax}>{hasLeftRight && `${t("left")} - `}{t("avg")}&nbsp;
                        {minMaxAvgZoom.left.avg.toFixed(0).replace('-0', '0')} N, {t("max")}&nbsp;
                        {minMaxAvgZoom.left.max.toFixed(0).replace('-0', '0')} N
                    </div>}
                    {rightForcesSelection.length > 0 && <div
                        className={styles.avgMax}>{hasLeftRight && `${t("right")} - `}{t("avg")}&nbsp;
                        {minMaxAvgZoom.right.avg.toFixed(0).replace('-0', '0')} N, {t("max")}&nbsp;
                        {minMaxAvgZoom.right.max.toFixed(0).replace('-0', '0')} N
                    </div>}
                </div>
                <div style={{position: "absolute", left: 0, top: 0}}>
                    <Tooltip>
                        <TooltipTrigger>
                            <InfoTooltipButton className={styles.chartInfo}/>
                        </TooltipTrigger>
                        <TooltipContent>
                            <InfoTooltip content={isMultiAthlete ? "info.detailed pulling force chart multi" :
                                (isTwoPaddle(sport) ?
                                    "info.detailed pulling force chart double POD" :
                                    "info.detailed pulling force chart single POD")}/>
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
            {hasZones && <div className={styles.zonesContainer}>
                <div style={{width: zoneWidth, height: 1}}/>
            </div>}
        </div>
        {showCrosshair && <div
            ref={refs.setFloating}
            style={{
                ...floatingStyles,
                pointerEvents: "none",
                background: "white",
                boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.6)",
                borderRadius: 2,
                padding: 8,
                fontFamily: "Helvetica, Arial, sans-serif",
                display: "flex",
                alignItems: "center",
                fontSize: "0.8em",
                zIndex: 10
            }}
        >
            <ChartTooltipContent
                x={tooltipValue?.x ?? 0}
                distance={distanceSeries && distanceSeries.length > 0 && tooltipDistance}
                points={tooltipValue?.ys?.map((x, i) => {
                    return ({
                        color: series[i]?.color,
                        y: x
                    });
                }).filter(x => x.color)}
                valueFormatter={(x: number) => x.toFixed(0).replace('-0', '0')}
                timeFormat={"H:mm:ss.SSS"}
                unit={"N"}
            />
        </div>}
    </>;
};

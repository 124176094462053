import {SizedBox} from '../../base/SizedBox/SizedBox';
import styles from './GlanceIndicatorLive.module.scss';

interface IGlanceIndicatorLive {
    icon: string;
    text: string;
    value: string | undefined;
    unit: string;
}

export const GlanceIndicatorLive = ({icon, text, value, unit}: IGlanceIndicatorLive) => {
    const valueWithUnit = value && `${value} ${unit}`;
    return (
        <div className={value === undefined ? styles.containerUnavailable : styles.container}>
            <img src={icon} className={styles.icon}/>
            <SizedBox width={6}/>
            <div className={styles.col}>
                <div className={styles.value}>{valueWithUnit}</div>
                <div className={styles.text}>{text}</div>
            </div>
        </div>
    );
};

import * as React from 'react';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import {SizedBox} from '../../base/SizedBox/SizedBox';
import DialogShim from '../DialogShim';
import {Api} from "../../../utils/api";
import {Button} from "../../base/Button/Button";
import styles from './ReportProblemDialog.module.css';

interface IReportProblemDialog {
    dialogKey: string;
    trainingId: number;
    onSent: () => void;
}

export const ReportProblemDialog = ({dialogKey: key, trainingId, onSent}: IReportProblemDialog) => {
    const {dismissDialog, showOkDialog} = useContext(DialogContext);
    const [reportedProblem, setReportedProblem] = useState("");
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const onDismiss = () => dismissDialog(key!);
    if (key === undefined) console.warn('Dialog without a key opened!');
    const send = () => {
        setLoading(true);
        Api.updateTrainingCommon(trainingId, {reportedProblem}).then(() => {
            showOkDialog(t("success"), t('report problem dialog success'));
            dismissDialog(key);
            onSent();
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <DialogShim
            title={t('report problem dialog title')}
            onClose={onDismiss}
            style={{
                maxWidth: 600
            }}
        >
            <SizedBox height={20}/>
            {t('report problem dialog content')}
            <br/>
            <textarea
                value={reportedProblem}
                onChange={e => {
                    setReportedProblem(e.target.value.slice(0, 1000));
                }}
                className={styles.input}
            />
            <Button
                className={styles.button}
                label={t("send")}
                onClick={send}
                loading={loading}
            />
        </DialogShim>
    );
};

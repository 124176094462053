import { mdiAccountOutline, mdiLogout } from "@mdi/js";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../contexts/UserContext";
import { MenuItem } from "../../../base/MenuItem/MenuItem";
import styles from "./SettingsDropdown.module.css";
import {DropdownMenu, DropdownToggle, Dropdown} from "reactstrap";
import * as React from "react";

export const SettingsDropdown = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, logout, incomingCoachingRequests } = useContext(UserContext);
    const [open, setOpen] = useState(false);

    const cssColor = getComputedStyle(document.documentElement).getPropertyValue('--normal-error-color');

    const onProfile = () => {
        navigate('/profile');
    }

    return <Dropdown isOpen={open} toggle={() => setOpen((prevState) => !prevState)}>
            <DropdownToggle className={styles.container}>
                <img className={styles.profilePicture} alt="" src={user?.profilePictureUrl ?? ""} />
                {(incomingCoachingRequests?.length ?? 0) > 0 &&
                    <div className={styles.indicator}>{incomingCoachingRequests!.length}</div>
                }
            </DropdownToggle>
            <DropdownMenu className={styles.menu}>
                <MenuItem
                    onClick={onProfile}
                    title={t('profile')}
                    icon={mdiAccountOutline}
                />
                <MenuItem
                    onClick={logout}
                    title={t('logout')}
                    icon={mdiLogout}
                    iconColor={cssColor}
                />
            </DropdownMenu>
        </Dropdown>
}

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./LactateChart.module.scss";
import {ChartComp, ChartTooltip} from "../LineChart/LineChart";
import Icon from "@mdi/react";
import {mdiPencil} from "@mdi/js";
import {Button} from "reactstrap";
import {getAthleteDisplayName} from "../AthleteToggle/AthleteToggle";
import Highcharts from "highcharts";
import regression, {DataPoint} from 'regression';
import {useDoubleTap} from "../../../../hooks/useDoubleTap";


const lactateColor = "#dcd800";
export const LactateChart = ({userSeries, athletes, onEditClick}: any) => {
    const {t} = useTranslation();
    const ref = useRef<any>();
    const [boundary, setBoundary] = useState<Element | null>(null);
    const [crosshairRef, setCrosshairRef] = useState<any | null>(null);
    const [selectedPoints, setSelectedPoints] = useState<any[]>([]);
    const [showCrosshair, setShowCrosshair] = useState(false);

    const orderedSamples = useMemo(() => [...userSeries.samples]
            .sort((a: any, b: any) => a.force - b.force),
        [userSeries.samples]);

    const options = useMemo(() => {
        const athlete = athletes[userSeries.trainingIndex];
        const yAxisTitle = `${athlete ? getAthleteDisplayName(athlete, false) : t("guest")}<br/>${t("lactate")}`;
        const yAxis: Highcharts.XAxisOptions[] = [{
            minPadding: 0.06,
            maxPadding: 0.06,
            title: {
                text: yAxisTitle
            }
        }];
        if (orderedSamples[0].hr != null)
            yAxis.push({
                minPadding: 0.06,
                maxPadding: 0.06,
                opposite: true,
                title: {
                    text: t("heartrate")
                }
            });
        return ({
            yAxis: yAxis,
            xAxis: [{
                type: "linear",
                minRange: 1,
                labels: {
                    format: '{text} N'
                },
                minPadding: 0.06,
                maxPadding: 0.06
            }, {
                type: "linear",
                minRange: 1,
                labels: {
                    format: '{text} N'
                },
                minPadding: 0.06,
                maxPadding: 0.06,
                crosshair: false,
                visible: false
            }]
        });
    }, [athletes, orderedSamples, t, userSeries.trainingIndex]);

    const lactateRegressionPredict = useMemo(() => {
        const arr = orderedSamples.map<DataPoint>((x: any) => [x.force, x.value]);
        return regression.polynomial(arr, {order: 3, precision: 20}).predict;
    }, [orderedSamples]);

    const srRegressionPredict = useMemo(() => {
        if (orderedSamples[0].sr == null) return null;
        const arr = orderedSamples.map<DataPoint>((x: any) => [x.force, x.sr]);
        return regression.polynomial(arr, {order: 3, precision: 20}).predict;
    }, [orderedSamples]);

    const hrRegressionPredict = useMemo(() => {
        if (orderedSamples[0].hr == null) return null;
        const arr = orderedSamples.map<DataPoint>((x: any) => [x.force, x.hr]);
        return regression.polynomial(arr, {order: 3, precision: 20}).predict;
    }, [orderedSamples]);

    const units = ["mmol/l"];
    if (hrRegressionPredict != null)
        units.push("bpm");
    if (srRegressionPredict != null)
        units.push("spm");

    const afterDrawCrosshair = useCallback((e: any) => {
        if (e.e.chartX === undefined) return;
        const chart: Highcharts.Chart = e.target.chart;
        //set crosshair ref to position tooltip
        const px = e.point.clientX + chart.container.getBoundingClientRect().left + chart.plotLeft;
        const py = boundary?.getBoundingClientRect()?.top || 0;
        setCrosshairRef({
            getBoundingClientRect: () =>
                ({
                    x: px,
                    y: py + 20,
                    top: py + 20,
                    left: px,
                    bottom: py + 20,
                    right: px,
                    width: 0,
                    height: 0,
                })
        });
        //set selected points for tooltip content
        const xPoint = e.point.x;
        const points = chart.series
            .filter(x => !x.name.startsWith("user")) // hide user lactate from tooltip
            .map((series: any) => {
                // lactate series is sparse, so we iterate over all visible points
                return series.points.reduce((acc: any, y: any) => {
                    const p = {
                        x: y.x,
                        y: y.y,
                        color: y.color,
                        xError: Math.abs(y.x - xPoint)
                    };
                    if (acc === null || acc.xError > p.xError)
                        return p;
                    return acc;
                }, {x: 0, y: 0, xError: Infinity});
            });
        const minErrorPoint = points.reduce((min: any, x: any) => {
            if (min === null || (x.xError !== undefined && min.xError !== undefined && x.xError < min.xError))
                return x;
            return min;
        }, null);
        if (minErrorPoint != null && srRegressionPredict != null) {
            points.push({...minErrorPoint, y: srRegressionPredict(minErrorPoint.x)[1], color: "black"});
        }
        setSelectedPoints(points);
    }, [boundary, srRegressionPredict]);

    const innerSetRef = useCallback((e: any) => {
        ref.current = e;
        setBoundary(e?.container?.current);
    }, []);

    const xResolution = 10;

    const hcSeries = useMemo(() => {
        const regressionSeriesLength = orderedSamples[orderedSamples.length - 1].force - orderedSamples[0].force;
        const regressionXs = [...Array(regressionSeriesLength).keys()].flatMap((i: number) => {
            return [...Array(xResolution).keys()].map((j: number) => {
                    return orderedSamples[0].force + i + j / xResolution;
                }
            );
        });
        // add last point as it's missing from the array
        const lastPoint = orderedSamples[orderedSamples.length - 1].force
        regressionXs.push(lastPoint);
        const res: Highcharts.SeriesOptionsType[] = [{
            type: "line",
            color: lactateColor,
            data: regressionXs.map(x => [x, lactateRegressionPredict(x)[1]]),
            xAxis: 0,
            yAxis: 0
        }, { // lactate points
            data: orderedSamples.map((x: any, i: number) => ({
                x: x.force,
                y: x.value
            })),
            color: lactateColor,
            name: "userLactate",
            type: "line",
            marker: {
                enabled: true,
            },
            lineWidth: 0,
            xAxis: 1,
            yAxis: 0
        }];
        if (hrRegressionPredict) {
            res.push({
                type: "line",
                color: "red",
                data: regressionXs.map(x => [x, hrRegressionPredict(x)[1]]),
                xAxis: 0,
                yAxis: 1
            });
            res.push({ // hr points
                data: orderedSamples.map((x: any, i: number) => ({
                    x: x.force,
                    y: x.hr
                })),
                color: "red",
                name: "userHeartrate",
                type: "line",
                marker: {
                    enabled: true,
                },
                lineWidth: 0,
                xAxis: 1,
                yAxis: 1
            });
        }
        return res;
    }, [hrRegressionPredict, lactateRegressionPredict, orderedSamples]);
    const tooltipFormatter = (x: number, points: any, units: string[]) => {
        return <>
            <div style={{width: "3em"}}>{x} N</div>
            <div style={{marginLeft: 10}}>
                {points?.map((point: any, i: number) => {
                    return <div key={i} style={{color: point.color, fontWeight: 700}}>
                        {(point.y.toFixed(2))} {units[i]}
                    </div>;
                })
                }
            </div>
        </>
    }

    const {onClick} = useDoubleTap(() => {
        ref.current?.chart?.zoomOut();
    });
    useEffect(() => {
        if (!showCrosshair) {
            ref.current?.chart?.xAxis[0]?.hideCrosshair();
        }
    }, [showCrosshair]);
    return <div className={styles.chart}
                onClick={onClick}
                onMouseMove={(e) => setShowCrosshair(true)}
                onMouseOver={() => setShowCrosshair(true)}
                onMouseLeave={() => setShowCrosshair(false)}>
        <ChartComp innerSetRef={innerSetRef}
                   showXLabels={true}
                   afterDrawCrosshair={afterDrawCrosshair}
                   series={hcSeries}
                   options={options}/>
        <div className={styles.avgMinMaxContainer}>
            <Button onClick={onEditClick} size="sm" outline className={styles.button}>
                <Icon path={mdiPencil} size={0.5}/>
            </Button>
        </div>
        {showCrosshair && <ChartTooltip reference={crosshairRef}
                                        boundary={boundary}
                                        points={selectedPoints}
                                        tooltipFormatter={tooltipFormatter}
                                        units={units}/>}
    </div>
};

import React, {ReactNode, useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import {LoginState} from "../../../typings/LoginState";

const AuthRoute = () => {
    const userContext = useContext(UserContext);

    if (userContext.loginState === LoginState.loggedin) {
        return <Outlet/>;
    } else if (userContext.loginState === LoginState.loggedout) {
        return <Navigate to="/login" replace/>;
    }
    return <div>Loading</div>;
}

export default AuthRoute;

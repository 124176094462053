import uPlot from "uplot";

export class WheelPanPlugin implements uPlot.Plugin {
    private readonly maxX: number;
    constructor(maxX: number) {
        this.maxX = maxX;
    }

    factor = 0.1;

    hooks: uPlot.Hooks.ArraysOrFuncs = {
        ready: u => {
            u.over.addEventListener("wheel", e => {
                let currentMin = u.scales.x.min!!;
                let currentMax = u.scales.x.max!!;
                if (currentMin === 0 && currentMax >= this.maxX)
                    return;
                e.preventDefault();
                e.stopPropagation();

                let dx = e.deltaY / 100 * (currentMax - currentMin) * this.factor;
                let min = currentMin + dx;
                let max = currentMax + dx;

                //clamp min and max
                if (min < 0 && currentMin > 0) {
                    min = 0;
                    max = currentMax - currentMin;
                } else if (max > this.maxX && currentMax < this.maxX) {
                    min = currentMin + (this.maxX - currentMax);
                    max = this.maxX;
                } else if (!(currentMin === 0 && dx < 0) && !(currentMax >= this.maxX && dx > 0)) {

                } else
                    return;
                u.setScale('x', {
                    min,
                    max,
                });
            });
        }
    }
}
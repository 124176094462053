import {SettingsDropdown} from "../SettingsDropdown/SettingsDropdown";
import styles from "./DashboardHeader.module.scss";
import {LanguageSelector} from "../../../paddlemate/LanguageSelector/LanguageSelector";
import {NavButton} from "../../../base/NavButton/NavButton";
import {mdiAccessPoint, mdiAccount, mdiAccountGroup, mdiHome, mdiListBox, mdiLogout} from "@mdi/js";
import {useContext, useState} from "react";
import UserContext from "../../../../contexts/UserContext";
import {useTranslation} from "react-i18next";
import {Nav, Navbar, NavbarToggler, NavItem, Offcanvas, OffcanvasBody,} from "reactstrap";
import {Link} from "react-router-dom";

export const DashboardHeader = () => {
    const {isCoach, logout, setIsCoachVirtual, isActuallyCoach} = useContext(UserContext);
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogoutClick = () => {
        logout();
    }

    const toggle = () => setIsOpen(!isOpen);
    // we show the same navbar jsx element for small and large screens
    const navBar = <>
        <Nav className={styles.nav} navbar>
            <NavItem>
                <NavButton
                    icon={mdiHome}
                    title={t('statistics')}
                    link={'/dashboard'}
                />
            </NavItem>
            <NavItem>
                <NavButton
                    icon={mdiListBox}
                    title={t('training list')}
                    link={'/trainings'}
                />
            </NavItem>
            {isCoach &&
                <NavItem>
                    <NavButton
                        icon={mdiAccountGroup}
                        title={t('trainees')}
                        link={'/trainees'}
                    />
                </NavItem>
            }
            {!isCoach &&
                <NavItem>
                    <NavButton
                        icon={mdiAccountGroup}
                        title={t('coaches')}
                        link={'/coaches'}
                    />
                </NavItem>
            }
            {isCoach &&
                <NavItem className={"d-none d-xl-flex"}>
                    <NavButton
                        icon={mdiAccessPoint}
                        title={t('live')}
                        link={'/live'}
                    />
                </NavItem>
            }
            <NavItem className={"d-flex d-xl-none"}>
                <NavButton
                    icon={mdiAccount}
                    title={t('profile')}
                    link={'/profile'}
                />
            </NavItem>
            <NavItem className={"d-flex d-xl-none"}>
                <NavButton
                    icon={mdiLogout}
                    title={t('logout')}
                    onClick={handleLogoutClick}
                />
            </NavItem>
        </Nav>
        {isActuallyCoach &&
            <div className={styles.switchContainer}>
                <div
                    className={`${styles.switchButton} ${isCoach ? styles.active : ''}`}
                    onClick={() => (!isCoach) && setIsCoachVirtual(true)}
                >
                    {t('coach mode label')}
                </div>
                <div
                    style={{marginLeft: 8}}
                    className={`${styles.switchButton} ${isCoach ? '' : styles.active}`}
                    onClick={() => isCoach && setIsCoachVirtual(false)}
                >
                    {t('athlete mode label')}
                </div>
            </div>
        }
        <LanguageSelector/>
        <div style={{width: "10px"}}/>
        <div className="d-none d-xl-block"><SettingsDropdown/></div>
    </>;
    return (
        <Navbar className={styles.container} expand={"xl"} dark={true}>
            <Link to="/" className="navbar-brand">
                <img className={styles.logo} alt="" src="/icons/logos/paddlemate_logo_feher.png"/>
            </Link>
            {isActuallyCoach && <>
                <Nav className={`${styles.nav} d-flex d-xl-none`} navbar>
                    <NavItem>
                        <NavButton
                            icon={mdiAccessPoint}
                            title={t('live')}
                            link={'/live'}
                        />
                    </NavItem>
                </Nav>
                <div className={`d-flex d-xl-none`} style={{flex: 1}}></div>
            </>}
            <NavbarToggler onClick={toggle}/>
            <div className={styles.defaultNavbar}>{navBar}</div>
            <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className={styles.openCollapse}>
                <button
                    type="button"
                    onClick={toggle}
                    className={`btn-close btn-close-white ${styles.close}`}
                />
                <OffcanvasBody className={styles.canvasBody}>
                    {navBar}
                </OffcanvasBody>
            </Offcanvas>
        </Navbar>
    )
}

import * as React from "react";
import {useState} from "react";
import {DE, ES, GB, HU} from "country-flag-icons/react/3x2";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useTranslation} from "react-i18next";
import styles from './LanguageSelector.module.scss';

const iconsByLocale = {
    en: <GB/>,
    hu: <HU/>,
    de: <DE/>,
    es: <ES/>,
};
export const LanguageSelector = () => {
    const {i18n} = useTranslation();

    const setLanguage = (newLanguage: string) => {
        if (i18n.language !== newLanguage) {
            i18n.changeLanguage(newLanguage);
            sessionStorage.setItem("LANG_OVERRIDE", newLanguage)
        }
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className={styles.button}>{iconsByLocale[i18n.language.slice(0, 2) as keyof typeof iconsByLocale]}</DropdownToggle>
                <DropdownMenu className={styles.menu}>
                    {Object.keys(iconsByLocale).map((x) => (
                        <DropdownItem key={x} onClick={() => setLanguage(x)} className={styles.item}>
                            {iconsByLocale[x as keyof typeof iconsByLocale]}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
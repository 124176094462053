import {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AthletesContext from '../../../contexts/AthletesContext';
import UserContext from '../../../contexts/UserContext';
import {Dropdown} from '../../base/Dropdown/Dropdown';
import {DropdownLabel} from '../../base/DropdownLabel/DropdownLabel';
import {SelectItem} from '../../base/SelectItem/SelectItem';
import styles from './AthleteSelector.module.css';

export const AthleteSelector = () => {
    const {t} = useTranslation();
    const {isCoach} = useContext(UserContext);
    const {athletes, athlete, setAthlete} = useContext(AthletesContext);
    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef<HTMLDivElement>(null);

    if (!isCoach) return <div/>;

    return (
        <div
            ref={anchor}
            className={styles.container}
            onClick={() => setOpen(!open)}
        >
            <DropdownLabel
                label={athlete === null ? t('all') : athlete?.name ?? t('loading')}
                open={open}
                className={styles.label}
            />
            {open &&
                <Dropdown anchor={anchor} setOpen={setOpen}>
                    <div className={styles.dropdown}>
                        {athletes === null &&
                            <SelectItem
                                label={t('loading')}
                                onClick={() => {
                                }}
                            />
                        }
                        {isCoach && athletes !== null &&
                            <SelectItem
                                label={t('all')}
                                onClick={() => setAthlete(null)}
                            />
                        }
                        {athletes !== null &&
                            athletes!.map(athlete =>
                                <SelectItem
                                    key={athlete.id}
                                    label={athlete.name}
                                    onClick={() => setAthlete(athlete)}
                                />
                            )
                        }
                    </div>
                </Dropdown>
            }
        </div>
    );
};

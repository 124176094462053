const secToTimeString = (sec: number) => {
    const hours = Math.floor(sec / 3600);
    if (hours > 0) sec -= hours * 3600;
    const minutes = Math.floor(sec / 60);
    const minutesString = hours > 0 ? minutes.toString().padStart(2, '0') : minutes.toString();
    return (hours > 0 ? `${hours}:` : '') + `${minutesString}:${Math.floor(sec % 60) < 10 ? '0' : ''}${Math.floor(sec % 60)}`;
}

const capitalizeFirstLetter = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export const Formatter = {
    secToTimeString,
    capitalizeFirstLetter
}

import uPlot from "uplot";

export class PlotLinesPlugin implements uPlot.Plugin {
    private readonly plotLines: number[];

    constructor(plotLines: number[]) {
        this.plotLines = plotLines;
    }

    hooks: uPlot.Hooks.ArraysOrFuncs = {
        draw: (u) => {
            const ctx = u.ctx;
            const xScale = u.series[0].scale;
            const {top, height} = u.bbox;
            if (!xScale) return;
            const xMin = u.scales[xScale].min;
            const xMax = u.scales[xScale].max;
            if (xMin === undefined || xMax === undefined) return;

            ctx.save();
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 1;

            this.plotLines.forEach((xValue) => {
                if (xValue >= xMin && xValue <= xMax) {
                    const xPos = Math.round(u.valToPos(xValue, xScale, true)) - 0.5;
                    ctx.beginPath();
                    ctx.moveTo(xPos, top);
                    ctx.lineTo(xPos, top + height);
                    ctx.stroke();
                }
            });

            ctx.restore();
        }
    }
}
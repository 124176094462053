import moment from "moment";
import {ITrainingCommon} from "../typings/ITrainingCommon";
import {StatisticsData} from "../typings/StatisticsData";
import {TimeGrouping} from "../typings/TimeGrouping";
import {TrainingSport} from "../typings/TrainingSport";
import {IApiTrainingCommonFull} from "../typings/IApiTrainingCommonFull";
import {ITrainingCommonFile} from "../typings/ITrainingCommonFile";

const statisticsResponse = (d: any, timeGrouping: TimeGrouping) => {
    const statistics: StatisticsData = {
        timeGrouping,
        trainingSessions: d.trainingSessions,
        durationS: d.durationS,
        lengthKm: d.lengthKm,
        caloriesKcal: d.caloriesKcal,
        avgPaddlingForceN: d.avgPaddlingForceN,
        avgStrokeRateSpm: d.avgStrokeRateSpm,
        timePointsSeries: [],
        trainingSessionsSeries: [],
        timePointStrings: [],
        durationSSeries: [],
        lengthKmSeries: [],
        caloriesKcalSeries: [],
        avgPaddlingForceNSeries: [],
        avgStrokeRateSpmSeries: [],
    };
    const timePointsSeries = new Set([
        ...Object.keys(d.trainingSessionsSeries),
        ...Object.keys(d.durationSSeries),
        ...Object.keys(d.lengthKmSeries),
        ...Object.keys(d.caloriesKcalSeries),
        ...Object.keys(d.avgPaddlingForceNSeries),
        ...Object.keys(d.avgStrokeRateSpmSeries),
    ]);
    statistics.timePointsSeries = Array.from(timePointsSeries);
    for (const t of statistics.timePointsSeries) {
        if (d.trainingSessionsSeries[t] !== undefined) statistics.trainingSessionsSeries.push(d.trainingSessionsSeries[t]);
        else statistics.trainingSessionsSeries.push(NaN);
        if (d.durationSSeries[t] !== undefined) statistics.durationSSeries.push(d.durationSSeries[t]);
        else statistics.durationSSeries.push(NaN);
        if (d.lengthKmSeries[t] !== undefined) statistics.lengthKmSeries.push(d.lengthKmSeries[t]);
        else statistics.lengthKmSeries.push(NaN);
        if (d.caloriesKcalSeries[t] !== undefined) statistics.caloriesKcalSeries.push(d.caloriesKcalSeries[t]);
        else statistics.caloriesKcalSeries.push(NaN);
        if (d.avgPaddlingForceNSeries[t] !== undefined) statistics.avgPaddlingForceNSeries.push(d.avgPaddlingForceNSeries[t]);
        else statistics.avgPaddlingForceNSeries.push(NaN);
        if (d.avgStrokeRateSpmSeries[t] !== undefined) statistics.avgStrokeRateSpmSeries.push(d.avgStrokeRateSpmSeries[t]);
        else statistics.avgStrokeRateSpmSeries.push(NaN);
    }
    statistics.timePointStrings = statistics.timePointsSeries.map(t => {
        switch (timeGrouping) {
            case TimeGrouping.Year:
                return t;
            case TimeGrouping.DayOfWeek:
                return moment().day(parseInt(t) + 1).format("ddd");
            default:
                return "";
        }
    });
    return statistics;
}

const trainingCommonResponse = (tc: IApiTrainingCommonFull) => {
    const data: ITrainingCommon = {
        id: tc.id,
        name: tc.name ?? "",
        sport: tc.sport as TrainingSport,
        lengthKm: tc.lengthKm,
        startAt: new Date(tc.startAt),
        endAt: new Date(tc.endAt),
        createdBy: tc.createdBy,
        durationS: tc.durationS,
        notes: tc.notes,
        reportedProblem: tc.reportedProblem,

        avgStrokeRateSpm: tc.avgStrokeRateSpm,
        maxStrokeRateSpm: tc.maxStrokeRateSpm,
        avgSpeedKph: tc.avgSpeedKph,
        maxSpeedKph: tc.maxSpeedKph,

        speedData: tc.speedKphSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
        tempoData: tc.tempoMinpkmSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
        tempo500Data: tc.tempoMinp500mSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
        strokeData: tc.strokeRateSpmSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
        distanceData: tc?.lengthKmSeries?.split(',').map(val => parseFloat(val)),
        distancePerStrokeData: tc?.distancePerStrokeMSeries?.split(',').map(val => parseFloat(val)),
        gpsData: tc.coordinateSeries?.split(',')?.map(gpsString => {
            let lat: number;
            let lng: number;
            const splitted = gpsString.split("|");
            lat = parseFloat(splitted[0]);
            lng = parseFloat(splitted[1]);
            return {lat, lng};
        }) ?? undefined,

        trainings: tc.trainings.map(t => ({
            id: t.id,
            user: t.user,
            avgPaddlingForceN: t.avgPaddlingForceN,
            maxPaddlingForceN: t.maxPaddlingForceN,
            balanceDeg: t.balanceDeg,
            symmetryPct: t.symmetryPct,
            caloriesKcal: t.caloriesKcal,
            avgHeartRateBpm: t.avgHeartRateBpm,
            maxHeartRateBpm: t.maxHeartRateBpm,
            leftPaddlingForceNSeries: t.leftPaddlingForceNSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
            rightPaddlingForceNSeries: t.rightPaddlingForceNSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
            heartRateBpmSeries: t.heartRateBpmSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
            detailedLeftPaddlingForceNSeries: t.detailedLeftPaddlingForceNSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
            detailedRightPaddlingForceNSeries: t.detailedRightPaddlingForceNSeries?.split(',').map(val => parseFloat(val)) ?? undefined,
            notes: t.notes ?? "",
            devices: t.devices,
            hrZones: t.hrZones
        })),
        recorderDevice: tc.recorderDevice,
        userSeries: tc.userSeries
    };
    return data;
}

const trainingCommonFileResponse = (tcf: ITrainingCommonFile) => {
    if(tcf.imuSeries)
    {
        for (let i = 0; i < tcf.imuSeries.length; i++) {
            for (let j = 0; j < 3; j++) {
                tcf.imuSeries[i][j] /= 4.1;
            }
            for (let j = 3; j < 6; j++) {
                tcf.imuSeries[i][j] /= 65.536;
            }
        }
    }
    return tcf;
}

export const Transform = {
    statisticsResponse,
    trainingCommonResponse,
    trainingCommonFileResponse,
}

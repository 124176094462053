import {Button, Col, Label, Row} from "reactstrap";
import * as React from "react";
import {forwardRef, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Api} from "../../../utils/api";
import {useTranslation} from "react-i18next";
import styles from "./ExternalConnections.module.scss";
import {mdiCheck} from "@mdi/js";
import Icon from "@mdi/react";
import {Spinner} from "../../base/Spinner/Spinner";
import {useSearchParams} from "react-router-dom";
import DialogContext from "../../../contexts/DialogContext";
import {stravaConfig} from "../../../utils/config";

export const externalServices = [
    {
        id: "garmin",
        nameKey: "garmin connect",
        icon: "/icons/Garmin_Connect_app.png",
        descriptionKey: "garmin description",
        operations: ["download"]
    },
    {
        id: "strava",
        nameKey: "strava",
        icon: "/icons/strava.png",
        connectIcon: "/icons/btn_strava_connectwith_orange.png",
        descriptionKey: "strava description",
        noteKey: "strava note",
        operations: ["upload", "download"]
    }];

export const ExternalConnections = forwardRef<HTMLDivElement, any>((props, ref) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [connectedServices, setConnectedServices] = useState<string[]>([]);
    const [loadingServices, setLoadingServices] = useState<string[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const {showError} = useContext(DialogContext);
    const updateConnectedServices = async () => {
        const res = await Api.getConnectedServices();
        setConnectedServices(res.services);
        setLoading(false);
    };

    const actions = {
        "garmin": {
            connect: () => {
                setLoadingServices([...loadingServices, "garmin"])
                const state = Math.random().toString(36).slice(2);
                localStorage.setItem("garmin_oauth_state", state);
                Api.requestTokenGarmin().then((res) => {
                    const redirectUri = `${window.location.protocol}//${window.location.host}/connect/garmin?state=${state}`;
                    window.location.href = res.redirectUri + `&oauth_callback=${encodeURIComponent(redirectUri)}`
                }).catch(console.error).finally(() => {
                    setLoadingServices(loadingServices.filter(x => x !== "garmin"))
                });
            },
            disconnect: () => {
                setLoadingServices([...loadingServices, "garmin"])
                Api.disconnectGarmin().then(() => {
                    updateConnectedServices().catch(console.log)
                }).catch(console.error).finally(() => {
                    setLoadingServices(loadingServices.filter(x => x !== "garmin"))
                })
            }
        },
        "strava": {
            connect: () => {
                const state = Math.random().toString(36).slice(2);
                localStorage.setItem("strava_oauth2_state", state);
                const redirectUri = `${window.location.protocol}//${window.location.host}/connect/strava`;
                window.location.href = `https://www.strava.com/oauth/authorize?client_id=${stravaConfig.clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=auto&scope=activity:write,activity:read_all&state=${state}`;
            },
            disconnect: () => {
                setLoadingServices([...loadingServices, "strava"])
                Api.disconnectStrava().then(() => {
                    updateConnectedServices().catch(console.log)
                }).catch(console.error).finally(() => {
                    setLoadingServices(loadingServices.filter(x => x !== "strava"))
                })
            }
        }
    }

    useEffect(() => {
        updateConnectedServices().catch(console.log);
    }, []);

    const spHandled = useRef(false);
    const externalConnectionsRef = useCallback((node: HTMLDivElement, service: string) => {
        if (node !== null && !loading) {
            if (spHandled.current) return
            const success = searchParams.get("success-ec");
            if (success === service) {
                spHandled.current = true;
                node.scrollIntoView();
                searchParams.delete("success-ec");
                setSearchParams(searchParams, {replace: true});
            }
            const failure = searchParams.get("failure-ec");
            if (failure === service) {
                spHandled.current = true;
                node.scrollIntoView();
                showError(t("connect service error format", {
                    service:
                        t(externalServices.find(x => x.id === service)!.nameKey)
                }), () => {
                    searchParams.delete("failure-ec");
                    setSearchParams(searchParams, {replace: true});
                })
            }
        }
    }, [loading, searchParams, setSearchParams, showError, t]);

    return <div>
        <Label style={{fontWeight: 700}}>{t("external connections")}</Label>
        {loading && <div><Spinner/></div>}
        {!loading && externalServices.map(x => {
            const connected = connectedServices.includes(x.id);
            const isLoading = loadingServices.includes(x.id);
            return <div key={x.id} ref={(node: HTMLDivElement) => externalConnectionsRef(node, x.id)}>
                <Row>
                    <Col sm={2} xs={2} className={styles.logo}>
                        <img src={x.icon} alt=""/>
                    </Col>
                    <Col sm={7} xs={10} className={styles.text}>
                        <div>
                            <Label style={{fontWeight: 700}}>{t(x.nameKey)}</Label>
                            <p style={{margin: 0}}>{t(x.descriptionKey)}</p>
                            <ul className="mt-2">
                                {x.operations.includes("upload") && <li>{t("uploading trainings")}</li>}
                                {x.operations.includes("download") && <li>{t("downloading trainings")}</li>}
                            </ul>
                            {x.operations.includes("upload") && x.noteKey &&
                                <p className={styles.note}>{t("note")}: {t(x.noteKey)}</p>}
                        </div>
                    </Col>
                    <Col sm={3} xs={12} className={styles.right}>
                        {connected && <span className="d-inline mb-2 d-flex flex-row justify-content-center">
                                {t("connected")} <Icon path={mdiCheck} size={0.75}/>
                            </span>}
                        {isLoading && <Spinner/>}
                        {!isLoading && <>
                            {(connected || !x.connectIcon) && <Button outline className={styles.button}
                                                                      onClick={connected ? (actions as any)[x.id].disconnect : (actions as any)[x.id].connect}
                            >{t(connected ? "disconnect" : "connect")}</Button>}
                            {(!connected && x.connectIcon) && <img src={x.connectIcon} className={styles.button}
                                                                   onClick={(actions as any)[x.id].connect}/>}
                        </>}
                    </Col>
                </Row>
                <hr/>
            </div>
        })}
    </div>
});
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import styles from "./DialogShim.module.css";

interface IPosition {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

interface IDialogShim {
    title: string,
    children: React.ReactNode,
    className?: string,
    onClose?: () => void,
    position?: IPosition,
    onClickAway?: React.MouseEventHandler<HTMLDivElement>,
    style?: React.CSSProperties,
    noHeader?: boolean,
}

const DialogShim = ({
    title = "Error",
    className = "",
    children,
    onClose = undefined,
    position = undefined,
    onClickAway = undefined,
    style = {},
    noHeader = false,
}: IDialogShim) => {
    return (
        <>
            {onClickAway &&
                <div
                    className={styles.dialogShimClickaway}
                    onClick={onClickAway}
                />
            }

            <div
                className={`${styles.dialogShim} ${position ? styles.positioned : styles.centered} ${className}`}
                style={position && position.left !== undefined ? { left: position.left, ...style } : style}
            >
                {noHeader ? null :
                    (
                        <>
                            {onClose &&
                                <div className={styles.close} onClick={onClose}>
                                    <Icon path={mdiClose} size={0.7} />
                                </div>
                            }
                            <div className={styles.title}>
                                {title}
                            </div>
                        </>
                    )}

                {children}
            </div>
        </>
    );
}

export default DialogShim;

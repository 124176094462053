import {CSSProperties, useCallback, useEffect, useRef} from "react";
import styles from "./ChartStepper.module.css";
import {Button} from "reactstrap";
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment/moment";
import {Moment} from "moment";

interface IChartStepper {
    min: number;
    max: number;
    from: number;
    to: number;
    onChange: (minVal: number, maxVal: number) => void;
    style?: CSSProperties | undefined;
}

export const ChartStepper = (args: IChartStepper) => {
    const {min, max, onChange, from, to, style} = args;
    const interval = useRef<number>();
    const stepCount = useRef<number>(0);

    const step = useCallback((direction: ("left" | "right")) => {
        let d = (to - from) / 10;
        if (direction === "left")
            d *= -1;
        //clamp step
        if (from + d < min) {
            d = min - from;
        }
        if (to + d > max) {
            d = max - to;
        }
        onChange(from + d, to + d)
    }, [from, max, min, onChange, to]);

    const stepRef = useRef<(direction: ("left" | "right")) => void>(step);
    const lastStepRef = useRef<(direction: ("left" | "right")) => void>();
    useEffect(() => {
        stepRef.current = step;
    }, [step]);

    const onMouseDown = useCallback((direction: ("left" | "right")) => {
        interval.current = window.setInterval(() => {
            // this handler gets called slower that the timeout value
            // if somehow it still gets called faster, do not call the same stepRef multiple times,
            // as it only works for one time (for the same deps)
            if (lastStepRef.current !== stepRef.current)
                stepRef.current(direction);
            lastStepRef.current = stepRef.current;
            stepCount.current++;
        }, 100);
    }, []);

    const onMouseUp = useCallback((direction: ("left" | "right")) => {
        if (interval.current) {
            window.clearInterval(interval.current);
            interval.current = undefined;
        }
        if (stepCount.current === 0)
            stepRef.current(direction);
        stepCount.current = 0;
    }, []);

    return (
        <div className={styles.container} style={style}>
            <Button outline
                    onMouseDown={() => onMouseDown("left")} onMouseUp={() => onMouseUp("left")} disabled={from === min}>
                <Icon
                    size={1}
                    path={mdiArrowLeft}
                />
            </Button>
            <Button outline onClick={() => step("right")}
                    onMouseDown={() => onMouseDown("right")} onMouseUp={() => onMouseUp("right")} disabled={to === max}>
                <Icon
                    size={1}
                    path={mdiArrowRight}
                />
            </Button>
        </div>
    );
};

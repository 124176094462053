import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {IAthlete} from "../typings/IAthlete";
import {Api} from "../utils/api";
import UserContext from "./UserContext";
import {usePrevious} from "../hooks/usePrevious";
import {ATHLETE_QUERY_PARAM, parseNumberQuery, serializeNumberQuery, useQuery} from "../hooks/useQuery";

interface IAthletesContext {
    athleteId: number | null;  // undefined - loading; null - all
    athlete: IAthlete | undefined | null;  // undefined - loading; null - all
    athletes: IAthlete[] | null;
    deletedAthletes: IAthlete[] | null;
    cachedAthleteId: number | undefined;

    setAthlete: (athlete: IAthlete | null) => void;
    refreshAthletes: () => void;
    setAthleteId: (athleteId: number | undefined) => void;
    setCachedAthleteId: (athleteId: number | undefined) => void;
    loadCached: () => void;
}

const dv: IAthletesContext = {
    athleteId: null,
    athlete: undefined,
    athletes: null,
    deletedAthletes: null,
    cachedAthleteId: undefined,
    setAthlete: () => {
    },
    refreshAthletes: () => {
    },
    setAthleteId: () => {
    },
    setCachedAthleteId: () => {
    },
    loadCached: () => {
    },
};

export const AthletesContext = React.createContext(dv);

export const AthletesContextProvider = ({children}: { children: React.ReactNode }) => {
    const {user, isCoach} = useContext(UserContext);

    const [athletes, setAthletes] =
        useState<IAthlete[] | null>(null);
    const [deletedAthletes, setDeletedAthletes] =
        useState<IAthlete[] | null>(null);
    const [cachedAthleteId, setCachedAthleteId] = useState<number>();

    const {value: athleteId, update: setAthleteId} = useQuery(
        ATHLETE_QUERY_PARAM, parseNumberQuery, serializeNumberQuery
    );

    useEffect(() => { // unset athlete id if not found in athletes
        if (athletes != null && athleteId !== undefined &&
            !athletes.find(x => x.id === athleteId)) {
            setAthleteId(undefined);
        }
    }, [athleteId, athletes, setAthleteId]);

    const loadCached = useCallback(() => { // set cached or default value
        if (athleteId === undefined) {
            if(cachedAthleteId !== undefined) {
                setAthleteId(cachedAthleteId);
            }
        }
    }, [athleteId, cachedAthleteId, setAthleteId]);

    const setAthlete = useCallback((athlete: IAthlete | null) => {
        setAthleteId(athlete?.id);
    }, [setAthleteId]);

    const athlete = useMemo(() =>
            athleteId == null ? null : (athletes?.find(x => x.id === athleteId) ?? undefined),
        [athleteId, athletes]);

    useEffect(() => { // Load athletes
        let cancelled = false;

        if (user === null || isCoach === undefined) {
            setAthletes(null);
            return;
        }
        const load = async () => {
            if (isCoach) {
                try {
                    const athletes = await Api.fetchAthletes(user!.id);
                    if (cancelled) return;
                    setAthletes(athletes.filter(athlete => !athlete.deleted));
                    setDeletedAthletes(athletes.filter(athlete => athlete.deleted));
                } catch (e) {
                    console.error("Error fetching athletes");
                    console.error(e);
                }
            } else {
                const athletes = [{
                    id: user!.id,
                    club: user!.club,
                    name: user!.name,
                    firstName: user!.firstName,
                    lastName: user!.lastName,
                    profilePictureUrl: user!.profilePictureUrl,
                    sport: user!.sport,
                    lastWeekTrainingCount: 0, // TODO this number in this case might not be correct?
                    deleted: false,
                    maxHrBpm: 220,
                    cloudId: user!.cloudId
                }];
                if (cancelled) return;
                setAthletes(athletes);
                setDeletedAthletes([]);
            }
        };
        load().catch(console.error);

        return () => {
            cancelled = true;
        };
    }, [isCoach, user]);

    const previousIsCoach = usePrevious(isCoach);

    useEffect(() => {
        if (previousIsCoach !== undefined && isCoach !== undefined && previousIsCoach !== isCoach) {
            setAthleteId(undefined);
        }
    }, [isCoach, previousIsCoach, setAthleteId]);

    const refreshAthletes = async () => {
        try {
            const athletes = await Api.fetchAthletes(user!.id);
            setAthletes(athletes.filter(athlete => !athlete.deleted));
            setDeletedAthletes(athletes.filter(athlete => athlete.deleted));
        } catch (e) {
            console.error("Error fetching athletes");
            console.error(e);
        }
    };

    return (
        <AthletesContext.Provider value={{
            athleteId: athleteId || null,
            athlete,
            athletes,
            deletedAthletes,
            cachedAthleteId,

            setAthlete,
            refreshAthletes,
            setCachedAthleteId,
            setAthleteId,
            loadCached
        }}>
            {children}
        </AthletesContext.Provider>
    );
};

export default AthletesContext;

import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContext from '../../../contexts/DialogContext';
import { ICoachingRequest } from '../../../typings/ICoachingRequest';
import { Button } from '../../base/Button/Button';
import { SizedBox } from '../../base/SizedBox/SizedBox';
import { Spacer } from '../../base/Spacer/Spacer';
import { YesCancelDialog } from '../../dialogs/YesCancelDialog/YesCancelDialog';
import styles from './CoachingRequestItem.module.css';

interface ICoachingRequestItem {
    request: ICoachingRequest;
    onResend: () => void;
    onDelete: () => void;
    didAthleteDelete?: boolean;
}

export const CoachingRequestItem = ({ request: req, onDelete, onResend, didAthleteDelete = false }: ICoachingRequestItem) => {
    const { t } = useTranslation();
    const { openDialog } = useContext(DialogContext);

    const onResendWrapper = () => {
        openDialog(
            <YesCancelDialog
                title={t('are your sure')}
                onYes={onResend}
            />
        )
    }
    const onDeleteWrapper = () => {
        openDialog(
            <YesCancelDialog
                title={t('are your sure you want to delete it')}
                onYes={onDelete}
            />
        )
    }

    return (
        <div className={styles.coachingRequest}>
            <img
                className={styles.profilePic}
                src={req.athleteProfilePictureUrl}
            />
            <SizedBox width={8} />
            <div>
                <div className={styles.name}>
                    {req.athleteName}
                </div>
                <div className={styles.email}>
                    {req.athleteEmail}
                </div>
            </div>
            <Spacer flex={1} />
            {!didAthleteDelete && req.denied &&
                <div className={styles.resendContainer}>
                    <Button
                        label={t('resend')}
                        secondary={true}
                        onClick={onResendWrapper}
                    />
                </div>
            }
            <div className={`${styles.status} ${req.denied ? styles.statusDenied : ''}`}>
                {didAthleteDelete ? t('deleted by athlete') : ''}
                {!didAthleteDelete ? (req.denied ? t('denied') : t('waiting for accept')) : ''}
            </div>
            <div className={styles.deleteIcon} onClick={onDeleteWrapper}>
                <Icon size={1.2} path={mdiClose} />
            </div>
        </div>
    );
};

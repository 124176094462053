import moment, {Moment} from "moment";
import {useEffect, useMemo, useRef, useState} from "react";
import {Dropdown} from "../Dropdown/Dropdown";
import {DropdownLabel} from "../DropdownLabel/DropdownLabel";
import {SelectItem} from "../SelectItem/SelectItem";
import styles from "./DateSelector.module.scss";
import {useTranslation} from "react-i18next";

interface IDateTimeSelector {
    date: Date | undefined;
    setDate: (date: Date) => void;
    highlighted?: boolean;
    minYear?: number;
    maxYear?: number;
    preset?: "bootstrap";
    disabled?: boolean;
}

const defaultMinYear = 2020;
const defaultMaxYear = moment().year();

export const DateSelector = ({
                                 date,
                                 setDate,
                                 highlighted,
                                 minYear = defaultMinYear,
                                 maxYear = defaultMaxYear,
                                 preset,
                                 disabled
                             }: IDateTimeSelector) => {
    const {t} = useTranslation();
    const yearSelector = useRef<HTMLDivElement>(null);
    const [yearSelectorOpen, setYearSelectorOpen] = useState<boolean>(false);
    const calendarSelector = useRef<HTMLDivElement>(null);
    const [calendarSelectorOpen, setCalendarSelectorOpen] = useState<boolean>(false);

    const years = useMemo(() => Array.from({length: maxYear - minYear + 1},
        (_, i) => i + minYear).reverse(), [maxYear, minYear]);

    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedMonthDay, setSelectedMonthDay] = useState<{ month: number, date: number } | null>(null);
    const [tempDate, setTempDate] = useState<Moment | null>(null);
    const momentDate = (date && moment(date)) || null;

    useEffect(() => {
        if (date) {
            const m = moment(date);
            setSelectedYear(m.year());
            setSelectedMonthDay({month: m.month(), date: m.date()});
        } else {
            setSelectedYear(null);
            setSelectedMonthDay(null);
        }
    }, [date]);

    useEffect(() => {
        const now = moment();
        let newVar = (selectedYear && moment({
            year: selectedYear,
            month: selectedMonthDay?.month ?? now.month(),
            date: selectedMonthDay?.date ?? now.date()
        })) || null;
        setTempDate(newVar);
    }, [selectedMonthDay, selectedYear]);

    const decrementMonth = () => {
        setTempDate(x => x!.clone().subtract(1, 'M'))
    }

    const incrementMonth = () => {
        setTempDate(x => x!.clone().add(1, 'M'))
    }

    const startWeekdayOfMonth = tempDate?.clone()?.startOf('month').day() === 0 ? 7 : tempDate?.clone()?.startOf('month').day();

    const now = moment();
    const isInCurrentMonth = tempDate?.clone()?.month() === now.month() && tempDate?.clone()?.year() === now.year();

    const setDateInternal = (year: number, month: number, day: number) => {
        setDate(moment({year, month, day}).toDate());
    }

    const handleYearClick = (year: number) => {
        let lastMonthDay = selectedMonthDay;
        // check if the selected month-day is valid for this year (leap days)
        if (tempDate && lastMonthDay) {
            const c = tempDate.clone().year(year)
            if (!c.isSame(tempDate, "date")) // moment clamped the date
            {
                const corrected = c.endOf("month")
                lastMonthDay = {month: corrected.month(), date: corrected.date()}
                setSelectedMonthDay(lastMonthDay)
            }
        }
        if (lastMonthDay)
            setDateInternal(year, lastMonthDay.month, lastMonthDay.date);
        else {
            setSelectedYear(year);
        }
        setYearSelectorOpen(false);
    };

    const handleDayClick = (day: number) => {
        setDateInternal(selectedYear!, tempDate!.month(), day);
        setCalendarSelectorOpen(false);
    };

    return (
        <div className={`${styles.container} ${highlighted ? styles.highlighted : ''}`}>
            <div
                ref={yearSelector}
                onClick={() => !disabled && setYearSelectorOpen(!yearSelectorOpen)}
                className={`${preset === "bootstrap" ? `form-control ${styles.formControlBootstrap}` : ""} ${disabled ? styles.disabled2 : ""}`}
            >
                <DropdownLabel
                    label={momentDate?.year().toString() ?? selectedYear?.toString() ?? t('year')}
                    open={yearSelectorOpen}
                    preset={preset}
                    disabled={disabled}
                />
                {yearSelectorOpen &&
                    <Dropdown anchor={yearSelector} setOpen={setYearSelectorOpen}>
                        <div className={styles.yearSelector}>
                            {years.map(year => (
                                <SelectItem
                                    key={year}
                                    label={year.toString()}
                                    onClick={() => handleYearClick(year)}
                                />
                            ))}
                        </div>
                    </Dropdown>
                }
            </div>
            <div
                ref={calendarSelector}
                onClick={() => {
                    // year must be selected first -> leap years have different days
                    if (disabled || calendarSelectorOpen || !selectedYear) return
                    setCalendarSelectorOpen(!calendarSelectorOpen)
                }}
                className={`${preset === "bootstrap" ? `form-control ${styles.formControlBootstrap}` : ""} ${(!selectedYear) ? styles.disabled : ""}  ${disabled ? styles.disabled2 : ""}`}
                style={{
                    ...(preset === "bootstrap" && {marginLeft: 8})
                }}
            >
                <DropdownLabel
                    label={(momentDate?.format("MMMM D")) ?? t('day')}
                    open={calendarSelectorOpen}
                    disabled={!selectedYear}
                    preset={preset}
                />
                {calendarSelectorOpen &&
                    <Dropdown anchor={calendarSelector} setOpen={setCalendarSelectorOpen}
                              className={styles.calendar}>
                        <div className={styles.calendarHeader}>
                            <div onClick={decrementMonth} className={styles.timeSelectorButton}>
                                &lt;
                            </div>
                            <div>
                                {tempDate?.format('MMMM')}
                            </div>
                            <div onClick={incrementMonth} className={styles.timeSelectorButton}>
                                &gt;
                            </div>
                        </div>
                        <div className={styles.calendarGrid}>
                            {Array.from({length: startWeekdayOfMonth! - 1}).map((_, i) => <div key={i}
                                                                                               className={styles.cell}/>)}
                            {Array.from({length: tempDate!.daysInMonth()}).map((_, i) => {
                                const isToday = isInCurrentMonth && now.date() === i + 1;
                                return (
                                    <div
                                        key={i}
                                        className={`${styles.cell} ${styles.contentCell} ${isToday ? styles.currentDate : ''}`}
                                        onClick={() => handleDayClick(i + 1)}
                                    >
                                        {(i + 1).toString()}
                                    </div>
                                );
                            })}
                        </div>
                    </Dropdown>
                }
            </div>
        </div>
    );
};

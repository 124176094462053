export enum GenderType {
    Woman = 1,
    Man = 2,
    Other = 3,
}

export function genderToString(g?: GenderType | null) {
    switch (g) {
        case GenderType.Woman:
            return 'female';
        case GenderType.Man:
            return 'male';
        case GenderType.Other:
            return 'other';
        default:
            return '';
    }
}

import uPlot, {AlignedData, Options} from "uplot";

export class TouchZoomPlugin implements uPlot.Plugin {
    private readonly onZoomingChanged: (value: boolean) => void;
    private readonly maxX: number;


    constructor(onZoomingChanged: (value: boolean) => void, maxX: number) {
        this.onZoomingChanged = onZoomingChanged;
        this.maxX = maxX;
    }

    hooks: uPlot.Hooks.ArraysOrFuncs = {
        init: (u: uPlot, opts: Options, data: AlignedData) => {
            let over = u.over;
            let rect: DOMRect, oxRange: number, oyRange: number, xVal: number, yVal: number;
            let fr = {x: 0, y: 0, dx: 0, dy: 0};
            let to = {x: 0, y: 0, dx: 0, dy: 0};

            function storePos(t: { dx: number; dy: number; x: number; y: number }, e: TouchEvent) {
                let ts = e.touches;

                let t0 = ts[0];
                let t0x = t0.clientX - rect.left;
                let t0y = t0.clientY - rect.top;

                if (ts.length === 1) {
                    t.x = t0x;
                    t.y = t0y;
                    (t as any).d = t.dx = t.dy = 1;
                } else {
                    let t1 = e.touches[1];
                    let t1x = t1.clientX - rect.left;
                    let t1y = t1.clientY - rect.top;

                    let xMin = Math.min(t0x, t1x);
                    let yMin = Math.min(t0y, t1y);
                    let xMax = Math.max(t0x, t1x);
                    let yMax = Math.max(t0y, t1y);

                    // midpts
                    t.y = (yMin + yMax) / 2;
                    t.x = (xMin + xMax) / 2;

                    t.dx = xMax - xMin;
                    t.dy = yMax - yMin;

                    // dist
                    (t as any).d = Math.sqrt(t.dx * t.dx + t.dy * t.dy);
                }
            }

            let rafPending = false;

            function clamp(nRange: number, nMin: any, nMax: any, fRange: number, fMin: number, fMax: number) {
                if (nRange > fRange) {
                    nMin = fMin;
                    nMax = fMax;
                } else if (nMin < fMin) {
                    nMin = fMin;
                    nMax = fMin + nRange;
                } else if (nMax > fMax) {
                    nMax = fMax;
                    nMin = fMax - nRange;
                }

                return [nMin, nMax];
            }

            const zoom = () => {
                rafPending = false;

                let left = to.x;
                //let top = to.y;

                // non-uniform scaling
                //	let xFactor = fr.dx / to.dx;
                //	let yFactor = fr.dy / to.dy;

                // uniform x/y scaling
                let xFactor = (fr as any).d / (to as any).d;
                //let yFactor = (fr as any).d / (to as any).d;

                let leftPct = left / rect.width;
                //let btmPct = 1 - top / rect.height;

                let nxRange = oxRange * xFactor;
                let nxMin = xVal - leftPct * nxRange;
                let nxMax = nxMin + nxRange;
                [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, this.maxX, 0, this.maxX);

                //let nyRange = oyRange * yFactor;
                //let nyMin = yVal - btmPct * nyRange;
                //let nyMax = nyMin + nyRange;
                u.setScale("x", {
                    min: nxMin,
                    max: nxMax,
                });

                // u.batch(() => {
                //     u.setScale("x", {
                //         min: nxMin,
                //         max: nxMax,
                //     });
                //
                //     // u.setScale("y", {
                //     //     min: nyMin,
                //     //     max: nyMax,
                //     // });
                // });
            }

            function touchmove(e: TouchEvent | Event) {
                storePos(to, e as TouchEvent);

                if (!rafPending) {
                    rafPending = true;
                    requestAnimationFrame(zoom);
                }
            }

            over.addEventListener("touchstart", (e) => {
                if (e.touches.length > 1) {
                    e.preventDefault();
                    this.onZoomingChanged(true);
                }
                rect = over.getBoundingClientRect();

                storePos(fr, e);

                oxRange = u.scales.x.max!! - u.scales.x.min!!;
                oyRange = u.scales.y.max!! - u.scales.y.min!!;

                let left = fr.x;
                let top = fr.y;

                xVal = u.posToVal(left, "x");
                yVal = u.posToVal(top, "y");

                document.addEventListener("touchmove", touchmove, {passive: true});
            });

            over.addEventListener("touchend", (e) =>  {
                this.onZoomingChanged(false);
                document.removeEventListener("touchmove", touchmove);
            });
        }
    }
}
const hexToRgb = (hex: string) => {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return {r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255};
    }
    throw new Error('Bad Hex');
}

// See http://www.w3.org/TR/AERT#color-contrast
const getBrightness = ({r, g, b}: any) => (r * 299 + g * 587 + b * 114) / 1000;

export const getContrastColor = (color: string, light: string, dark: string) => {
    const rgba = hexToRgb(color);
    return getBrightness(rgba) > 128 ? dark : light;
}
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en.json";
import huTranslation from "./translations/hu.json";
import deTranslation from "./translations/de.json";
import esTranslation from "./translations/es.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: enTranslation
    },
    hu: {
        translation: huTranslation
    },
    de: {
        translation: deTranslation
    },
    es: {
        translation: esTranslation
    }
};

export const supportedLanguages = Object.keys(resources);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            order: ['sessionStorage', 'navigator'],
            lookupCookie: undefined,
            caches: ['sessionStorage'],
            excludeCacheFor: ["cookies"]
        }
    });

export default i18n;

import {apiConfig} from "./apiConfig";
import axios from "axios";
import {IApiLiveData} from "../typings/IApiLiveData";
import qs from "qs";

const liveAxiosApi = axios.create({
    baseURL: apiConfig.liveApiUrl
});

export const LiveApi = {
    getData: async (userIds: number[], creatorId: number): Promise<IApiLiveData[]> => {
        const res = await liveAxiosApi.get<IApiLiveData[]>("/api/live", {
            params: {
                userIds,
                creatorId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });
        return res.data;
    }
}

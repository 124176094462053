import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import DialogContext from "../../../contexts/DialogContext";
import {Button} from "../../base/Button/Button";
import {SizedBox} from "../../base/SizedBox/SizedBox";
import DialogShim from "../DialogShim";
import styles from "./LactateChartDialog.module.scss";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";
import {Button as BsButton, Col, FormFeedback, Input, Label, Row} from "reactstrap";
import {UserSeriesUnit} from "../../../typings/UserSeriesUnit";
import {getAthleteDisplayName} from "../../paddlemate/AnalyticsPanel/AthleteToggle/AthleteToggle";
import Icon from "@mdi/react";
import {mdiClose, mdiPlus} from "@mdi/js";
import moment from "moment";
import {Api} from "../../../utils/api";
import {IUserSeriesData} from "../../../typings/IUserSeries";
import {Spinner} from "../../base/Spinner/Spinner";
import {avg} from "../../../utils/arrayUtils";
import {DecimalNumberInput} from "./DecimalNumberInput";
import {LactateChartDialogChart} from "./LactateChartDialogChart";
import {usePrevious} from "../../../hooks/usePrevious";

interface ILactateChartDialog {
    dialogKey?: string,
    training: ITrainingCommon,
    userSeriesIndex?: number,
    distanceSeries: number[][] | null,
    speedSeries: number[][] | null,
    forceLeftSeries: (number[][] | null)[],
    forceRightSeries: (number[][] | null)[],
    heartRateSeries: (number[][] | null)[];
    strokeSeries: number[][] | null;
    isTracker: boolean,
    onChange?: () => void
}

export interface LactateSample {
    from: number | null;
    to: number | null
    value: number | null;
    force: number | null;
    sr: number | null;
    hr: number | null;
}

export const LactateChartDialog = ({
                                       dialogKey: key,
                                       training,
                                       distanceSeries,
                                       speedSeries,
                                       userSeriesIndex,
                                       forceLeftSeries,
                                       forceRightSeries,
                                       heartRateSeries,
                                       strokeSeries,
                                       isTracker,
                                       onChange
                                   }: ILactateChartDialog) => {
    const dialogContext = useContext(DialogContext);
    const {t} = useTranslation();

    const onDismiss = () => dialogContext.dismissDialog(key!);
    const [athleteIndex, setAthleteIndex] = useState<number>(0);
    const [samples, setSamples] = useState<LactateSample[]>([]);
    const [sampleDisplayErrors, setSampleDisplayErrors] = useState<any[]>([]);
    const [selectedSampleIndex,
        setSelectedSampleIndex] = useState<number | undefined>(0);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [dirty, setDirty] = useState(false);
    const currentSampleErrors = useRef<any[]>([]);
    const chartRef = useRef<any>(null);
    const samplesScrollRef = useRef<any>();

    const formatTimestamp = (x: number | null) => {
        const moment1 = moment(x).utc();
        return x === null ? "" :
            String(moment1.format(moment1.hours() > 0 ? "HH:mm:ss" : "mm:ss"));
    };
    useEffect(() => {
        if (userSeriesIndex !== undefined) {
            const data = training.userSeries[userSeriesIndex].data;
            setSamples(data.samples);
            if (data.trainingIndex)
                setAthleteIndex(data.trainingIndex);
        } else {
            setSamples([{
                from: null, to: null, value:
                    null, force: null, sr: null, hr: null
            }]);
        }
    }, [training, userSeriesIndex]);
    const handleDeleteSample = (index: number) => {
        if (selectedSampleIndex === index)
            setSelectedSampleIndex(undefined);
        setSamples(x => x
            .filter((_, i) => i !== index));
    };
    const handleAddSample = () => {
        setSamples(x => {
            setSelectedSampleIndex(x.length);
            return [...x,
                {
                    from: null, to: null, value:
                        null, force: null, sr: null, hr: null
                }];
        });
    };
    const lastSamples = usePrevious(samples);
    useEffect(() => {
        if (lastSamples != null && lastSamples.length < samples.length)
            samplesScrollRef.current?.scrollTo(0, samplesScrollRef.current.scrollHeight);
    }, [lastSamples, samples]);
    useEffect(() => {
        const xAxis = chartRef.current?.xAxis[0];
        if (!xAxis) return;
        xAxis!.setExtremes(undefined, undefined);
    }, [selectedSampleIndex]);

    const leftRightForcesData = useMemo(() => [
        forceLeftSeries[athleteIndex]?.map(y => y[1] as number),
        forceRightSeries[athleteIndex]?.map(y => y[1] as number)
    ].filter(x => x) as number[][], [athleteIndex, forceLeftSeries, forceRightSeries]);
    const strokeDatas = useMemo(() => strokeSeries?.map(
        x => x[1]), [strokeSeries]);
    const hrDatas = useMemo(() => heartRateSeries[athleteIndex]?.map(
        x => x[1]), [athleteIndex, heartRateSeries]);

    const handleRangeSelected = (from: number, to: number) => {
        if (athleteIndex == null) return
        const fromSec = Math.round(from / 1000);
        const toSec = Math.round(to / 1000);
        const forces = leftRightForcesData
            .flatMap(x => x.slice(fromSec, toSec));
        const force = Math.round(avg(forces));
        let sr: number | null = null;
        if (strokeDatas) {
            sr = Math.round(avg(strokeDatas.slice(fromSec, toSec)));
        }
        let hr: number | null = null;
        if (hrDatas) {
            hr = Math.round(avg(hrDatas.slice(fromSec, toSec)));
        }
        if (samples.length === 1 && samples[0].from === null) {
            setSamples([{...samples[0], from, to, force, sr, hr}])
        } else if (selectedSampleIndex !== undefined)
            setSamples(x => {
                return x.map(
                    (y, i) =>
                        i === selectedSampleIndex ? {...y, from, to, force, sr, hr} : y);
            })
    }

    const setValue = (value: number, index: number) => {
        setSamples(x => {
            return x.map(
                (y, i) =>
                    i === index ? {...y, value} : y);
        })
    }

    const validateSamples = useCallback(() => {
        const basicErrors = samples.map((x, i) => {
            const res = {} as any;
            if (currentSampleErrors.current[i]?.value)
                res.value = currentSampleErrors.current[i].value;
            if (samples[i].from == null)
                res.from = "empty";
            if (i > 0 && samples[i].from != null &&
                samples[i - 1].to != null && samples[i].from! <= samples[i - 1].to!)
                res.from = "less than";
            return res;
        });
        setSampleDisplayErrors(basicErrors);
        return basicErrors.every(x => Object.keys(x).length === 0);
    }, [samples]);

    useEffect(() => {
        if (samples.length === 1 && samples[0].from == null)
            return;
        validateSamples();
    }, [samples, validateSamples]);

    const errors = useMemo(() => {
        const res = [];
        if (samples.length < 2)
            res.push(t("less than samples error", {x: 2}));
        if (samples.every(x => x.force == null))
            res.push(t("no forces error"));
        return res;
    }, [samples, t]);
    const validate = useCallback(() => {
        setDirty(true);
        const samplesValid = validateSamples();
        return samplesValid && errors.length === 0;
    }, [errors.length, validateSamples]);
    const handleSampleError = (error: string, index: number, key: string) => {
        currentSampleErrors.current[index] = {...currentSampleErrors.current?.[index], [key]: error};
    };
    const onAddClick = () => {
        const isValid = validate();
        if (!isValid) return;
        setSaveLoading(true);
        const userSeries: IUserSeriesData = {
            samples: samples as any[],
            unit: UserSeriesUnit.MillimolPerLiter,
            trainingIndex: athleteIndex === null ? undefined : athleteIndex
        };
        const existingDatas = training.userSeries.map(x => x.data);
        let newSeries = existingDatas;
        if (userSeriesIndex === undefined) {
            newSeries = [...existingDatas, userSeries];
        } else {
            newSeries[userSeriesIndex] = userSeries;
        }
        Api.updateTrainingCommon(training.id, {
            userSeries: newSeries
        }).then(x => {
            onChange?.();
            onDismiss();
        }).finally(() => {
            setSaveLoading(false);
        });
    }
    const onDeleteClick = () => {
        setDeleteLoading(true);
        const userSeries = training.userSeries.map(x => x.data)
            .filter((_, i) => i !== userSeriesIndex!);
        Api.updateTrainingCommon(training.id, {
            userSeries
        }).then(x => {
            onChange?.();
            onDismiss();
        }).finally(() => {
            setDeleteLoading(false);
        });
    }
    return (
        <DialogShim
            title={t("lactate series")}
            onClose={onDismiss}
        >
            <SizedBox height={8}/>
            <Row>
                <Col sm={6}>
                    <Label>{t("athlete")}</Label>
                    <Input
                        type="select"
                        value={athleteIndex.toString()}
                        onChange={(e) => {
                            setAthleteIndex(parseInt(e.target.value));
                        }}
                        className={"d-inline-block ms-2"} style={{width: "16em"}}
                    >
                        {training.trainings.map((x, i) => {
                                const name = x.user ?
                                    getAthleteDisplayName(x.user, training.trainings.length > 1) : t("guest");
                                return <option key={x.id} value={i.toString()}>{name}</option>;
                            }
                        )}
                    </Input>
                </Col>
            </Row>
            <Row>
                <LactateChartDialogChart chartRef={chartRef}
                                         allowSelect={selectedSampleIndex != null}
                                         training={training}
                                         distanceSeries={distanceSeries}
                                         speedSeries={speedSeries}
                                         forceLeftSeries={forceLeftSeries}
                                         forceRightSeries={forceRightSeries}
                                         isTracker={isTracker}
                                         athleteIndex={athleteIndex}
                                         samples={samples}
                                         selectedSample={selectedSampleIndex != null ? samples[selectedSampleIndex] : null}
                                         onRangeSelected={handleRangeSelected}/>
            </Row>
            {samples.length > 0 && <div className={styles.samples} ref={samplesScrollRef}>
                <table>
                    <thead>
                    <tr>
                        <th style={{width: "11em"}}>{t("step")}</th>
                        <th>{t("lactate")} (mmol/L)</th>
                        <th>{t("avg pulling force")} (N)</th>
                        <th>{t("avg stroke rate")} (spm)</th>
                        <th>{t("avg heart rate")} (bpm)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {samples.map((x: any, i: number) => {
                        return (
                            <tr key={i}>
                                <td>
                                    <div className={"d-flex"}>
                                        {selectedSampleIndex === i &&
                                            <div className={styles.selectionIndicator}></div>}
                                        <div className={"d-inline-block"}>
                                            <Input type={"text"}
                                                   readOnly={true}
                                                   onFocus={() => setSelectedSampleIndex(i)}
                                                   value={(x.from != null && x.to != null && `${formatTimestamp(x.from)}-${formatTimestamp(x.to)}`) || ""}
                                                   invalid={dirty && !!sampleDisplayErrors[i]?.from}/>
                                            {dirty && sampleDisplayErrors[i] && <FormFeedback>
                                                {sampleDisplayErrors[i].from === "empty" && t("empty error")}
                                                {sampleDisplayErrors[i].from === "less than" && t("less than previous error")}
                                            </FormFeedback>}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <DecimalNumberInput value={x.value}
                                                        onChange={(y: number) => setValue(y, i)}
                                                        onError={(error: string) => handleSampleError(error, i, "value")}
                                                        onFocus={() => setSelectedSampleIndex(i)}
                                                        invalid={dirty && !!sampleDisplayErrors[i]?.value}/>
                                    {dirty && sampleDisplayErrors[i] && <FormFeedback>
                                        {sampleDisplayErrors[i].value === "format" && t("format error")}
                                        {sampleDisplayErrors[i].value === "empty" && t("empty error")}
                                    </FormFeedback>}
                                </td>
                                <td>
                                    <Input value={x.force ?? ""}
                                           onFocus={() => setSelectedSampleIndex(i)}
                                           readOnly={true}/>
                                </td>
                                <td>
                                    <Input value={x.sr ?? ""}
                                           onFocus={() => setSelectedSampleIndex(i)}
                                           readOnly={true}/>
                                </td>
                                <td>
                                    <Input value={x.hr ?? ""}
                                           onFocus={() => setSelectedSampleIndex(i)}
                                           readOnly={true}/>
                                </td>
                                <td className={styles.deleteSample}>
                                    <BsButton size="sm" onClick={() => handleDeleteSample(i)} outline>
                                        <Icon path={mdiClose} size={0.8} className={"d-flex align-items-center"}/>
                                    </BsButton>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>}
            <BsButton size="sm" onClick={handleAddSample} outline className={"ms-1 mt-1 d-flex align-items-center"}>
                <Icon path={mdiPlus} size={0.8}/> {t("add step")}
            </BsButton>
            {dirty && errors.length > 0 && <FormFeedback className={"d-block"}>
                {errors.map((x: any) =>
                    <div key={x}>{x}</div>)}
            </FormFeedback>}

            <div className={styles.buttonRow}>
                <Button
                    label={t("cancel")}
                    onClick={onDismiss}
                    secondary={true}
                    className={styles.w120}
                />
                {deleteLoading && userSeriesIndex !== undefined && <Spinner size={35}/>}
                {!deleteLoading && userSeriesIndex !== undefined &&
                    <div className={styles.delete}
                         onClick={(!saveLoading && onDeleteClick) || undefined}>{t("delete")}</div>}
                {saveLoading && <div className={styles.w120}><Spinner size={35}/></div>}
                {!saveLoading && <Button
                    label={t(userSeriesIndex === undefined ? "add" : "save")}
                    onClick={onAddClick}
                    className={styles.w120}
                    disabled={deleteLoading}
                />}
            </div>
        </DialogShim>
    );
};

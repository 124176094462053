import uPlot from "uplot";

export type TooltipChangedListener = (x: number, ys: (number | null | undefined)[],
                                      px: number, py: number) => void;

export class TooltipPlugin implements uPlot.Plugin {
    private readonly onChanged: TooltipChangedListener;
    private readonly onVisibleChanged?: (value: boolean) => void;
    private div: HTMLDivElement | null = null;

    constructor(onChanged: TooltipChangedListener, onVisibleChanged?: (value: boolean) => void) {
        this.onChanged = onChanged;
        this.onVisibleChanged = onVisibleChanged;
    }

    hooks: uPlot.Hooks.ArraysOrFuncs = {
        init: u => {
            this.div = u.over;
            this.div.onmouseenter = () => {
                this.onVisibleChanged?.(true);
            };
            this.div.onmouseleave = () => {
                this.onVisibleChanged?.(false);
            };
        },
        destroy: u => {
            this.onVisibleChanged?.(false);
        },
        setCursor: u => {
            const {idx} = u.cursor;
            if (idx != null) {
                const bbox = u.root.getBoundingClientRect();
                const x = u.data[0][idx];
                const xPos = Math.round(u.valToPos(x, "x", true));
                const px = (bbox?.x ?? 0) + xPos;
                const py = (bbox?.y ?? 0);
                this.onChanged(x, u.data.slice(1).map(x => x[idx]), px, py);
            }
        }
    }
}